import React from 'react'
import "./styles.css";

function SpinnerTwo() {
    return (
        <div className="loadingio-spinner-rolling-wg9uiqwvzd">
            <div className="ldio-acyp17j81na">
                <div></div>
            </div>
        </div>
    )
}

export default SpinnerTwo