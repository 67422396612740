import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

//PAGE STYLES 
import './styles.css';

//COMPONENTS
import Navbar from '../../components/Navbar'; 
import Footer from '../../components/Footer';
import Login from '../../components/Login';

import HeadphoneModelConfig from '../../components/HeadphoneModelConfig'; 

export default function CharmeleonConfig(){ 

    //POSITION TWO
    const refIframeContainerHpConfig = useRef(null); 

    const [user, setUser] = useState(sessionStorage.getItem('access'));

   return(<>
    
    {!user ? <>
    <Helmet>
        <title>Login | Fresco Design</title>
    </Helmet> 
    <Login setUser={setUser} />
    </> : <> 

    <Helmet>
        <title>Charmeleon Configurator | Fresco Design</title>
        <meta name="description" content="Charmeleon configurator" />
        {/* opengraph protocol */}
        <meta property="og:description" content="Charmeleon configurator" /> 
        <meta property="og:title" content="Charmeleon Configurator | Fresco Design" />
        <meta property="og:url" content="https://fresco-augmented-reality.com/Charmeleon_config" />
        <link rel="canonical" href="https://fresco-augmented-reality.com/Charmeleon_config" />
        <link rel="alternate" href="https://www.fresco-design.com.ar/Charmeleon_config" hreflang="es-AR" />
        <link rel="alternate" href="https://www.fresco-design.com/Charmeleon_config" />
    </Helmet> 
    
    <Navbar noShowOptions={true} />

    <div className="container-fluid container_3decommerce_iframe"> 
        <section className="section_title_3dmodel">
            <div className="row">
                <div className="col">
                    <h3 style={{textAlign: 'center'}} className="mt-lg-2" >Neurable Charmeleon</h3>
                </div>
            </div>
        </section>   
    </div>    

    <div className="container-fluid container_3decommerce_iframe" ref={refIframeContainerHpConfig}> 
        <HeadphoneModelConfig refIframeContainer={refIframeContainerHpConfig} pageName='Charmeleon_config' />  
    </div>

    <div className="container container_3decommerce"> 

        <section className="section_contact_3decommerce mt-5 mb-3">
            <div className="row">
                <div className="col-12">
                <p>                     
                    Fresco Immersive Commerce leverages AR to create online experiences for consumers to discover and explore your products.
                </p>
                </div> 
                <div className="col-12">
                    <p>
                        <a href="https://www.fresco-design.com/contact">
                            Let’s discuss how Fresco can grow your eCommerce business
                            <i className="fa fa-angle-right fa-lg" aria-hidden="true"></i>
                        </a>
                    </p>
                </div>
            </div>  
        </section>
        
    </div> 

    <Footer /> </>}
         
   </>)

}

