import React, {
  useEffect,
  useState,
} from "react"; /* THIS IS A NEURABLE Squirtle prototypes page  */
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

//Styles
import "./styles.css";

//DATA
import { AWS_DOMAIN, isMobile } from "../../services/data";

//IMAGES
import FrescoIcon from "../../images/icons/fresco_icon_transparent.png";

export default function Prototype() {
  //STATES
  const [productName, setProductName] = useState("");

  //URL PARAMS
  const { product } = useParams();

  useEffect(
    function () {
      try {
        if (product !== "none") {
          //setProductName(atob(product));
          setProductName(decodeURI(product));
        }
      } catch (error) {
        window.location = `${AWS_DOMAIN}modConcepts`;
      }

      //let internalRefIosLink = refIosBtn.current;

      console.log("Info Block:", {
        productName: productName,
      });
    },
    [product, productName]
  );

  return (
    <>
      <Helmet>
        <title>Fresco Design | Prototypes</title>
      </Helmet>

      <main className="prototype_page">
        <div className="prototype_grid">
          <figure>
            <img src={FrescoIcon} alt="Fresco Desing Icon" />
          </figure>
          <div className="prototype_text">
            <h1>Model number {productName}</h1>
            {productName == 12 && (
              <>
                <h4>Squirtle_01_ME12.pdf</h4>
                <a
                  href={`${AWS_DOMAIN}assets/neurable/documents/squirtle_01_ME12.pdf`}
                >
                  {isMobile ? "Preview/Download" : "Preview"}
                </a>
                {!isMobile && (
                  <a
                    href={`${process.env.PUBLIC_URL}/documents/squirtle_01_ME12.pdf`}
                    download={`squirtle_01_ME12-${+new Date()}.pdf`}
                  >
                    Download
                  </a>
                )}
              </>
            )}
          </div>
          <div className="prototype_button">
            <Link className="btn_fresco_rounded_outline" to="/">
              Back Home
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}
