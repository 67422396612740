import React from 'react';
import { Helmet } from 'react-helmet';

//STYLES
import './styles.css';

//COMPONENTS
import BkNavbar from '../../components/BkNavbar';
import BottleKeeperCofig from '../../components/BottleKeeperConfig';
import Footer from '../../components/Footer';

export default function BottleKeeper(){
   
    return(
    <div className="bk-page"> 

        <Helmet>
            <title>BK Model | Fresco Design</title>
            <meta name="description" content="BK configurator" />
            {/* opengraph protocol */}
            <meta property="og:description" content="BK configurator" /> 
            <meta property="og:title" content="BK Model | Fresco Design" />
            <meta property="og:url" content="https://fresco-augmented-reality.com/BKX" />
            <link rel="canonical" href="https://fresco-augmented-reality.com/BKX" />
            <link rel="alternate" href="https://www.fresco-design.com.ar/BKX" hreflang="es-AR" />
            <link rel="alternate" href="https://www.fresco-design.com/BKX" />
        </Helmet> 

        <BkNavbar />
        <main>       
            <BottleKeeperCofig pageName='BKX' />    
        </main>
        <Footer />
    </div>)

}
