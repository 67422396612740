import React from 'react';
import { Link } from 'react-router-dom';

//STYLES
import './styles.css';

//IMAGES
import bklogo from '../../images/icons/bklogo.png';

export default function BkNavbar(){ 

    return(
        <header>                 
            <div className="container">

            <div className="row summer-row"> 

               <div className="col-12 col-md-6 col-lg-4 title-left">
                 <span>free U.S. shipping on orders over $60</span>  
               </div>

               <div className="col-12 col-md-6 col-lg-4 title-center">
                 <span>
                    THE SUMMER SALE IS ON - USE CODE 'SUMMER'
                 </span>
               </div>

               <div className="col-md-4 sign-in">
                 <Link to="/">Login</Link>
               </div>

            </div>

                <div className="row navbar-row">

                    <div className="col-md-2 navbar-logo">
                        <Link className="" to="/">
                            <img src={bklogo} alt="" />
                        </Link>
                    </div>
                        
                    <div className="col-md-8 navbar-options" >
                        <ul>
                            <li>
                                <Link to="/">The Shop</Link>
                            </li>
                            <li>
                                <Link to="/">Custom Shop</Link>
                            </li>
                            <li>
                                <Link to="/">Who We Are</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-md-2 navbar-cart">
                        <Link className="" to="/">
                        <i className="fa fa-shopping-cart mr-1" aria-hidden="true"></i>
                        0</Link>    
                    </div>

                </div>
            </div>   
        </header>
    )
     
}
