import React, { useCallback, useRef, useEffect, useState } from 'react';

//PACKAGES
import screenfull from 'screenfull';

//HELPERS
import { StartAndHidePlayButton } from '../../helpers/index';

//IMAGES
import fullScreenIcon from '../../images/icons/fullscreen_icon.png';

export default function UiButtons({ api, iframeStart, setIframeStart, refViewerGrid, fullScreen, setFullScreen, 
  refIframeContainer, refIframeRow, refIframeCol, refViewer, refStartViewerBtn, ios, android,
  viewerContainerClassList, viewerRowClassList, viewerColClassList, loadingViewer, setLoadingEffect }){

    const refGridBtnUi = useRef(null);
    var html = document.getElementsByTagName('html');  

    const [showFullScreenBtn, setShowFullScreenBtn] = useState(true);

    useEffect(() => {


      if (!screenfull.isEnabled && (!refIframeContainer || !refIframeRow || !refIframeCol)){  
        console.error('You are not passing ref props for fullscreen functionality in case the devices are not supported', {
          refIframeContainer: refIframeContainer,
          refIframeRow: refIframeRow,
          refIframeCol: refIframeCol,
        });
        setShowFullScreenBtn(false);
      } 

      if(!screenfull.isEnabled && (!viewerContainerClassList || !viewerRowClassList || !viewerColClassList)) {
        console.error('You are not passing ClassList props for fullscreen functionality in case the devices are not supported', {
          viewerContainerClassList: viewerContainerClassList,
          viewerRowClassList: viewerRowClassList,
          viewerColClassList: viewerColClassList,
        });
        setShowFullScreenBtn(false);
      }
      
    }, [refIframeContainer, refIframeRow, refIframeCol, viewerContainerClassList, viewerRowClassList, viewerColClassList]);

    const HandleFullScreen = useCallback(() => {

      /* -----  Movil Device  ----- */ 
      /*api.getCameraLookAt(function(err, camera) { 
        console.log(camera.position);
        console.log(camera.target);
      }); */

      //api.setCameraLookAt([298.54375871132527, 273.73998794417656, 114.63240083119334], [0,0,0], 4.5);
      //-------------------

      StartAndHidePlayButton(api, iframeStart, setIframeStart, loadingViewer, setLoadingEffect, refStartViewerBtn);  
  
        if (screenfull.isEnabled && !ios && !android){  //CHANGE LATER  
          screenfull.toggle(refViewerGrid.current); 

          screenfull.on('change', () => {         
            setFullScreen(screenfull.isFullscreen); 
          });
          
        }else{  //FULL SCREEN SIMULATION  
      
            if(!fullScreen){   
              refViewerGrid.current.style.border = 'none';
              refViewer.current.style.height = '85%';     
              /* Remove Viewer ClassLists */
              refIframeContainer.current.classList.remove(...viewerContainerClassList); 
              refIframeRow.current.classList.remove(...viewerRowClassList);  
              refIframeCol.current.classList.remove(...viewerColClassList);   
              /* --- */
              refIframeCol.current.classList.add('iframe-fullscreen');
              refGridBtnUi.current.style.bottom = '16%';
              html[0].style.overflow = 'hidden';
              //window.scroll(0, 0);
              setFullScreen(true); 
            }else{          
              refViewerGrid.current.style.border = '';
              refViewer.current.style.height = '';
              refGridBtnUi.current.style.bottom = '';
              refIframeCol.current.classList.remove('iframe-fullscreen');
              /* Add Viewer ClassLists */
              refIframeContainer.current.classList.add(...viewerContainerClassList);
              refIframeRow.current.classList.add(...viewerRowClassList);                    
              refIframeCol.current.classList.add(...viewerColClassList); 
              /* --- */
              html[0].style.overflow = 'auto';
              //window.scroll(0, 200);
              setFullScreen(false);
            } 

        }
        
      }, [ api, iframeStart, setIframeStart, refIframeContainer, refIframeRow, refIframeCol, refViewerGrid, 
        refViewer, refGridBtnUi, fullScreen, setFullScreen, refStartViewerBtn, html, ios, android, 
        viewerContainerClassList, viewerRowClassList, viewerColClassList, loadingViewer, setLoadingEffect ]);

    return(<div ref={refGridBtnUi} className="iframe-buttons-ui-section"> 
     
      {showFullScreenBtn && <button className="fullscreen-button" onClick={() => HandleFullScreen()}>
          <img src={fullScreenIcon} alt="Fullscreen icon"/>
        </button>}

    </div>)

}

