import React, { useRef } from 'react';
import './styles.css';

//HOOKS
import useSkecthfabViewer from '../../hooks/useSkecthfabViewer';
import ArButton from '../ArButton';

//IMAGES
import arIconOneSeat from '../../images/icons/swandoo_ar_one-seat_v2.png';
import arIconThreeSeat from '../../images/icons/swandoo_ar_triplicated_v2.png';

//DATA
import { SWANDOO_PRODUCT } from '../../services/data';

export default function SwandooMarieModel({ refViewerContainer }) {

    //IFRAME REFS
    const refIframeRow = useRef(null);
    const refIframeCol = useRef(null);

    const { viewerIframe, setFullScreen } =
        useSkecthfabViewer(SWANDOO_PRODUCT.model_id,  
            { 
            productName: SWANDOO_PRODUCT.product_name, 
            initial_ar_img: { },  
            refIframeContainer: refViewerContainer,
            refIframeRow: refIframeRow,
            refIframeCol: refIframeCol,
            iframeBorder: true,
            arbtn: false,
            viewerContainerClassList: ['container-fluid', 'container_3decommerce_iframe'],
            viewerRowClassList: ['section-3dmodel-grid'],
            viewerColClassList: ['swandoo-viewer-grid'],  
            //fullScreenElementID: 'swandoo-viewer-grid',
            },
            {
                ui_animations: 0,
            }
        );

    return (<>

        <section className="desktop-title-iframe mt-5">
            <div className="row">
                <div className="col-12">
                    <h3>Swandoo Marie</h3>
                </div>
            </div>  
        </section>
            
        <section className="section-3dmodel-grid" ref={refIframeRow}>   

            <div className="swandoo-viewer-grid" id="swandoo-viewer-grid" ref={refIframeCol}>

                <div className="section-ar-buttons_ab-swandoo-marie"> 
                    <ArButton pageNameForQr='Swandoo_marie' text={{lineOne: 'Fit 1 in', lineTwo: 'your car!'}} icon={arIconOneSeat} productName={`${SWANDOO_PRODUCT.product_name} Lime`} arFiles={SWANDOO_PRODUCT.initial_ar_img} setFullScreen={setFullScreen} /> 
                    <ArButton pageNameForQr='Swandoo_marie' text={{lineOne: 'Fit 3 in', lineTwo: 'your car!'}} icon={arIconThreeSeat} productName={`${SWANDOO_PRODUCT.product_name} 3 Seats`} arFiles={SWANDOO_PRODUCT.initial_ar_img_v2} setFullScreen={setFullScreen} /> 
                </div> 

                {viewerIframe}
            </div>  

        </section>

    </>) 
}

