import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

//IMAGES
import iconFrescoQr from '../../images/icons/fresco_icon_qr.png';

//DATA
//import { AWS_DOMAIN, FRESCO_FOLDER_URL } from "../../services/data";

export default function RealityDesktopUi({
  productName,
  modelPageState,
}) {
  const refQrGrid = useRef(null);

  const urlQRcreated = window.location.href;

  useEffect(() => {
    new window.QRCode(refQrGrid.current, {
      text: `${urlQRcreated}`,
      width: 350,
      height: 350,
      colorDark: "#57616e",
      colorLight: "#ffffff",
    });
  }, [urlQRcreated]);  
  
  return (
    <main className="reality_desktop_page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 product_qr_grid">
            <div className="qr-img" ref={refQrGrid}>
              <figure className="logo-grid">
                <img src={iconFrescoQr} alt="Fresco Design Logo on QR" />
              </figure>
            </div>
          </div>

          <div className="col-12 col-lg-6 product_description_grid">
            <h3>{productName ? productName : "Scan the QR"}</h3>
            <p>
              Sorry, you are trying to preview some of our products in Augmented
              Reality from a Desktop or Laptop.
            </p>
            <p>
              Scan the QR code with your <strong>mobile device</strong> to
              continue.
            </p>
            <div className="btn_grid">
              <Link className="btn_fresco_rounded_outline" to={`/${modelPageState}`}>
                Back Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
