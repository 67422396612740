import React, { useEffect, useRef, useState, Fragment } from "react";
import "./styles.css";

//COMPONENTS
import SpinnerTwo from "../SpinnerTwo";

//HOOKS
import useSkecthfabViewer from "../../hooks/useSkecthfabViewer";

//HELPERS
import {
  SetTexture,
  StartAndHidePlayButton,
  VisibilityEvent,
} from "../../helpers/index";

//DATA
import { SHOE_PRODUCT } from "../../services/data";

export default function ShoeModelConfig({
  refIframeContainer,
  setIsShoeConfigActive,
  isBikeConfigActive,
  pageName = "immersive-commerce",
  height,
}) {
  //IFRAME REFS
  const refIframeRow = useRef(null);
  const refIframeCol = useRef(null);

  //STATES
  const [materialsList, setmaterialsList] = useState([]);
  const [isApplyTextures, setIsApplyTextures] = useState(false);
  const [shoeSelected, setShoeSelected] = useState("Light Blue");

  //VARIABLES
  const [timeDelay, setTimeDelay] = useState(1000);

  //CUSTOM HOOK
  const {
    viewerIframe,
    api,
    loadingViewer,
    iframeStart,
    setIframeStart,
    ios,
    android,
    setArFiles,
    setProductNameState,
    setLoadingEffect,
    loadingEffect,
    viewerSectionHook,
  } = useSkecthfabViewer(SHOE_PRODUCT.model_id, {
    productName: SHOE_PRODUCT.product_name,
    //description: 'Keurig K-Cafe',
    //price: '$250',
    initial_ar_img: SHOE_PRODUCT.initial_ar_img,
    pageNameForQr: pageName,
    refIframeContainer: refIframeContainer,
    refIframeRow: refIframeRow,
    refIframeCol: refIframeCol,
    iframeBorder: false,
    /* bgStartViewer: {
      desktop: `${AWS_DOMAIN}shoe_objects/iframe_bg/shoe_sketchfab_preview.jpg`,
      movil: `${AWS_DOMAIN}shoe_objects/iframe_bg/shoe_sketchfab_preview.jpg`,
    }, */
    //buyInfoInAr: true,
    //iosCallToActionTextInAr: 'Add to cart',
    viewerContainerClassList: [
      "container-fluid",
      "container_3decommerce_iframe",
    ],
    viewerRowClassList: ["section-3dmodel-grid"],
    viewerColClassList: ["shoeconfig-viewer-grid"],
    fullScreenElementID: "shoeconfig-viewer-grid",
    forToStartBtn: {
      condition: isBikeConfigActive ? false : true,
      function: () => {
        //This function only happens when the "codition" is FALSE or it is not fulfilled
        let option = window.confirm(
          "For Mobile devices, only one Configurator can be running at the same time. Do you want to open this configurator?"
        );
        if (option === true) {
          sessionStorage.setItem("ConfiguratorToUse", "Shoe");
          window.location.reload();
        }
      },
      device: "ios",
    },
  });

  function stopViewer() {
    api.stop(function () {
      console.log("Viewer stopped");
      setIframeStart(false);
    });
  }

  window.stopShoeConfigurator = stopViewer;

  /* == Carousel Arrows == */
  useEffect(() => {
    const carouselControlPrev = document.querySelectorAll(
      ".viewerCarousel .carousel-control-prev"
    );
    const carouselControlNext = document.querySelectorAll(
      ".viewerCarousel .carousel-control-next"
    );
    if (iframeStart) {
      carouselControlPrev.forEach((prevArrow) => {
        prevArrow.addEventListener("click", stopViewer);
      });
      carouselControlNext.forEach((prevArrow) => {
        prevArrow.addEventListener("click", stopViewer);
      });
    }
    return () => {
      carouselControlPrev.forEach((prevArrow) => {
        prevArrow.removeEventListener("click", stopViewer);
      });
      carouselControlNext.forEach((prevArrow) => {
        prevArrow.removeEventListener("click", stopViewer);
      });
    };
  }, [iframeStart]);
  /* ==== */

  useEffect(() => {
    if (!loadingViewer) {
      /* -- */
      if (setIsShoeConfigActive) {
        setIsShoeConfigActive(true);
      }
      /* -- */

      //GET MATERIALS
      api.getMaterialList(function (err, materials) {
        if (!err) {
          let materialArray = [];
          //console.log('MATERIALS list:', materials);

          materials.forEach((material) => {
            if (material.name === "UV1") {
              materialArray.push(material);
            } else if (material.name === "UV2") {
              materialArray.push(material);
            }
            setmaterialsList(materialArray);
          });
        }
      });

      setTimeout(() => {
        setTimeDelay(0);
      }, timeDelay);

      /* Visibility Functionality */

      let taskToDo = {
        isVisibility: () => {},
        isNotVisibility: () => {
          if (iframeStart) {
            api.stop(function () {
              setIframeStart(false);
            });
          }
        },
      };

      VisibilityEvent(taskToDo);

      /* ---- */
    }
  }, [
    api,
    loadingViewer,
    timeDelay,
    iframeStart,
    setIframeStart,
    setIsShoeConfigActive,
  ]);

  useEffect(() => {
    if (viewerSectionHook) {
      viewerSectionHook.current.style.height = height;
    }
  }, [viewerSectionHook, height]);

  const HandleClick = (option) => {
    let delayTime = 1000;

    let numberOfChanges = option.changes.length;

    let count = 0;
    let countB = 0;

    /* */

    if (!iframeStart) {
      if (ios) {
        /* !ios || !android */
        if (isBikeConfigActive) {
          let option = window.confirm(
            "For Mobile devices, only one Configurator can be running at the same time. Do you want to open this configurator?"
          );
          if (option === true) {
            sessionStorage.setItem("ConfiguratorToUse", "Shoe");
            window.location.reload();
          }
        } else {
          setShoeSelected(option.value);
          setIsApplyTextures(true);

          StartAndHidePlayButton(
            api,
            iframeStart,
            setIframeStart,
            loadingViewer,
            setLoadingEffect
          ).then((data) => {
            api.getMaterialList(function (err, materials) {
              if (!err) {
                let materialArray = [];

                materials.forEach((material) => {
                  if (material.name === "UV1") {
                    materialArray.push(material);
                  } else if (material.name === "UV2") {
                    materialArray.push(material);
                  }
                });

                option.changes.forEach((change) => {
                  if (!change.uid) {
                    let textureUrl =
                      ios || android
                        ? change.value.movil
                        : change.value.desktop;
                    api.addTexture(textureUrl, (err, uid) => {
                      //STEP 1
                      if (!err) {
                        change.uid = uid; // <---- ERROR / PROBLEM

                        SetTexture(
                          api,
                          change.uid,
                          materialArray,
                          change.channel,
                          change.material
                        ).then((data) => {
                          //STEP 2

                          count += 1;
                          if (count === numberOfChanges) {
                            let lastCount = 0;

                            materialArray.forEach((materialUpdated) => {
                              api.setMaterial(materialUpdated, function () {
                                //STEP 3
                                lastCount += 1;
                                if (lastCount === materialArray.length) {
                                  setTimeout(() => {
                                    setIsApplyTextures(false);
                                  }, delayTime);
                                }
                              });
                            });
                          }
                        });
                      }
                    });
                  } else {
                    delayTime = 0;
                    SetTexture(
                      api,
                      change.uid,
                      materialArray,
                      change.channel,
                      change.material
                    ).then((data) => {
                      api.setMaterial(data, function () {
                        countB += 1;
                        if (countB === numberOfChanges) {
                          setTimeout(() => {
                            setIsApplyTextures(false);
                          }, delayTime);
                        }
                      });
                    });
                  }
                });
              }
            });
          });
        }
      } else {
        setShoeSelected(option.value);
        setIsApplyTextures(true);

        StartAndHidePlayButton(
          api,
          iframeStart,
          setIframeStart,
          loadingViewer,
          setLoadingEffect
        ).then((data) => {
          api.getMaterialList(function (err, materials) {
            if (!err) {
              let materialArray = [];

              materials.forEach((material) => {
                if (material.name === "UV1") {
                  materialArray.push(material);
                } else if (material.name === "UV2") {
                  materialArray.push(material);
                }
              });

              option.changes.forEach((change) => {
                if (!change.uid) {
                  let textureUrl =
                    ios || android ? change.value.movil : change.value.desktop;
                  api.addTexture(textureUrl, (err, uid) => {
                    //STEP 1
                    if (!err) {
                      change.uid = uid; // <---- ERROR / PROBLEM

                      SetTexture(
                        api,
                        change.uid,
                        materialArray,
                        change.channel,
                        change.material
                      ).then((data) => {
                        //STEP 2

                        count += 1;
                        if (count === numberOfChanges) {
                          let lastCount = 0;

                          materialArray.forEach((materialUpdated) => {
                            api.setMaterial(materialUpdated, function () {
                              //STEP 3
                              lastCount += 1;
                              if (lastCount === materialArray.length) {
                                setTimeout(() => {
                                  setIsApplyTextures(false);
                                }, delayTime);
                              }
                            });
                          });
                        }
                      });
                    }
                  });
                } else {
                  delayTime = 0;
                  SetTexture(
                    api,
                    change.uid,
                    materialArray,
                    change.channel,
                    change.material
                  ).then((data) => {
                    api.setMaterial(data, function () {
                      countB += 1;
                      if (countB === numberOfChanges) {
                        setTimeout(() => {
                          setIsApplyTextures(false);
                        }, delayTime);
                      }
                    });
                  });
                }
              });
            }
          });
        });
      }
    } else {
      setShoeSelected(option.value);
      setIsApplyTextures(true);

      option.changes.forEach((change) => {
        if (!change.uid) {
          let textureUrl =
            ios || android ? change.value.movil : change.value.desktop;
          api.addTexture(textureUrl, (err, uid) => {
            //STEP 1
            if (!err) {
              change.uid = uid; // <---- ERROR / PROBLEM
              SetTexture(
                api,
                change.uid,
                materialsList,
                change.channel,
                change.material
              ).then((data) => {
                //STEP 2

                count += 1;
                if (count === numberOfChanges) {
                  let lastCount = 0;

                  materialsList.forEach((materialUpdated) => {
                    api.setMaterial(materialUpdated, function () {
                      //STEP 3
                      lastCount += 1;
                      if (lastCount === materialsList.length) {
                        setTimeout(() => {
                          setIsApplyTextures(false);
                        }, delayTime);
                      }
                    });
                  });
                }

                // FOR TO APPLY ONE BY ONE

                //api.setMaterial(data, function() {
                //count += 1;
                //if(count === numberOfChanges){
                //setTimeout(() => {
                //setIsApplyTextures(false);
                //}, delayTime);
                //}
                //});

                //setTimeout(() => {
                //setIsApplyTextures(false);
                //}, delayTime);

                //---
              });
            }
          });
        } else {
          delayTime = 0;
          SetTexture(
            api,
            change.uid,
            materialsList,
            change.channel,
            change.material
          ).then((data) => {
            api.setMaterial(data, function () {
              countB += 1;
              if (countB === numberOfChanges) {
                setTimeout(() => {
                  setIsApplyTextures(false);
                }, delayTime);
              }
            });
          });
        }
      });
    }

    setArFiles(option.ar_files);
    setProductNameState(`3D Footwear ${option.value}`);
  };

  return (
    <>
      {/* DESKTOP */}

      {/* </DESKTOP> */}

      <section className="section-3dmodel-grid" ref={refIframeRow}>
        {/* <h3 className='shoe_configurator_title'>Demo: 3D Configurator with AR</h3> */}

        {/* <VIEWER> */}
        <div
          className="shoeconfig-viewer-grid"
          id="shoeconfig-viewer-grid"
          ref={refIframeCol}
        >
          {!loadingViewer && isApplyTextures && (
            <div className="loading-texture-effect">
              <SpinnerTwo />
            </div>
          )}
          {viewerIframe}
        </div>
        {/* </VIEWER> */}

        {/* <CONFIGURATOR> */}
        {
          /* !loadingViewer && */ <div className="configurator-options-grid mt-3 mb-3">
            <div className="color-options">
              <div className="color-row desktop">
                {SHOE_PRODUCT.colors.color_primary.map((color) => {
                  /* if(color.id > 5){
                                return <Fragment key={`${color.id}-${color.reference}`}> </Fragment>
                            }  */
                  let isSelected = false;

                  if (color.value === shoeSelected) {
                    isSelected = true;
                  }

                  return (
                    <div
                      key={`${color.id}-${color.reference}`}
                      className={`circle-option ${color.reference}-rowOne`}
                    >
                      <input
                        disabled={isApplyTextures || loadingEffect}
                        type="radio"
                        name="shoe-color"
                        id={`shoe-color-${color.reference}`}
                        onChange={() => HandleClick(color)}
                      />
                      <label
                        htmlFor={`shoe-color-${color.reference}`}
                        className={`label-${
                          isSelected ? "selected" : "noSelected"
                        } ${isApplyTextures ? `loadingEffect` : ""}`}
                      >
                        <img
                          src={color.thumbnail}
                          alt={`Shoe ${color.value}`}
                        />
                        <span
                          className={`span-${
                            isSelected ? "selected" : "noSelected"
                          } ${isApplyTextures ? `loadingEffect` : ""}`}
                        ></span>
                      </label>
                    </div>
                  );
                })}
              </div>

              {/* MOVIL */}

              <div className="color-row movil">
                {SHOE_PRODUCT.colors.color_primary.map((color) => {
                  if (color.id > 3) {
                    return (
                      <Fragment key={`${color.id}-${color.reference}`}>
                        {" "}
                      </Fragment>
                    );
                  }

                  let isSelected = false;

                  if (color.value === shoeSelected) {
                    isSelected = true;
                  }

                  return (
                    <div
                      key={`${color.id}-${color.reference}`}
                      className={`circle-option ${color.reference}-rowOne`}
                    >
                      <input
                        disabled={isApplyTextures || loadingEffect}
                        type="radio"
                        name="shoe-color"
                        id={`shoe-color-${color.reference}-movil`}
                        onChange={() => HandleClick(color)}
                      />
                      <label
                        htmlFor={`shoe-color-${color.reference}-movil`}
                        className={`label-${
                          isSelected ? "selected" : "noSelected"
                        } ${isApplyTextures ? `loadingEffect` : ""}`}
                      >
                        <img
                          src={color.thumbnail}
                          alt={`Shoe ${color.value}`}
                        />
                        <span
                          className={`span-${
                            isSelected ? "selected" : "noSelected"
                          } ${isApplyTextures ? `loadingEffect` : ""}`}
                        ></span>
                      </label>
                    </div>
                  );
                })}
              </div>

              <div className="color-row movil">
                {SHOE_PRODUCT.colors.color_primary.map((color) => {
                  if (color.id <= 3 || color.id > 6) {
                    return (
                      <Fragment key={`${color.id}-${color.reference}`}>
                        {" "}
                      </Fragment>
                    );
                  }

                  let isSelected = false;

                  if (color.value === shoeSelected) {
                    isSelected = true;
                  }

                  return (
                    <div
                      key={`${color.id}-${color.reference}`}
                      className={`circle-option ${color.reference}-rowOne`}
                    >
                      <input
                        disabled={isApplyTextures || loadingEffect}
                        type="radio"
                        name="shoe-color"
                        id={`shoe-color-${color.reference}-movil`}
                        onChange={() => HandleClick(color)}
                      />
                      <label
                        htmlFor={`shoe-color-${color.reference}-movil`}
                        className={`label-${
                          isSelected ? "selected" : "noSelected"
                        } ${isApplyTextures ? `loadingEffect` : ""}`}
                      >
                        <img
                          src={color.thumbnail}
                          alt={`Shoe ${color.value}`}
                        />
                        <span
                          className={`span-${
                            isSelected ? "selected" : "noSelected"
                          } ${isApplyTextures ? `loadingEffect` : ""}`}
                        ></span>
                      </label>
                    </div>
                  );
                })}
              </div>

              <div className="color-row movil">
                {SHOE_PRODUCT.colors.color_primary.map((color) => {
                  if (color.id <= 6) {
                    return (
                      <Fragment key={`${color.id}-${color.reference}`}>
                        {" "}
                      </Fragment>
                    );
                  }

                  let isSelected = false;

                  if (color.value === shoeSelected) {
                    isSelected = true;
                  }

                  return (
                    <div
                      key={`${color.id}-${color.reference}`}
                      className={`circle-option ${color.reference}-rowOne`}
                    >
                      <input
                        disabled={isApplyTextures || loadingEffect}
                        type="radio"
                        name="shoe-color"
                        id={`shoe-color-${color.reference}-movil`}
                        onChange={() => HandleClick(color)}
                      />
                      <label
                        htmlFor={`shoe-color-${color.reference}-movil`}
                        className={`label-${
                          isSelected ? "selected" : "noSelected"
                        } ${isApplyTextures ? `loadingEffect` : ""}`}
                      >
                        <img
                          src={color.thumbnail}
                          alt={`Shoe ${color.value}`}
                        />
                        <span
                          className={`span-${
                            isSelected ? "selected" : "noSelected"
                          } ${isApplyTextures ? `loadingEffect` : ""}`}
                        ></span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        }

        {/* </CONFIGURATOR> */}
      </section>
    </>
  );
}
