import React, { useEffect, Fragment, useRef, useState } from 'react'; 

//STYLES CSS
import './styles.css';

//HOOK
import useSketchfabViewerBk from '../../hooks/useSkecthfabViewerBk'; 

//HELPER
import { SetBackgroundToClassName, StartAndHidePlayButton, SetTexture, VisibilityEvent } from '../../helpers/index';

//IMAGES 
import soldout from '../../images/icons/soldout.png';

//DATA
import { BOTTLE_PRODUCT } from '../../services/data';

export default function BottleKeeperCofig({ pageName }){

  //IFRAME REFS
  const refIframeContainer = useRef(null);
  const refIframeRow = useRef(null);
  const refIframeCol = useRef(null); 

  //STATES
  const [materialsList, setMaterialsList] = useState(null);

    const { viewerIframe, api, loadingViewer, iframeStart, setIframeStart, 
      ios, android, setArFiles, setProductNameState, 
      colorSelected, setColorSelected, setModelMaterials, isApplyTextures, setIsApplyTextures,
      setLoadingEffect, loadingEffect } = useSketchfabViewerBk(BOTTLE_PRODUCT.model_id,  
        { 
          productName: BOTTLE_PRODUCT.product_name, 
          //description: 'Keurig K-Cafe',
          //price: '$250',
          initial_qr_img: 'spokeshave-qr.png',  
          initial_ar_img: BOTTLE_PRODUCT.initial_ar_img,
          pageNameForQr: pageName,
          refIframeContainer: refIframeContainer,
          refIframeRow: refIframeRow,
          refIframeCol: refIframeCol,
          iframeBorder: false,
          //buyInfoInAr: true,
          //iosCallToActionTextInAr: 'Add to cart',
          viewerContainerClassList: ['container'],
          viewerRowClassList: ['row'],
          viewerColClassList: ['col-md-7', 'product-section'],  
        },
    );

    /* EFFECTS */

    useEffect(() => {
        if(api){
          SetBackgroundToClassName("color-item")
        }
    },[api]);

    //--

    useEffect(() => {  

      if(!loadingViewer){   

          //GET MATERIALS
          api.getMaterialList(function(err, materials) {
              if (!err) {

                  let materialArray = [];
                  //console.log('MATERIALS list:', materials);
    
                  materials.forEach(material => {           
                          if(material.name === 'bk3_X_body_2'){ 
                              materialArray.push(material);
                          }                                                             
                          setMaterialsList(materialArray); 
                          setModelMaterials(materialArray);          
                  });
              }
          });

          /* Visibility Functionality */
          let taskToDo = {
              isVisibility: () => {
              },
              isNotVisibility: () => {
                if(iframeStart){
                  api.stop(function() {
                      setIframeStart(false);
                    });
                }

              }
            }

          VisibilityEvent(taskToDo); 
          /* ---- */

      }     
      
  },[api, loadingViewer, iframeStart, setIframeStart, setModelMaterials]); 

    const HandleOnClick = (option) => {  

      let delayTime = 1000;
        
      setColorSelected(option.value);

        let numberOfChanges = option.changes.length;

        let count = 0;
        let countB = 0; 

        /* */

        if(!iframeStart){

            setIsApplyTextures(true);

            StartAndHidePlayButton(api, iframeStart, setIframeStart, loadingViewer, setLoadingEffect).then(data => {

                api.getMaterialList(function(err, materials) {
                    if (!err) {
            
                        let materialArray = [];
            
                        materials.forEach(material => {           
                            if(material.name === 'bk3_X_body_2'){ 
                                materialArray.push(material);
                            }                                                                    
                        });
                        
                        option.changes.forEach((change) => {
                            if(!change.uid){
                                let textureUrl = (ios || android) ? change.value.movil : change.value.desktop;
                                api.addTexture(textureUrl, (err, uid) => {                              //STEP 1
                                    if (!err) {
                                        change.uid = uid;                                  // <---- ERROR / PROBLEM
            
                                        SetTexture(api, change.uid, materialArray, change.channel, change.material).then(data => {        //STEP 2                                      
                            
                                            count += 1;
                                            if(count === numberOfChanges){  
                                                                    
                                                let lastCount = 0;
                                    
                                                materialArray.forEach(materialUpdated => {
                                                    api.setMaterial(materialUpdated, function(){          //STEP 3
                                                        lastCount += 1;
                                                            if(lastCount === materialArray.length){
                                                                setTimeout(() => {    
                                                                    setIsApplyTextures(false);              
                                                                }, delayTime);
                                                            }
                                    
                                                        }); 
                                                    });
                                                           
                                            }                           
                                    
                                        }); 
            
                                    }
                                }); 
                            } else {
                                delayTime = 0;
                                SetTexture(api, change.uid, materialArray, change.channel, change.material).then(data => {
                                    api.setMaterial(data, function() {
                                        countB += 1;
                                        if(countB === numberOfChanges){  
                                            setTimeout(() => { 
                                                setIsApplyTextures(false);
                                            }, delayTime);
                                        }
                                    });  
                                });
                            }
                        }); 
                    }
                });
               
            });

        } else {

            setIsApplyTextures(true);

            option.changes.forEach((change) => {
                if(!change.uid){
                    let textureUrl = (ios || android) ? change.value.movil : change.value.desktop;
                    api.addTexture(textureUrl, (err, uid) => {                              //STEP 1
                        if (!err) {
                            change.uid = uid;                                  // <---- ERROR / PROBLEM
                            SetTexture(api, change.uid, materialsList, change.channel, change.material).then(data => {        //STEP 2 
                                
                                count += 1;
                                if(count === numberOfChanges){  
                                    
                                    let lastCount = 0;
    
                                    materialsList.forEach(materialUpdated => {
                                        api.setMaterial(materialUpdated, function(){          //STEP 3
                                            lastCount += 1;
                                            if(lastCount === materialsList.length){
                                                setTimeout(() => {  
                                                    setIsApplyTextures(false);                
                                                }, delayTime);
                                            }
    
                                        }); 
                                    });
                           
                                }
    
                            }); 
                        }
                    }); 
                } else {
                    delayTime = 0;
                    SetTexture(api, change.uid, materialsList, change.channel, change.material).then(data => {
                        api.setMaterial(data, function() {
                            countB += 1;
                            if(countB === numberOfChanges){  
                                setTimeout(() => {
                                    setIsApplyTextures(false);
                                }, delayTime);
                            }
                        });  
                    });
                }
            });

        }

        setArFiles(option.ar_files);
        setProductNameState(`Bk-X ${option.value}`);
      
    };
  
    return( 
        <div ref={refIframeContainer} className="container" id="iframe-grid-container">

          <div className="row mb-3 row-movil">
            <div className="col">
                <h2 className="title-product">BottleKeeper® X</h2>
            </div>

            <div className="col">
              {api && <div className="bkcolors-section bkcolors-section-movil ">                   

                  <div className="color-title">
                      <span>Color</span> 
                  </div>  

                  {BOTTLE_PRODUCT.colors.color_primary.map((array) => {

                  if(array.value === colorSelected){
                    document.getElementById(`movil-option-${array.id}`).checked = true;
                  }

                  return(<Fragment key={array.id}>
                  <input disabled={isApplyTextures || loadingEffect} onClick={(e) => HandleOnClick(array, e)} 
                  id={`movil-option-${array.id}`} name="colormovil" type="radio" 
                  value={array.value} className="color-radio" />
                  <div className="swatch-element">
                      <label htmlFor={`movil-option-${array.id}`} data-bgcolor={`#${array.hex}`} className="color-item">
                        <img className="crossed-out" src={soldout} role="button" alt="Soldout" />                                          
                      </label>
                  </div>
                  </Fragment>)

                  })}

              </div>}
            </div>

          </div>  
          
          <div ref={refIframeRow} className="row" id="iframe-grid-background">

                <div ref={refIframeCol} className="col-md-7 product-section" id="iframe-grid-section">                                       
                    {viewerIframe}
                </div>             
                        
                <div className="col-md-5 description-section" id="other-grid"> 
                  <h2 className="">BottleKeeper® X</h2>

                  <div className="prod-price">
                    <span className="mr-1" >
                      $ 34.62
                    </span>
                    <s>
                      $ 36.99
                    </s>
                  </div>

                  <p className="description">
                  Now with a fancy new quick access cap!<br/><br/>
                  Fits 99% of beer bottles and&nbsp;is <strong>double walled and vacuum insulated</strong> to keep your beer 
                  colder and longer than ever before -- and by longer we mean up to 6 hours of cold frothy deliciousness. 
                  The BK X will fit your Standard shaped beer bottles like Corona, Bud and Stone, and comes standard with a 
                  built-in bottle opener. Mind. Officially. Blown.</p>

                  <p><button className="button-outline">Click to see bottles that fit The X</button></p>

                  {/* --------------------------- */}                

                  {api && <div className="bkcolors-section bkcolors-section-desktop">                   

                      <div className="color-title">
                          <span>Color</span>
                      </div>  

                      {BOTTLE_PRODUCT.colors.color_primary.map((array) => {

                      if(array.value === colorSelected){
                        document.getElementById(`desktop-option-${array.id}`).checked = true;
                      }

                      return(<Fragment key={array.id}>
                          <input disabled={isApplyTextures || loadingEffect} onClick={(e) => HandleOnClick(array, e)} 
                          id={`desktop-option-${array.id}`} name="colordesktop" type="radio" value={array.value} 
                          className="color-radio" />
                          <div className="swatch-element">
                              <label htmlFor={`desktop-option-${array.id}`} data-bgcolor={`#${array.hex}`} className="color-item">
                                <img className="crossed-out" src={soldout} role="button" alt="Soldout" />                                          
                              </label>
                          </div>
                      </Fragment>)

                      })}

                  </div>}

                   {/* -------------------------- */}     

                    <p className="mt-2"><button className="button-blue">Add To Cart</button></p>

                    <div className="bonus-section">
                      <img src="//cdn.shopify.com/s/files/1/0885/1096/files/Guarantees3_19cc32c7-2e62-4c7d-8778-a9a7487eacd8.jpg?v=1541129849" alt=""/>
                    </div>
                    
                    <div className="movil-section">
                      <p className="movil-description">
                      Now with a fancy new quick access cap!<br/><br/>
                        
                      Fits 99% of beer bottles and&nbsp;is <strong>double walled and vacuum insulated</strong> to keep your beer 
                      colder and longer than ever before -- and by longer we mean up to 6 hours of cold frothy deliciousness. 
                      The BK X will fit your Standard shaped beer bottles like Corona, Bud and Stone, and comes standard with a 
                      built-in bottle opener. Mind. Officially. Blown.</p>  

                      <div className="movil-add">
                        <button className="button-blue">Click to see bottles that fit The X</button>
                      </div>
                    </div>

                </div>             
                
          </div>
        
        </div>
    )

}

