import React, { useEffect, useRef, useState } from "react";
import "./styles.css";

//Slick Slider
// import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Components
import CarouselViewer from "../CarouselViewer";

// Models
import Spokeshave from "../../components/Spokeshave";

//CONFIGURATORS
import ShoeModelConfig from "../../components/ShoeModelConfig";
import BikeModelConfig from "../BikeModelConfig";

export default function ImmersiveExperiencesMod() {
  const refIframeContainerShoeConfig = useRef(null);

  const ASSETS_FOLDER =
    "https://s3.amazonaws.com/fresco-augmented-reality.com/assets/immersiveCommerce";

  const SLIDERS_EXPERIENCES = [
    {
      id: 1,
      children: (
        <Spokeshave height={window.innerWidth > 800 ? "500px" : "350px"} />
      ),
      border: true,
    },
    {
      id: 2,
      children: (
        <ShoeModelConfig
          refIframeContainer={refIframeContainerShoeConfig}
          isBikeConfigActive={false}
          // setIsShoeConfigActive={setIsShoeConfigActive}
          pageName="immersive-commerce"
          height={window.innerWidth > 800 ? "500px" : "350px"}
        />
      ),
      border: true,
    },
    {
      id: 3,
      children: (
        <BikeModelConfig height={window.innerWidth > 800 ? "500px" : "350px"} />
      ),
      border: true,
    },
    {
      id: 4,
      children: (
        <img
          className="experience-carousel-img no-height"
          src={`${ASSETS_FOLDER}/equifit_user-flow_graph_fv_220422.png`}
          alt=""
        />
      ),
      border: true,
    },
    {
      id: 5,
      children: (
        <img
          className="experience-carousel-img no-height"
          src={`${ASSETS_FOLDER}/003_form3_closeup02_comp_ik_220419.png`}
          alt=""
        />
      ),
      border: false,
    },
    {
      id: 6,
      children: (
        // <div className="container-video">
        <video
          className="experience-carousel-video"
          src={`${ASSETS_FOLDER}/sw_marie_animation_update_HD_mb_ik_220420_comp.mp4`}
          autoPlay
          loop
          muted
          playsInline
        ></video>
        // </div>
      ),
      border: false,
    },
    {
      id: 7,
      children: (
        <img
          className="experience-carousel-img no-height"
          src={`${ASSETS_FOLDER}/ecom_platforms_02.png`}
          alt=""
        />
      ),
      border: true,
    },
  ];

  function clickElement(querySelector) {
    // setCheckedOption(querySelector.replace("#", ""));
    const elem = document.querySelector(querySelector);
    elem.click();

    // Stop Viewers
    stopAllCarouselViewers();
  }

  function stopAllCarouselViewers() {
    console.log("stopAllCarouselViewers");
    window.stopSpokeViewer();
    window.stopShoeConfigurator();
    window.stopBikeConfigurator();
  }

  const [checkedOption, setCheckedOption] = useState("ar"); // ID of the selected option

  const MOBILE_BREAKPOINT = 800;

  return (
    <div className="container-fluid immersive_experiences">
      <div className="experiences_square--mod">
        {/* experiences_square */}
        {window.innerWidth < MOBILE_BREAKPOINT && (
          <h4 className="font1 offerings">Immersive Commerce Offerings</h4>
        )}
        <CarouselViewer
          id="carouselExperiences"
          data={SLIDERS_EXPERIENCES}
          showArrows={false}
          showIndicators={false}
          borderInCarouselItems={true}
        />
        <span className="experiences_line experiences_line--mod" />
        <section className="experiences-section">
          {/* <h4 className='font1'>Immersive Commerce Offerings</h4> */}
          {window.innerWidth >= MOBILE_BREAKPOINT && (
            <h4 className="font1 offerings">Immersive Commerce Offerings</h4>
          )}
          <ul className="experiences">
            <input
              id="ar"
              type="radio"
              name="selected-carousel-option"
              checked={checkedOption === "ar"}
              onChange={() => setCheckedOption("ar")}
            />
            <li
              onClick={() => clickElement("#ar")}
              data-target="#carouselExperiences"
              data-slide-to="0"
            >
              AR
            </li>

            <input
              id="web-viewer"
              type="radio"
              name="selected-carousel-option"
              checked={checkedOption === "web-viewer"}
              onChange={() => setCheckedOption("web-viewer")}
            />
            <li
              onClick={() => clickElement("#web-viewer")}
              data-target="#carouselExperiences"
              data-slide-to="1"
            >
              3D Web Viewer
            </li>

            <input
              id="configurator"
              type="radio"
              name="selected-carousel-option"
              checked={checkedOption === "configurator"}
              onChange={() => setCheckedOption("configurator")}
            />
            <li
              onClick={() => clickElement("#configurator")}
              data-target="#carouselExperiences"
              data-slide-to="2"
            >
              3D Configurator
            </li>

            <input
              id="ux-ui-1"
              type="radio"
              name="selected-carousel-option"
              checked={checkedOption === "ux-ui-1"}
              onChange={() => setCheckedOption("ux-ui-1")}
            />
            <li
              onClick={() => clickElement("#ux-ui-1")}
              data-target="#carouselExperiences"
              data-slide-to="3"
            >
              UX/UI
            </li>

            <input
              id="virtual-photography"
              type="radio"
              name="selected-carousel-option"
              checked={checkedOption === "virtual-photography"}
              onChange={() => setCheckedOption("virtual-photography")}
            />
            <li
              onClick={() => clickElement("#virtual-photography")}
              data-target="#carouselExperiences"
              data-slide-to="4"
            >
              Virtual Photography
            </li>

            <input
              id="animation"
              type="radio"
              name="selected-carousel-option"
              checked={checkedOption === "animation"}
              onChange={() => setCheckedOption("animation")}
            />
            <li
              onClick={() => clickElement("#animation")}
              data-target="#carouselExperiences"
              data-slide-to="5"
            >
              Animation
            </li>

            <input
              id="platform-integration"
              type="radio"
              name="selected-carousel-option"
              checked={checkedOption === "platform-integration"}
              onChange={() => setCheckedOption("platform-integration")}
            />
            <li
              onClick={() => clickElement("#platform-integration")}
              data-target="#carouselExperiences"
              data-slide-to="6"
            >
              Platform Integration
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}
