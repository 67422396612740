import React, { useState, useRef, useEffect } from "react";
import "./styles.css";

//DATA
import { AWS_DOMAIN } from "../../services/data";

//AR IMG
import defaultMediaAR from "../../images/icons/ar_img.png";
import invisibleImg from "../../images/utils/80x80px_transparent.png";

//COMPONENTS
import Modal from "../../components/Modal";
import Qr from "../../components/Qr";

//PACKAGES
import screenfull from "screenfull";

function ArButton({
  productName,
  description,
  price,
  buyInfoInAr,
  iosCallToActionTextInAr,
  arFiles,
  setFullScreen,
  icon,
  text = { lineOne: "View it in", lineTwo: "your room!" },
  ArSectionStyles,
  pageNameForQr,
}) {
  const mediaAR = icon !== undefined ? icon : defaultMediaAR;

  //REFS
  const refIosArBtn = useRef(null);
  const refAndroidArBtn = useRef(null);
  const refDesktopArBtn = useRef(null);

  //IOS Link to AR DIRECT
  const refIosLink = useRef(null);

  //STATES
  const [showModal, setShowModal] = useState(false);
  const [showArBtns, setShowArBtns] = useState(false);

  //DEVICES
  const [iosDevice, setIosDevice] = useState(false);
  const [androidDevice, setAndroidDevice] = useState(false);

  /* function HandleTapIos (event) {   
        if (event.data === "_apple_ar_quicklook_button_tapped") {
            // handle the user tap. 
            alert('Thank you for testing our model!');  
        }
    } */

  const [arFileNamesToURL, setarFileNamesToURL] = useState(null);

  useEffect(() => {
    if (arFiles.android === arFiles.ios) {
      setarFileNamesToURL(arFiles.android);
    } else {
      setarFileNamesToURL(`${arFiles.android}/${arFiles.ios}`);
    }
  }, [arFiles.android, arFiles.ios]);

  useEffect(() => {
    if (!showArBtns) {
      if (arFiles.local) {
        /* if(!arFiles){
                    console.error('You are not passing the corresponding 3D files (IOS and ANDROID) in the property for AR functionality', {
                        arFiles: { android: undefined, ios: undefined },
                    });
                } else{ */
        if (arFiles.android && arFiles.ios) {
          if (!arFiles.serverRouting) {
            console.error(
              'Remember that the "serverRouting" property is to set the folder path/address where the AR files are hosted on your server.',
              {
                arFiles: { serverRouting: undefined },
              }
            );
            arFiles.serverRouting = "";
          }
          setShowArBtns(true);
        } else {
          console.error(
            "You are not passing the corresponding 3D files (IOS and ANDROID) in the property for AR functionality",
            {
              arFiles: arFiles,
            }
          );
        }
        // }
      } else {
        if (arFiles.landingPage === "") {
          console.error(
            "You are not passing the corresponding inital link in the landingPage property for AR functionality",
            {
              arFiles: arFiles,
            }
          );
        } else {
          setShowArBtns(true);
        }
      }
    } else {
      refIosArBtn.current.style.display = "";
      refAndroidArBtn.current.style.display = "";
      refDesktopArBtn.current.style.display = "";

      //let internalRefIosLink = refIosLink.current;

      //IOS
      if (
        navigator.platform.includes("iPad") ||
        navigator.platform === "iPhone" ||
        (navigator.platform.includes("Mac") && navigator.maxTouchPoints > 1)
      ) {
        setIosDevice(true);
        refIosArBtn.current.style.display = "block";
        //internalRefIosLink.addEventListener("message", HandleTapIos, false);
      }
      //DESKTOP
      else if (
        navigator.platform.includes("Win") ||
        navigator.platform.includes("Mac")
      ) {
        refDesktopArBtn.current.style.display = "block";
      }
      //ANDROID
      else {
        setAndroidDevice(true);
        refAndroidArBtn.current.style.display = "block";
      }

      /*return () => {
                internalRefIosLink.removeEventListener("message", HandleTapIos, false);
            }*/
    }
  }, [showArBtns, arFiles]);

  // console.log("🚀 arFiles.local", arFiles);

  let qrPageToRedirect = arFiles.local
    ? `${AWS_DOMAIN}${
        pageNameForQr === "" ? "immersive-commerce/" : `${pageNameForQr}/`
      }reality/${encodeURI(productName)}/${
        arFiles.serverRouting === ""
          ? ""
          : `${arFiles.serverRouting.replaceAll("/", "=")}/`
      }${arFileNamesToURL}`
    : arFiles.landingPage;
  // console.log("🚀 qrPageToRedirect", qrPageToRedirect);

  let iosArHref = arFiles.local
    ? process.env.PUBLIC_URL +
      `/images/${
        arFiles.serverRouting === "" ? "" : `${arFiles.serverRouting}/`
      }${arFiles.ios}.usdz`
    : arFiles.landingPage;

  let androidArHref = arFiles.local
    ? `intent://arvr.google.com/scene-viewer/1.0?file=${AWS_DOMAIN}${
        arFiles.serverRouting === "" ? "" : `${arFiles.serverRouting}/`
      }${
        arFiles.android
      }.glb#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`
    : arFiles.landingPage;

  function handleLocation() {
    if (iosDevice) {
      refIosLink.current.click();
    } else if (androidDevice) {
      window.location = androidArHref;
    } else {
      //Desktop
    }
  }

  //MODAL
  function handleOpen() {
    if (screenfull.isFullscreen) {
      setFullScreen(false);
      screenfull.exit();
    }

    setShowModal(true);
  }

  function handleClose() {
    setShowModal(false);
  }

  return (
    <>
      {showArBtns && (
        <>
          <div ref={refAndroidArBtn} className="grid_btnAr">
            <button
              onClick={() => handleLocation()}
              className="android-ar-styles ar-button-styles"
            >
              <img src={mediaAR} alt="Android AR Button" className="icon-ar" />
              <span>
                {text.lineOne}
                <br /> {text.lineTwo}
              </span>
            </button>
          </div>

          <div ref={refIosArBtn} className="grid_btnAr">
            <button
              onClick={() => handleLocation()}
              className="ios-ar-styles ar-button-styles"
            >
              <img src={mediaAR} alt="Ios AR Button" className="icon-ar" />
              <span>
                {text.lineOne}
                <br /> {text.lineTwo}
              </span>
            </button>
            {arFiles.local ? (
              <a ref={refIosLink} rel="ar" href={iosArHref}>
                <img
                  src={invisibleImg}
                  alt=""
                  className="invisible miniature"
                />
              </a>
            ) : (
              <a ref={refIosLink} href={iosArHref}>
                <img
                  src={invisibleImg}
                  alt=""
                  className="invisible miniature"
                />
              </a>
            )}
          </div>

          <div ref={refDesktopArBtn} className="grid_btnAr">
            <button
              onClick={() => handleOpen()}
              className="desktop-ar-styles ar-button-styles"
            >
              <img src={mediaAR} alt="Desktop AR Button" className="icon-ar" />
              <span>
                {text.lineOne}
                <br /> {text.lineTwo}
              </span>
            </button>
          </div>

          {showModal && (
            <Modal title={productName} onClose={handleClose}>
              <Qr
                actualPage={`${pageNameForQr === "" ? "" : `${pageNameForQr}`}`}
                qrPageToRedirect={qrPageToRedirect}
              />
            </Modal>
          )}
        </>
      )}
    </>
  );
}

export default ArButton;
