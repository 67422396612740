import React, { useEffect, useRef, useState } from 'react';
import './styles.css';

//HOOKS
import useSkecthfabViewer from '../../hooks/useSkecthfabViewer';

//HELPERS
import { SetTexture } from '../../helpers/index';  /* PostData */

//DATA
import { HEADPHONE_PRODUCT } from '../../services/data';

//COMPONENTS
import SpinnerTwo from '../SpinnerTwo';

export default function HeadphoneModelConfig({ refIframeContainer, pageName }) {

    //IFRAME REFS
    const refIframeRow = useRef(null);
    const refIframeCol = useRef(null);

    //STATES
    const [materialsList, setmaterialsList] = useState([]);

    //VARIABLES 
    const [timeDelay, setTimeDelay] = useState(1000); 

    const [isApplyTextures, setIsApplyTextures] = useState(false);

    //CUSTOM HOOK
    const { viewerIframe, api, loadingViewer, 
    ios, android, setArFiles, setProductNameState, loadingEffect } = 
    useSkecthfabViewer('fe28536a634a497998a9c7f9f531395c',  
        { 
        productName: 'Charmeleon White Resting', 
        //description: 'Keurig K-Cafe',
        //price: '$250',
        initial_qr_img: 'spokeshave-qr.png',  
        initial_ar_img: HEADPHONE_PRODUCT.initial_ar_img,  /* HEADPHONE_PRODUCT.initial_ar_img */
        pageNameForQr: pageName,
        refIframeContainer: refIframeContainer,
        refIframeRow: refIframeRow,
        refIframeCol: refIframeCol,
        iframeBorder: false,
        //buyInfoInAr: true,
        //iosCallToActionTextInAr: 'Add to cart',
        viewerContainerClassList: ['container-fluid', 'container_3decommerce_iframe'],
        viewerRowClassList: ['row'],
        viewerColClassList: ['col-12'],  
        },
    ); 

    useEffect(() => {  

        if(!loadingViewer){   

            //GET MATERIALS
            api.getMaterialList(function(err, materials) {
                if (!err) {

                    let materialArray = [];
                    //console.log('MATERIALS list:', materials);
      
                    materials.forEach(material => {           
                            if(material.name === 'Material'){ 
                                materialArray.push(material);
                            }                                                               
                        setmaterialsList(materialArray);           
                    });
                        
                }
            });

            setTimeout(() => {
                setTimeDelay(0);
            }, timeDelay);

        }     
        
    },[api, loadingViewer, timeDelay]); 
    
    const HandleClick = (option) => {
        let delayTime = 1000;

        let numberOfChanges = option.changes.length;

        let count = 0;
        let countB = 0;        
        
        setIsApplyTextures(true);

        option.changes.forEach((change) => {
            if(!change.uid){
                let textureUrl = (ios || android) ? change.value.movil : change.value.desktop;
                api.addTexture(textureUrl, (err, uid) => {                              //STEP 1
                    if (!err) {
                        change.uid = uid;                                  // <---- ERROR / PROBLEM
                        SetTexture(api, change.uid, materialsList, change.channel, change.material).then(data => {        //STEP 2 
                            
                            count += 1;
                            if(count === numberOfChanges){  
                                
                                let lastCount = 0;

                                materialsList.forEach(materialUpdated => {
                                    api.setMaterial(materialUpdated, function(){          //STEP 3
                                        lastCount += 1;
                                        if(lastCount === materialsList.length){
                                            setTimeout(() => {                  
                                                setIsApplyTextures(false); 
                                            }, delayTime);
                                        }

                                    }); 
                                });
                       
                            }
                            
                            /* FOR TO APPLY ONE BY ONE
                            
                            api.setMaterial(data, function() {             
                                count += 1;
                                if(count === numberOfChanges){  
                                    setTimeout(() => {
                                        setIsApplyTextures(false); 
                                    }, delayTime);
                                }
                            });  
                            
                            setTimeout(() => {                  
                                    setIsApplyTextures(false); 
                                }, delayTime); 
                                
                            */
                        }); 
                    }
                }); 
            } else {
                delayTime = 0;
                SetTexture(api, change.uid, materialsList, change.channel, change.material).then(data => {
                    api.setMaterial(data, function() {
                        countB += 1;
                        if(countB === numberOfChanges){  
                            setTimeout(() => {
                                setIsApplyTextures(false); 
                            }, delayTime);
                        }
                    });  
                });
            }
        }); 

        /* OLD APPOACH */

        /* //Step 1
        option.changes.forEach(change => {
            if(!change.uid){
                api.addTexture(change.value, (err, uid) => {
                    if (!err) {
                        change.uid = uid;
                    }
                });
            } else {
                delayTime = 0;
            }
        });

        //Step 2
        setTimeout(() => {
            //console.log('option (reference) :::step2', option);  
            option.changes.forEach(change => {
                SetTexture(api, change.uid, materialsList[0], change.channel);
            });
        }, delayTime); 

        //Step 3
        setTimeout(() => {
            //console.log('option (option) :::step3', materialsList[0]); 
            api.setMaterial(materialsList[0], function() {
                //console.log(`Material updated channel`);
            }); 
        }, delayTime);  */

        setArFiles(option.ar_files);
        setProductNameState(`Charmeleon ${option.value} Resting`);

    }

    return (<>

        {/* DESKTOP */}
        
        {/* </DESKTOP> */}
            
        <div className="row" ref={refIframeRow}>

            {/* VIEWER */} 
            <div className="col-12" ref={refIframeCol}>
                {(!loadingViewer && isApplyTextures) && <div className="loading-texture-effect"> 
                    <SpinnerTwo />
                </div>}
                {viewerIframe}

                {!loadingViewer && <div className="hp-configurator-options">

                    {HEADPHONE_PRODUCT.colors.color_primary.map((option) => {

                        return(
                            <button disabled={isApplyTextures || loadingEffect} style={{backgroundColor: `#${option.hex}`}} key={option.id} onClick={() => HandleClick(option)}></button>
                        )

                    })}

                </div>}

            </div>  
            {/* </VIEWER> */}

        </div>

    </>)
}