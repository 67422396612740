import React from 'react';
import './styles.css';

export default function Spinner(){
    return(
    <div className="loadingio-spinner-ellipsis-kiz2l0qkmz"><div className="ldio-zxjp1fvj1mf">
    <div></div><div></div><div></div><div></div><div></div>
    </div></div>
    )
}

