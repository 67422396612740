import React, {
  useEffect,
  useRef,
  useState,
} from "react"; /* THIS IS A NEURABLE unknown models page  */
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

//Styles
import "./styles.css";

export default function CreateQR() {
  //URL PARAMS
  // const { product } = useParams();

  const refQrGrid = useRef(null);

  const [url, setUrl] = useState("");

  const [width, setWidth] = useState(560);

  const [height, setHeight] = useState(560);

  /* useEffect(() => {
    new window.QRCode(refQrGrid.current, {
      text: `https://fresco-augmented-reality.com/modConcepts/prototype/${product}`, //https://neurable.com?prototype=${product}
      width: 700,
      height: 700,
      colorDark: "#57616e",
      colorLight: "#ffffff",
    });
  }, [product]); */

  function generateQR() {
    refQrGrid.current.innerHTML = "";

    new window.QRCode(refQrGrid.current, {
      text: url,
      width: width,
      height: height,
      colorDark: "#57616e",
      colorLight: "#ffffff",
    });
  }

  return (
    <>
      <Helmet>
        <title>Fresco Design | Create QR</title>
      </Helmet>

      <main className="models_page">
        <div className="">
          <figure ref={refQrGrid}>
            {/* <img src={FrescoIcon} alt="Fresco Desing Icon"/> */}
          </figure>
          {/* <div className="models_button">
                    <Link className="btn_fresco_rounded_outline" to="/" >Back Home</Link>
                </div> */}

          <div className="qr__options">
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <input
                className="form-control"
                type="text"
                placeholder="URL"
                onChange={(e) => setUrl(e.target.value)}
                style={{ width: "600px" }}
              />
            </div>
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <label>
                Width
                <input
                  className="form-control"
                  type="number"
                  placeholder="width"
                  onChange={(e) => setWidth(e.target.value)}
                  style={{ width: "90px", marginLeft: "6px" }}
                  value={width}
                />
              </label>
              <label>
                Height
                <input
                  className="form-control"
                  type="number"
                  placeholder="height"
                  onChange={(e) => setHeight(e.target.value)}
                  style={{ width: "90px", marginLeft: "6px" }}
                  value={height}
                />
              </label>
              <button className="btn btn-primary" onClick={() => generateQR()}>
                generate QR
              </button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
