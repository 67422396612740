import React, { useEffect, useRef } from "react";

//  Styles
import "./styles.css";

export default function SwandooCharlieViewer({
  height = { desktop: "500px", mobile: "500px" },
  maxWidth = { desktop: "500px", mobile: "500px" },
  width = { desktop: "100%", mobile: undefined },
}) {
  const FRESCO_SERVER = "https://s3.amazonaws.com/fresco-augmented-reality.com";

  const fresco3DViewerCss = `${FRESCO_SERVER}/libs/viewer/v4.0/Fresco3DViewer.css`;
  const frescoViewerJs = `${FRESCO_SERVER}/libs/viewer/v4.0/Fresco3DViewer.min.js`;

  const projectBundleCss = `${FRESCO_SERVER}/swandoo_objects/bundle/index.42774295.css`;
  const projectBundleJs = `${FRESCO_SERVER}/swandoo_objects/bundle/index.7d499b28.js`;
  const projectVendorJs = `${FRESCO_SERVER}/swandoo_objects/bundle/vendor.78b5cc61.js`;

  const ViewerContainer = useRef(null);
  const ViewerParent = useRef(null);

  useEffect(() => {
    const scriptForViewerCDN = document.createElement("script");
    scriptForViewerCDN.setAttribute("language", "javascript");
    scriptForViewerCDN.setAttribute("type", "text/javascript");
    scriptForViewerCDN.setAttribute("src", frescoViewerJs);
    document.body.appendChild(scriptForViewerCDN);

    scriptForViewerCDN.onload = function () {
      document.body.appendChild(scriptForSwandooViewerVendor);
    };

    // Fresco Viewer CSS
    const linkForFrescoCSS = document.createElement("link");
    linkForFrescoCSS.setAttribute("crossorigin", "anonymous");
    linkForFrescoCSS.setAttribute("rel", "stylesheet");
    linkForFrescoCSS.setAttribute("href", fresco3DViewerCss);
    document.head.appendChild(linkForFrescoCSS);

    /* ==========================  CONFIGURATOR LAYOUT  ==========================  */
    // Configurator Logic CSS
    const linkForConfiguratorCSS = document.createElement("link");
    linkForConfiguratorCSS.setAttribute("crossorigin", "anonymous");
    linkForConfiguratorCSS.setAttribute("rel", "stylesheet");
    linkForConfiguratorCSS.setAttribute("href", projectBundleCss);
    document.head.appendChild(linkForConfiguratorCSS);

    // Vendor Swandoo Viewer JS
    const scriptForSwandooViewerVendor = document.createElement("script");
    scriptForSwandooViewerVendor.setAttribute("language", "javascript");
    scriptForSwandooViewerVendor.setAttribute("type", "module");
    scriptForSwandooViewerVendor.setAttribute("src", projectVendorJs);

    // ==== ARROWS =====
    function stopViewer() {
      if (!window.swandooViewer.isStopped) {
        window.swandooViewer.stop();
      }
    }

    const carouselControlPrev = document.querySelectorAll(
      ".carousel-control-prev-viewer"
    );
    const carouselControlNext = document.querySelectorAll(
      ".carousel-control-next-viewer"
    );

    scriptForSwandooViewerVendor.onload = function () {
      // Configurator Logic JS
      const scriptForSwandooViewer = document.createElement("script");
      scriptForSwandooViewer.setAttribute("language", "javascript");
      scriptForSwandooViewer.setAttribute("type", "module");
      scriptForSwandooViewer.setAttribute("src", projectBundleJs);

      scriptForSwandooViewer.onload = () => {
        if (height) {
          let finalHeight;
          if (window.innerWidth > 800) {
            if (height.desktop) {
              finalHeight = height.desktop;
            }
          } else {
            if (height.mobile) {
              finalHeight = height.mobile;
            }
          }
          if (finalHeight) {
            ViewerParent.current.style.height = finalHeight;
          }
        }

        if (maxWidth) {
          let finalMaxWidth;
          if (window.innerWidth > 800) {
            if (maxWidth.desktop) {
              finalMaxWidth = maxWidth.desktop;
            }
          } else {
            if (maxWidth.mobile) {
              finalMaxWidth = maxWidth.mobile;
            }
          }
          if (finalMaxWidth) {
            ViewerParent.current.style.maxWidth = finalMaxWidth;
          }
        }

        if (width) {
          let finalWidth;
          if (window.innerWidth > 800) {
            if (width.desktop) {
              finalWidth = width.desktop;
            }
          } else {
            if (width.mobile) {
              finalWidth = width.mobile;
            }
          }
          if (finalWidth) {
            ViewerParent.current.style.width = finalWidth;
          }
        }

        window.swandooViewer.addEventListener("viewerIsReady", async () => {
          setTimeout(() => {
            const arButtonImage = document.querySelector(
              ".fresco-viewer__ar button img"
            );
            if (arButtonImage) arButtonImage.classList.add("new-styles");
            // arButtonImage.style.height = "80px";
            // arButtonImage.style.width = "80px";

            // Model position
            // const model = window.swandooViewer.models[0];
            // model.scene.position.y = -0.085;

            // const colors = document.querySelector(".swandoo-colors");
            // if (colors) colors.style.left = "5rem";
            // const arButton = document.querySelector(".fresco-viewer__ar");
            // arButton.addEventListener("click", () => {
            //   if (document.body.style.overflow !== "hidden") {
            //     document.body.style.overflow = 'hidden';
            //   }
            // });
            // fresco-viewer__modal-section_closeBtn
            // if (arButton) arButton.style.marginRight = "4rem";
          }, 2000);

          // ======= EVENTS =========
          carouselControlPrev.forEach((prevArrow) => {
            prevArrow.addEventListener("click", stopViewer);
          });
          carouselControlNext.forEach((prevArrow) => {
            prevArrow.addEventListener("click", stopViewer);
          });
        });
      };

      document.body.appendChild(scriptForSwandooViewer);
    };

    return () => {
      carouselControlPrev.forEach((prevArrow) => {
        prevArrow.removeEventListener("click", stopViewer);
      });
      carouselControlNext.forEach((prevArrow) => {
        prevArrow.removeEventListener("click", stopViewer);
      });
    };
  }, []);

  useEffect(() => {}, []);

  return (
    // <div ref={ViewerContainer} className="container-swandoo-viewer">
    <div ref={ViewerParent} id="swandoo-viewer"></div>
    // </div>
  );
}
