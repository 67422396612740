import React, { useEffect, useRef, useState, useCallback } from 'react'; 
import './styles.css';

//HELPERS
import { StartAndHidePlayButton, Platform } from '../../helpers/index'; 

//COMPONENTS
import Spinner from '../../components/Spinner';
import ArButton from '../../components/ArButton';
import ColorOptions from "./ColorOptions";

//BODY
import UiButtons from './UiButtons';
import SpinnerTwo from '../../components/SpinnerTwo';

export default function useSketchfabViewerBk(model, 
  { productName = '', description = '', price = '', initial_qr_img, initial_ar_img, pageNameForQr = '', refIframeContainer, refIframeRow, refIframeCol, bgStartViewer = undefined, 
  arbtn = true, buyInfoInAr = false, iosCallToActionTextInAr = '', playdesign = 1, addTimeToLoading = 0, iframeBorder = true, viewerContainerClassList = undefined, 
  viewerRowClassList = undefined,  viewerColClassList = undefined, forToStartBtn = undefined } = {},
  { autostart = 0, camera = 1, preload = 0, ui_color = 'C4CC1F', ui_hint = 2, ui_controls = 1, ui_fullscreen = 0, ui_stop = 0, ui_infos = 0, load_before = 0,
  ui_help = 0, ui_settings = 0, ui_inspector = 0, ui_watermark = 0, ui_watermark_link = 0, ui_ar = 0, ui_vr = 0, ui_loading = 0, ui_start= 0, 
  ui_annotations = 0 } = {}
  ){  

    //VIEWER REFS
    const refViewer = useRef(null); 
    const refViewerGrid = useRef(null);
    const refStartViewerBtn = useRef(null);
    const refStartViewerImg = useRef(null);

    //IFRAME
    const [api, setApi] = useState();
    const [loadingViewer, setLoadingViewer] = useState(true);
    const [loadingEffect, setLoadingEffect] = useState(false);

    const [iframeStart, setIframeStart] = useState(() => {
        if(autostart === 0){
          return false;
        } else if(autostart === 1){
          return true;
        }
    }); 

    const [isApplyTextures, setIsApplyTextures] = useState(false);

    //START VIEWER IMAGE
    const [startViewerImg, setStartViewerImg] = useState('');

    //PLATFORM
    const [ios, setIos] = useState(false);
    const [android, setAndroid] = useState(false);
     
    //REALITY IMG (Android and IOS img object)
    const [arFiles, setArFiles] = useState(initial_ar_img);
    const [productNameState, setProductNameState] = useState(productName);

    //FULL SCREEN 
    const [fullScreen, setFullScreen] = useState(false);

    //ONLY FOR THIS MODEL
    const [colorSelected, setColorSelected] = useState(null);
    const [modelMaterials, setModelMaterials] = useState(null);

    const HandleStart = useCallback(() => {

      if(forToStartBtn){

        switch (forToStartBtn.device) {
          case 'ios':

              if(ios){
                if(forToStartBtn.condition){
                  StartAndHidePlayButton(api, iframeStart, setIframeStart, loadingViewer, setLoadingEffect, refStartViewerBtn);
                } else {
                  forToStartBtn.function();
                }
              } else {
                StartAndHidePlayButton(api, iframeStart, setIframeStart, loadingViewer, setLoadingEffect, refStartViewerBtn);
              }
            
            break;

          case 'android':

              if(android){
                if(forToStartBtn.condition){
                  StartAndHidePlayButton(api, iframeStart, setIframeStart, loadingViewer, setLoadingEffect, refStartViewerBtn);
                } else {
                  forToStartBtn.function();
                }
              } else {
                StartAndHidePlayButton(api, iframeStart, setIframeStart, loadingViewer, setLoadingEffect, refStartViewerBtn);
              }
            
            break;

          case 'desktop':

              if(android || ios){ 
                StartAndHidePlayButton(api, iframeStart, setIframeStart, loadingViewer, setLoadingEffect, refStartViewerBtn);
              } else {
                if(forToStartBtn.condition){
                  StartAndHidePlayButton(api, iframeStart, setIframeStart, loadingViewer, setLoadingEffect, refStartViewerBtn);
                } else {
                  forToStartBtn.function();
                }
              }
            
            break;  
        
          default:
            if(forToStartBtn.condition){
              StartAndHidePlayButton(api, iframeStart, setIframeStart, loadingViewer, setLoadingEffect, refStartViewerBtn);
            } else {
              forToStartBtn.function();
            }
            break;
        }

      } else {
        StartAndHidePlayButton(api, iframeStart, setIframeStart, loadingViewer, setLoadingEffect, refStartViewerBtn);
      }

    },[api, iframeStart, setIframeStart, loadingViewer, refStartViewerBtn, forToStartBtn, ios, android]);                         

    const viewerIframe = (<div ref={refViewerGrid} className="bksection-iframe" style={{border: `${iframeBorder && '1px solid lightgray' }`}} >          

      {/* LOADING */}                                     

        {(loadingEffect && iframeStart) && <div className="section-loading">   {/* loadingViewer && iframeStart */}              
            <div className="model-percent">
              {/*<h3 id="percent"></h3>*/}          
            </div> 
            <Spinner />    
        </div>}         

        {/* Apply Textures */}   

        {(!loadingEffect && isApplyTextures) && <div className="loading-texture-effect">
          <SpinnerTwo />
        </div>}                                 

      {/* INITIALIZATION BUTTON ( FOR START AUTOMATICALLY (OPTION 1) COMMENT THE CODE BELOW) */} 

      {!iframeStart && <div ref={refStartViewerBtn} className="section-start-button">
        
        {(bgStartViewer !== undefined) && <img className="start-img_bg" ref={refStartViewerImg} src={startViewerImg} alt="" />}   
        
        {/* EXAMPLE ONE */} 
        {api && <> 
          { (playdesign === 1) && <button onClick={() => HandleStart()} className="model-play-button"> 
            <i className="fa fa-play model-play-icon" aria-hidden="true"></i>
            <span className="model-label">3D</span>
          </button> }
        </>}  

        {/* EXAMPLE TWO */}   
        {api && <> 
          { (playdesign === 2) && <button onClick={() => HandleStart()} className="model-play-button"> 
          <i className="fa fa-play model-play-icon" aria-hidden="true"></i>
          <span className="model-label">Start</span>
          </button>}
        </>}  

      </div>}                                 

      {/* AR BUTTONS */}

      {arbtn && <div className="section-ar-buttons_ab"> 
        <ArButton productName={productNameState} description={description} price={price} buyInfoInAr={buyInfoInAr} 
        iosCallToActionTextInAr={iosCallToActionTextInAr} arFiles={arFiles} setFullScreen={setFullScreen} pageNameForQr={pageNameForQr} /> 
      </div>}

      {/* FULL SCRREN BUTTON */}     

      {api && <UiButtons api={api} iframeStart={iframeStart} setIframeStart={setIframeStart} 
      refViewerGrid={refViewerGrid} fullScreen={fullScreen} setFullScreen={setFullScreen} 
      refIframeContainer={refIframeContainer} refIframeRow={refIframeRow} refIframeCol={refIframeCol} refViewer={refViewer} 
      refStartViewerBtn={refStartViewerBtn} ios={ios} android={android} loadingViewer={loadingViewer} setLoadingEffect={setLoadingEffect}
      viewerContainerClassList={viewerContainerClassList} viewerRowClassList={viewerRowClassList} viewerColClassList={viewerColClassList} /> }

      {/* OPTIONS COLORS */} 

      {/*(api && fullScreen) && */}
      {<ColorOptions api={api} ios={ios} android={android} fullScreen={fullScreen} setArFiles={setArFiles} 
      colorSelected={colorSelected} setColorSelected={setColorSelected} setProductNameState={setProductNameState} 
      modelMaterials={modelMaterials} isApplyTextures={isApplyTextures} setIsApplyTextures={setIsApplyTextures} 
      loadingEffect={loadingEffect} /> }

      {/* IFRAME */}
        <iframe 
        ref={refViewer}
        title="sketchfab-viewer"
        sandbox="allow-scripts allow-same-origin allow-popups allow-forms" 
        frameBorder="0" allow="autoplay; fullscreen; vr" allowvr="true" allowFullScreen
        mozallowfullscreen="true" webkitallowfullscreen="true" className="iframe-show"
        /> 

      {/* </iframe> */}

    </div>); 

      useEffect(() => { 

        //------ AR ---------           
        Platform(setIos, setAndroid, iframeStart, bgStartViewer, refStartViewerImg, setStartViewerImg);   
        //-------------------       
         
        let client = new window.Sketchfab(refViewer.current);
        client.init(model, {
            success: (api) => {

              setApi(api);

              if(load_before === 1){
                api.load();
              }

              //api.start();  //if you want to initialize before                                          
                
                /*api.addEventListener('modelLoadProgress', function(eventData) {
                    if(iframeStart){  // OPTION 3 NEED THIS CONDITIONAL
                      var modelPorcent = document.getElementById('percent');
                      var percent = Math.floor(eventData.progress * 100);
                      console.log('mesh Load Progress', percent);
                      modelPorcent.innerHTML=`${percent}%`; 
                    }
                });*/     

              api.addEventListener('viewerready', function() {   
                setLoadingViewer(false);    
                //LOADING TIME
                setTimeout(() => {                  
                  setLoadingEffect(false);
                }, addTimeToLoading);
              });               
               
            }, 
            error: () => {
            console.log('Viewer error');
            },
           autostart: autostart, //0 wait for user make click the play. 
           camera: camera, //0 No initializar annimations.  
           preload: preload, //1 force all resources (textures) to download before is displayed.
           ui_color: ui_color, //landing and button color.
           ui_controls: ui_controls, //0 hide everything the ui controls. 
           ui_fullscreen: ui_fullscreen,  //0 hide fullscreen control.
           ui_stop: ui_stop, //0 hide the disable viewer (at the top-right).
           ui_hint: ui_hint, //0 never show, 1 show it once per session, always show.
           ui_infos: ui_infos,  //Setting to 0 will hide the model info bar at the top of the viewer.
           ui_help: ui_help, //Setting to 0 will hide the Help button.
           ui_settings: ui_settings, //Setting to 0 will hide the Settings button.
           ui_inspector: ui_inspector, //Setting to 0 will hide the inspector button.
           ui_watermark: ui_watermark, //Setting to 0 remove the Sketchfab logo watermark.  
           ui_watermark_link: ui_watermark_link, //Setting to 0 remove the link from the Sketchfab logo watermark.
           ui_ar: ui_ar,  //Setting to 0 will hide the AR button. See Augmented Reality for more information.
           ui_vr: ui_vr,  //Setting to 0 will hide the View in VR button.
           ui_loading: ui_loading,  //Setting to 0 will hide the viewer loading bars.
           ui_start: ui_start,  //0 hide the Start iframe button (if the autostart option is 0).
           annotations_visible: 0, //Setting to 0 will hide annotations by default.
           annotation_tooltip_visible: 0, //Setting to 0 will hide annotation tooltips by default.
           ui_annotations: ui_annotations,
        });
        },[model]);  

        return { viewerIframe, api, loadingViewer, iframeStart, setIframeStart, 
        ios, android, refStartViewerBtn, setArFiles, refViewer, setProductNameState, 
        colorSelected, setColorSelected, setModelMaterials, isApplyTextures, setIsApplyTextures, setLoadingEffect, loadingEffect };
}
