import React from 'react';
import { Helmet } from 'react-helmet';

//PAGE STYLES 
import '../HouseplantLighter/styles.css';

//COMPONENTS
import Navbar from '../../components/Navbar';
import FrescoWidget from '../../components/FrescoWidget';
import Footer from '../../components/Footer';

function FrescoSole() {
    return (<>
        <Helmet>
            <title>Sole Model | Fresco Design</title>
            <meta name="description" content="Fresco Model Twin AR Sole" />
            {/* opengraph protocol */}
            <meta property="og:description" content="Fresco Model Twin AR Sole" /> 
            <meta property="og:title" content="Sole Model | Fresco Design" />
            <meta property="og:url" content="https://fresco-augmented-reality.com/Fresco_sole" />
            <link rel="canonical" href="https://fresco-augmented-reality.com/Fresco_sole" />
            <link rel="alternate" href="https://www.fresco-design.com.ar/Fresco_sole" hreflang="es-AR" />
            <link rel="alternate" href="https://www.fresco-design.com/Fresco_sole" />
        </Helmet>

        <Navbar noShowOptions={true} />

        <div className="container-fluid container_3decommerce_iframe"> 
         
            {/* DESKTOP */}
            <section className="desktop-title-iframe mt-5">
                <div className="row">
                    <div className="col-12">
                        <h3>Fresco Model Twin AR Sole</h3>
                    </div>
                </div>  
            </section>
            {/* </DESKTOP> */}

            <FrescoWidget elementID="fresco-sole-model" frescoKey="c5f0f55b104648e08c1a896899d48676" /> 
        </div>

        <div className="container container_3decommerce">

            <section className="section_contact_3decommerce mt-5 mb-3">
                <div className="row">
                    <div className="col-12">
                       <p>                     
                            Fresco Immersive Commerce leverages AR to create online experiences for consumers to discover and explore your products.
                       </p>
                    </div> 
                    <div className="col-12">
                        <p>
                            <a href="https://www.fresco-design.com/contact">
                                Let’s discuss how Fresco can grow your eCommerce business
                                <i className="fa fa-angle-right fa-lg" aria-hidden="true"></i>
                            </a>
                        </p>
                    </div>
                </div>  
            </section>
             
        </div> 

        <Footer />

    </>)
}

export default FrescoSole;
