import React from 'react';
import './styles.css';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

//IMAGES
import FrescoIcon from '../../images/icons/fresco_icon_transparent.png';

export default function NotFound(){

   return(<>
   <Helmet>
      <title>Fresco Design | Not Found</title>
   </Helmet>
   <main className="notfound_page">
      <div className="notfound_grid">
            <figure>
            <img src={FrescoIcon} alt="Fresco Desing Icon"/>
            </figure>
            <div className="notfoud_text">
               <h1>404 - Page not found</h1>       
            </div>
            <div className="notfoud_button">
               <Link className="btn_fresco_rounded" to="/" >Back Home</Link>
            </div>
      </div>
   </main>
   </>)

}