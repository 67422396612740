import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

//DATA
import { AWS_DOMAIN } from "../../services/data";

//IMAGES
import iconQr from "../../images/icons/guide_icon_qr.png";

//COMPONENTS
import RealityDesktopUi from "../../components/RealityDesktopUi";

export default function Reality() {
  //REF
  const refIosBtn = useRef(null);
  const refAndroidBtn = useRef(null);

  //STATES
  const [showUiDesktop, setShowUiDesktop] = useState(false);

  const [productName, setProductName] = useState("");
  const [modelPageState, setModelPageState] = useState("");
  const [iosArFile, setIosArFile] = useState("");
  const [androidArFile, setAndroidArFile] = useState("");
  const [serverRoutingState, setServerRoutingState] = useState("");

  //EXTRAS
  //const [productDescription, setProductDescription] = useState('');
  //const [productPrice, setproductPrice] = useState('');
  //const [productBuyInfo, setProductBuyInfo] = useState('');
  //const [productCallToAction, setProductCallToAction] = useState('');

  //URL PARAMS
  const {
    modelPage,
    product,
    ServerRouting,
    androidFileName,
    iosFileName,
    androidAndIosFileName,
  } = useParams();

  /* 
      description,
      price,
      buyInfoInAr,
      iosCallToActionTextInAr, 
    */

  /* function HandleTapIos (event) {   
        if (event.data === "_apple_ar_quicklook_button_tapped") {
            // handle the user tap. 
            alert('Thanks you for testing our model!');  
        }
    } */

  function HandleAndroid() {
    window.location = `intent://arvr.google.com/scene-viewer/1.0?file=${AWS_DOMAIN}${
      serverRoutingState === "" ? "" : `${serverRoutingState}/`
    }${androidArFile}.glb#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`;

    setTimeout(() => {
      window.open(`${AWS_DOMAIN}${modelPageState}`, "_blank");
    }, 3000);
  }

  useEffect(
    function () {
      try {
        if (product !== "none") {
          setProductName(decodeURI(product));
        }

        setModelPageState(modelPage);
        setServerRoutingState(ServerRouting.replaceAll("=", "/"));

        /* console.log({
          androidFileName: androidFileName,
          iosFileName: iosFileName,
          androidAndIosFileName: androidAndIosFileName,
        }); */

        if (androidFileName && iosFileName) {
          setIosArFile(iosFileName);
          setAndroidArFile(androidFileName);
        } else {
          setIosArFile(androidAndIosFileName);
          setAndroidArFile(androidAndIosFileName);
        }
      } catch (error) {
        window.location = `${AWS_DOMAIN}${modelPageState}`;
      }

      if (
        (navigator.platform === "iPad" ||
          navigator.platform === "iPhone" ||
          (navigator.platform.includes("Mac") &&
            navigator.maxTouchPoints > 1)) &&
        iosArFile !== ""
      ) {
        refIosBtn.current.click();
        //TEST
        //internalRefIosLink.addEventListener("message", HandleTapIos, false);
        //---
        setTimeout(() => {
          window.location = `${AWS_DOMAIN}${modelPageState}`;
        }, 2000);
      } else if (
        navigator.platform.includes("Win") ||
        navigator.platform.includes("Mac")
      ) {
        //DESKTOP
        setShowUiDesktop(true);
      } else {
        if (androidArFile !== "") {
          refAndroidBtn.current.click();
        }
      }

      console.log("Info Block:", {
        productName: productName,
        iosArFile: iosArFile,
        androidArFile: androidArFile,
        androidURL: `${AWS_DOMAIN}${
          serverRoutingState === "" ? "" : `${serverRoutingState}/`
        }${androidArFile}.glb`,
      });
      /* productDescription: productDescription,
        productPrice: productPrice,
        productBuyInfo: productBuyInfo,
        productCallToAction: productCallToAction, */

      /* return () => {
            internalRefIosLink.removeEventListener("message", HandleTapIos, false);
        } */
    },
    [
      product,
      modelPage,
      ServerRouting,
      serverRoutingState,
      androidFileName,
      iosFileName,
      iosArFile,
      androidArFile,
      productName,
      modelPageState,
      androidAndIosFileName,
    ]
  );

  return (
    <>
      <Helmet>
        <title>Fresco Design | Reality</title>
      </Helmet>
      {showUiDesktop && (
        <RealityDesktopUi
          productName={productName}
          modelPageState={modelPageState}
        />
      )}

      <button
        className="invisible"
        ref={refAndroidBtn}
        onClick={() => HandleAndroid()}
      >
        <img src={iconQr} alt="Fresco Design Icon Qr" />
      </button>

      <a
        className="invisible"
        rel="ar"
        ref={refIosBtn}
        href={
          process.env.PUBLIC_URL +
          `/images/${
            serverRoutingState === "" ? "" : `${serverRoutingState}/`
          }${iosArFile}.usdz`
        }
      >
        <img src={iconQr} alt="Fresco Design Icon Qr" />
      </a>
    </>
  );
}
