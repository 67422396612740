/* API */
export const HOSTGATOR_DOMAIN = "https://www.fresco-design.com.ar/";

export const FRESCO_FOLDER_URL = "immersive-commerce";

export const FRESCO_FILE_URL = "home.html";

export const FRESCO_HOSTGATOR_SERVER = `${HOSTGATOR_DOMAIN}3d_configurator/local/`;

export const AWS_DOMAIN = "https://fresco-augmented-reality.com/";

export const FRESCO_API = "https://api.fresco-augmented-reality.com/";

export const isMobile = window.innerWidth <= 1024;

/*  BIKE 3D MODEL  */

//Name of Materials in blender for the type of model
export const blenderMaterialsNameForModel = {
  //<number-blender-material>_<blender-material-group> ...
  mda_aluminum: "1_1_", //start mid-drive model
  mda_gray: "2_1_",
  mda_lime: "3_1_",
  mda_teal: "4_1_",
  mda_violet: "5_1_",
  mdb_aluminum: "6_1_",
  mdb_gray: "7_1_",
  mdb_lime: "8_1_",
  mdb_teal: "9_1_",
  mdb_violet: "10_1_",
  mdc_aluminum: "11_1_",
  mdc_gray: "12_1_",
  mdc_lime: "13_1_",
  mdc_teal: "14_1_",
  mdc_violet: "15_1_", //finish mid-drive model

  rha_aluminum: "16_2_", //start rear-hub model
  rha_gray: "17_2_",
  rha_lime: "18_2_",
  rha_teal: "19_2_",
  rha_violet: "20_2_",
  rhb_aluminum: "21_2_",
  rhb_gray: "22_2_",
  rhb_lime: "23_2_",
  rhb_teal: "24_2_",
  rhb_violet: "25_2_",
  rhc_aluminum: "26_2_",
  rhc_gray: "27_2_",
  rhc_lime: "28_2_",
  rhc_teal: "29_2_",
  rhc_violet: "30_2_", //finish rear-hub model
};

//Name of Materials in blender for the Fork
export const blenderMaterialsNameForFork = {
  fork_aluminum: "32_3_",
  fork_copper: "31_3_",
};

export const BIKE_PRODUCT = {
  id: 1,
  product_name: "Fresco E-Bike",
  description: "E-bike designed by Fresco Design",
  price: "$3,499",
  model_id: "5a4fe6f0584343508b9302b33f5eb3e9",
  initial_ar_img: {
    android: "fb-e-mtb-3d-viewer-version_v1",
    ios: "fb_brighter_rotated_ik_210420_v1",
    serverRouting: "3d_files/e-bike",
    landingPage: "", //Example(initial Bike): https://api.fresco-augmented-reality.com/openAR?sha1=8fc78f566aa9d0d6907f6ae7767655b4145f27bc
    local: false,
  },
  material_primary: "textured_metallic_blue_paint",
  material_secondary: "brushed_metal_crome2",
  material_tertiary: "textured_metallic_blue_paint",
  istexture: false,
  colors: {
    initial_color_primary: "teal",
    initial_color_secondary: "Copper",
    initial_color_tertiary: "Pack B",
    color_primary: [
      {
        id: 1,
        hex: "3e9fae",
        color: `${AWS_DOMAIN}bike_objects_v2/textures/frame/`, // EXAMPLE: <model>(rear-hub/mid-drive)/<type-decals>(pack_b)/<color>(teal).<extension>(png/jpg)
        thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/frame/`,
        reference: "teal",
        value: "Teal",
      },
      {
        id: 2,
        hex: "a3b64d",
        color: `${AWS_DOMAIN}bike_objects_v2/textures/frame/`,
        thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/frame/`,
        reference: "lime",
        value: "Lime",
      },
      {
        id: 3,
        hex: "5b5298",
        color: `${AWS_DOMAIN}bike_objects_v2/textures/frame/`,
        thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/frame/`,
        reference: "violet",
        value: "Violet",
      },
      {
        id: 4,
        hex: "505462",
        color: `${AWS_DOMAIN}bike_objects_v2/textures/frame/`,
        thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/frame/`,
        reference: "gray",
        value: "Gray",
      },
      {
        id: 5,
        hex: "a5adb1",
        color: `${AWS_DOMAIN}bike_objects_v2/textures/frame/`,
        thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/frame/`,
        reference: "aluminum",
        value: "Aluminum",
      },
    ],
    color_secondary: [
      {
        id: 1,
        color: "#d0d0d0",
        thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/fork/fork-aluminum_frame-`, // EXAMPLE: <color>(teal).<extension>(png/jpg)
        reference: "aluminum",
        value: "Aluminum",
      },
      {
        id: 2,
        color: "#b67834",
        thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/fork/fork-cooper_frame-`,
        reference: "copper",
        value: "Copper",
      },
    ],
    color_tertiary: [
      {
        id: 1,
        color: `${AWS_DOMAIN}bike_objects_v2/textures/frame/`,
        thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/decals/`,
        reference: "pack_a",
        value: "Pack A",
      },
      {
        id: 2,
        color: `${AWS_DOMAIN}bike_objects_v2/textures/frame/`,
        thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/decals/`, // EXAMPLE: <model>(mid-drive)/<type-decals>(pack_b)/<color>(teal.png)
        reference: "pack_b",
        value: "Pack B",
      },
      {
        id: 3,
        color: `${AWS_DOMAIN}bike_objects_v2/textures/frame/`,
        thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/decals/`,
        reference: "pack_c",
        value: "Pack C",
      },
    ],
  },
  features: [
    [
      {
        id: 1,
        name: "Drivetrain",
        reference: "drivetrain",
        default_option_id: 416,
        default_option_name: "mid-drive_motor",
        default_option_value: "Mid Drive",
        model_options: [
          {
            id: 1,
            node_instanceID: 533,
            thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/nodes/rear-hub_motor.png`,
            node_name: "rear-hub_motor",
            value: "Rear Hub",
            blenderValue: "rad_rear-hub",
          },
          {
            id: 2,
            node_instanceID: 416,
            thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/nodes/mid-drive_motor.png`,
            node_name: "mid-drive_motor",
            value: "Mid Drive",
            blenderValue: "rad_mid-drive",
          },
        ],
      },
    ],
    [
      {
        id: 2,
        name: "Battery",
        reference: "battery",
        default_option_id: 631,
        default_option_name: "single_battery",
        default_option_value: "Single Battery",
        model_options: [
          {
            id: 1,
            node_instanceID: 631,
            thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/nodes/rear-hub/single_battery.png`,
            node_name: "single_battery",
            value: "Single Battery",
            blenderValue: "rad_rh-single-battery",
            blenderMatModelGeometry: "matg_2_rh-single-battery",
          },
          {
            id: 2,
            node_instanceID: 615,
            thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/nodes/rear-hub/double_battery.png`,
            node_name: "double_battery",
            value: "Double Battery ",
            blenderValue: "rad_rh-double-battery",
            blenderMatModelGeometry: "matg_2_rh-double-battery",
          },
        ],
      },
    ],
    [
      {
        id: 3,
        name: "Top Tube",
        reference: "toptube",
        default_option_id: 485,
        default_option_name: "no_top-tube", //top-tube2
        default_option_value: "None",
        model_options: [
          {
            id: 3,
            node_instanceID: 485,
            thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/nodes/mid-drive/no_top-tube.png`,
            node_name: "no_top-tube",
            value: "None",
            blenderValue: "rad_md-top-tube-none",
            blenderMatModelGeometry: "matg_1_md-top-tube-none",
          },
          {
            id: 1,
            node_instanceID: 501,
            thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/nodes/mid-drive/short_top-tube.png`,
            node_name: "short_top-tube",
            value: "Short",
            blenderValue: "rad_md-top-tube-short",
            blenderMatModelGeometry: "matg_1_md-top-tube-short",
          },
          {
            id: 2,
            node_instanceID: 517,
            thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/nodes/mid-drive/tall_top-tube.png`,
            node_name: "tall_top-tube",
            value: "Tall",
            blenderValue: "rad_md-top-tube-tall",
            blenderMatModelGeometry: "matg_1_md-top-tube-tall",
          },
        ],
      },
    ],
  ],
  extras: [
    {
      id: 2,
      reference: "mirrors",
      node_instanceID: 301,
      thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/nodes/accessories/mirrors.png`,
      default: false,
      value: "Mirrors",
    },
    {
      id: 1,
      reference: "mudguards",
      node_instanceID: 368,
      thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/nodes/accessories/mudguards.png`,
      default: false,
      value: "Mudguards",
    },
    {
      id: 3,
      reference: "daytime-leds",
      node_instanceID: 130,
      thumbnails: `${AWS_DOMAIN}bike_objects_v2/thumbnails/nodes/accessories/daytime-leds.png`,
      default: false,
      value: "Daytime LEDs",
    },
  ],
};

/* headphones_objects 3D MODEL */

export const HEADPHONE_PRODUCT = {
  id: 3,
  product_name: "Neurable Charmeleon",
  model_id: "d79b919972e243189d08a1eea0f5dc28",
  initial_ar_img: {
    android: "hp_white_r_201211_v1",
    ios: "hp_white_r_201211_v1",
    serverRouting: "3d_files/headphones/resting",
    local: true,
  },
  initial_qr_img: "some-qr.png",
  material_primary: "Material",
  istexture: true,
  colors: {
    initial_color_primary: "white",
    color_primary: [
      {
        id: 1,
        hex: "efeeeb",
        reference: "white",
        value: "White",
        ar_files: {
          android: "hp_white_r_201211_v1",
          ios: "hp_white_r_201211_v1",
          serverRouting: "3d_files/headphones/resting",
          local: true,
        },
        changes: [
          {
            channel: "albedo",
            material: "Material",
            value: {
              desktop: `${AWS_DOMAIN}headphones_objects/textures/main/white_color.jpg`,
              movil: `${AWS_DOMAIN}headphones_objects/textures/main/white_color.jpg`,
            },
          },
          {
            channel: "metalness",
            material: "Material",
            value: {
              desktop: `${AWS_DOMAIN}headphones_objects/textures/main/white_metallic.png`,
              movil: `${AWS_DOMAIN}headphones_objects/textures/main/white_metallic.png`,
            },
          },
          {
            channel: "roughness",
            material: "Material",
            value: {
              desktop: `${AWS_DOMAIN}headphones_objects/textures/main/white_roughness.png`,
              movil: `${AWS_DOMAIN}headphones_objects/textures/main/white_roughness.png`,
            },
          },
        ],
      },
      {
        id: 2,
        hex: "5b5c50",
        reference: "olive",
        value: "Olive",
        ar_files: {
          android: "hp_olive_r_201211_v1",
          ios: "hp_olive_r_201211_v1",
          serverRouting: "3d_files/headphones/resting",
          local: true,
        },
        changes: [
          {
            channel: "albedo",
            material: "Material",
            value: {
              desktop: `${AWS_DOMAIN}headphones_objects/textures/main/olive_color.jpg`,
              movil: `${AWS_DOMAIN}headphones_objects/textures/main/olive_color.jpg`,
            },
          },
          {
            channel: "metalness",
            material: "Material",
            value: {
              desktop: `${AWS_DOMAIN}headphones_objects/textures/main/charcoal-olive_metallic.png`,
              movil: `${AWS_DOMAIN}headphones_objects/textures/main/charcoal-olive_metallic.png`,
            },
          },
          {
            channel: "roughness",
            material: "Material",
            value: {
              desktop: `${AWS_DOMAIN}headphones_objects/textures/main/charcoal-olive_roughness.png`,
              movil: `${AWS_DOMAIN}headphones_objects/textures/main/charcoal-olive_roughness.png`,
            },
          },
        ],
      },
      {
        id: 3,
        hex: "3c3c44",
        reference: "charcoal",
        value: "Charcoal",
        ar_files: {
          android: "hp_charcoal_r_201211_v1",
          ios: "hp_charcoal_r_201211_v1",
          serverRouting: "3d_files/headphones/resting",
          local: true,
        },
        changes: [
          {
            channel: "albedo",
            material: "Material",
            value: {
              desktop: `${AWS_DOMAIN}headphones_objects/textures/main/charcoal_color.jpg`,
              movil: `${AWS_DOMAIN}headphones_objects/textures/main/charcoal_color.jpg`,
            },
          },
          {
            channel: "metalness",
            material: "Material",
            value: {
              desktop: `${AWS_DOMAIN}headphones_objects/textures/main/charcoal-olive_metallic.png`,
              movil: `${AWS_DOMAIN}headphones_objects/textures/main/charcoal-olive_metallic.png`,
            },
          },
          {
            channel: "roughness",
            material: "Material",
            value: {
              desktop: `${AWS_DOMAIN}headphones_objects/textures/main/charcoal-olive_roughness.png`,
              movil: `${AWS_DOMAIN}headphones_objects/textures/main/charcoal-olive_roughness.png`,
            },
          },
        ],
      },
    ],
  },
};

/* SHOE 3D MODEL */

export const SHOE_PRODUCT = {
  id: 2,
  product_name: "3D Footwear Light Blue",
  model_id: "dcb854d69e87404e9ee780d375d0cd57", // NEW: dcb854d69e87404e9ee780d375d0cd57 ||  OLD: f90c2ab14b274b12b7253920e68e3857
  initial_ar_img: {
    android: "shoe_lightBlue_af_210512_v3",
    ios: "shoe_lightBlue_ik_210120_v2",
    serverRouting: "3d_files/footwear",
    local: true,
  },
  material_primary: "UV1",
  material_secundary: "UV2",
  istexture: true,
  colors: {
    initial_color_primary: "white",
    color_primary: [
      {
        id: 1,
        hex: "a7ccca",
        materials_toChange: ["UV1", "UV2"],
        reference: "lightBlue",
        value: "Light Blue",
        thumbnail: `${AWS_DOMAIN}shoe_objects/thumbnails/min/lightBlue.png`,
        ar_files: {
          android: "shoe_lightBlue_af_210512_v3",
          ios: "shoe_lightBlue_ik_210120_v2",
          serverRouting: "3d_files/footwear",
          local: true,
        },
        changes: [
          {
            channel: "albedo",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/lightBlue_color_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/lightBlue_color_uv1.jpg`,
            },
          },
          {
            channel: "albedo",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/lightBlue_color_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/lightBlue_color_uv2.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/lightBlue_roughness_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/lightBlue_roughness_uv1.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/lightBlue_roughness_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/lightBlue_roughness_uv2.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/lightBlue_normal_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/lightBlue_normal_uv1.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/lightBlue_normal_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/lightBlue_normal_uv2.jpg`,
            },
          },
        ],
      },
      {
        id: 2,
        hex: "eb6a3f",
        materials_toChange: ["UV1", "UV2"],
        reference: "coral",
        value: "Coral",
        thumbnail: `${AWS_DOMAIN}shoe_objects/thumbnails/min/coral.png`,
        ar_files: {
          android: "shoe_coral_af_210512_v3",
          ios: "shoe_coral_ik_210120_v2",
          serverRouting: "3d_files/footwear",
          local: true,
        },
        changes: [
          {
            channel: "albedo",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/coral_color_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/coral_color_uv1.jpg`,
            },
          },
          {
            channel: "albedo",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/coral_color_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/coral_color_uv2.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/coral_roughness_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/coral_roughness_uv1.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/coral_roughness_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/coral_roughness_uv2.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/coral_normal_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/coral_normal_uv1.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/coral_normal_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/coral_normal_uv2.jpg`,
            },
          },
        ],
      },
      {
        id: 3,
        hex: "94c439",
        materials_toChange: ["UV1", "UV2"],
        reference: "lime",
        value: "Lime",
        thumbnail: `${AWS_DOMAIN}shoe_objects/thumbnails/min/lime.png`,
        ar_files: {
          android: "shoe_lime_af_210512_v3",
          ios: "shoe_lime_ik_210120_v2",
          serverRouting: "3d_files/footwear",
          local: true,
        },
        changes: [
          {
            channel: "albedo",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/lime_color_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/lime_color_uv1.jpg`,
            },
          },
          {
            channel: "albedo",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/lime_color_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/lime_color_uv2.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/lime_roughness_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/lime_roughness_uv1.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/lime_roughness_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/lime_roughness_uv2.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/lime_normal_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/lime_normal_uv1.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/lime_normal_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/lime_normal_uv2.jpg`,
            },
          },
        ],
      },
      {
        id: 4,
        hex: "1a293b",
        materials_toChange: ["UV1", "UV2"],
        reference: "blue",
        value: "Blue",
        thumbnail: `${AWS_DOMAIN}shoe_objects/thumbnails/min/blue.png`,
        ar_files: {
          android: "shoe_blue_af_210512_v3",
          ios: "shoe_blue_ik_210120_v2",
          serverRouting: "3d_files/footwear",
          local: true,
        },
        changes: [
          {
            channel: "albedo",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/blue_color_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/blue_color_uv1.jpg`,
            },
          },
          {
            channel: "albedo",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/blue_color_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/blue_color_uv2.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/blue_roughness_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/blue_roughness_uv1.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/blue_roughness_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/blue_roughness_uv2.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/blue_normal_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/blue_normal_uv1.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/blue_normal_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/blue_normal_uv2.jpg`,
            },
          },
        ],
      },
      {
        id: 5,
        hex: "ffffff",
        materials_toChange: ["UV1", "UV2"],
        reference: "white",
        value: "White",
        thumbnail: `${AWS_DOMAIN}shoe_objects/thumbnails/min/white.png`,
        ar_files: {
          android: "shoe_white_af_21512_v3",
          ios: "shoe_white_ik_210120_v2",
          serverRouting: "3d_files/footwear",
          local: true,
        },
        changes: [
          {
            channel: "albedo",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/white_color_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/white_color_uv1.jpg`,
            },
          },
          {
            channel: "albedo",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/white_color_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/white_color_uv2.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/white_roughness_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/white_roughness_uv1.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/white_roughness_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/white_roughness_uv2.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/white_normal_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/white_normal_uv1.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/white_normal_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/white_normal_uv2.jpg`,
            },
          },
        ],
      },
      {
        id: 6,
        hex: "66ccc3",
        materials_toChange: ["UV1", "UV2"],
        reference: "aqua",
        value: "Aqua",
        thumbnail: `${AWS_DOMAIN}shoe_objects/thumbnails/min/aqua.png`,
        ar_files: {
          android: "shoe_aqua_af_210512_v3",
          ios: "shoe_aqua_ik_210120_v2",
          serverRouting: "3d_files/footwear",
          local: true,
        },
        changes: [
          {
            channel: "albedo",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/aqua_color_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/aqua_color_uv1.jpg`,
            },
          },
          {
            channel: "albedo",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/aqua_color_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/aqua_color_uv2.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/aqua_roughness_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/aqua_roughness_uv1.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/aqua_roughness_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/aqua_roughness_uv2.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/aqua_normal_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/aqua_normal_uv1.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/aqua_normal_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/aqua_normal_uv2.jpg`,
            },
          },
        ],
      },
      {
        id: 7,
        hex: "8530b3",
        materials_toChange: ["UV1", "UV2"],
        reference: "purple",
        value: "Purple",
        thumbnail: `${AWS_DOMAIN}shoe_objects/thumbnails/min/purple.png`,
        ar_files: {
          android: "shoe_purple_af_210512_v3",
          ios: "shoe_purple_ik_210120_v2",
          serverRouting: "3d_files/footwear",
          local: true,
        },
        changes: [
          {
            channel: "albedo",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/purple_color_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/purple_color_uv1.jpg`,
            },
          },
          {
            channel: "albedo",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/purple_color_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/purple_color_uv2.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/purple_roughness_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/purple_roughness_uv1.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/purple_roughness_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/purple_roughness_uv2.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/purple_normal_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/purple_normal_uv1.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/aqua_normal_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/aqua_normal_uv2.jpg`,
            },
          },
        ],
      },
      {
        id: 8,
        hex: "e69840",
        materials_toChange: ["UV1", "UV2"],
        reference: "orange",
        value: "Orange",
        thumbnail: `${AWS_DOMAIN}shoe_objects/thumbnails/min/orange.png`,
        ar_files: {
          android: "shoe_orange_af_210512_v3",
          ios: "shoe_orange_ik_210120_v2",
          serverRouting: "3d_files/footwear",
          local: true,
        },
        changes: [
          {
            channel: "albedo",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/orange_color_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/orange_color_uv1.jpg`,
            },
          },
          {
            channel: "albedo",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/orange_color_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/orange_color_uv2.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/orange_roughness_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/orange_roughness_uv1.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/orange_roughness_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/orange_roughness_uv2.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/orange_normal_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/orange_normal_uv1.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/orange_normal_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/orange_normal_uv2.jpg`,
            },
          },
        ],
      },
      {
        id: 9,
        hex: "66ccc3",
        materials_toChange: ["UV1", "UV2"],
        reference: "darkBlue",
        value: "Dark Blue",
        thumbnail: `${AWS_DOMAIN}shoe_objects/thumbnails/min/darkBlue.png`,
        ar_files: {
          android: "shoe_darkBlue_af_210512_v3",
          ios: "shoe_darkBlue_ik_210120_v2",
          serverRouting: "3d_files/footwear",
          local: true,
        },
        changes: [
          {
            channel: "albedo",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/darkBlue_color_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/darkBlue_color_uv1.jpg`,
            },
          },
          {
            channel: "albedo",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/darkBlue_color_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/darkBlue_color_uv2.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/darkBlue_roughness_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/darkBlue_roughness_uv1.jpg`,
            },
          },
          {
            channel: "roughness",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/darkBlue_roughness_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/darkBlue_roughness_uv2.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV1",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/darkBlue_normal_uv1.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/darkBlue_normal_uv1.jpg`,
            },
          },
          {
            channel: "normal",
            material: "UV2",
            value: {
              desktop: `https://fresco-augmented-reality.com/shoe_objects/textures/main/2k/darkBlue_normal_uv2.jpg`,
              movil: `https://fresco-augmented-reality.com/shoe_objects/textures/main/1k/darkBlue_normal_uv2.jpg`,
            },
          },
        ],
      },
    ],
  },
};

export const SWANDOO_PRODUCT = {
  id: 5,
  product_name: "Swandoo Marie",
  model_id: "bf6ea157a6784438a857219159a911a7", // NEW: dcb854d69e87404e9ee780d375d0cd57 ||  OLD: f90c2ab14b274b12b7253920e68e3857
  initial_ar_img: {
    android: "sw_rot_test_15_wip_nm_210309",
    ios: "sw_rot_test_15_wip_nm_210309",
    serverRouting: "3d_files/swandoo/15-grades",
    local: true,
  },
  initial_ar_img_v2: {
    android: "sw_rot_triplicated_nm_210310",
    ios: "sw_rot_triplicated_nm_210310",
    serverRouting: "3d_files/swandoo/15-grades",
    local: true,
  },
};

export const MAMAROO_PRODUCT = {
  id: 6,
  product_name: "MamaRoo Sleep",
  model_id: "ac84616ba1184840bc1c1102686308f0",
  initial_ar_img: {
    android: "mamaRoo",
    ios: "mamaRoo",
    serverRouting: "3d_files/4moms/no_basket",
    local: true,
  },
  initial_ar_img_v2: {
    android: "mamaRoo",
    ios: "mamaRoo",
    serverRouting: "3d_files/4moms/basket",
    local: true,
  },
};

export const IROBOT_PRODUCT = {
  id: 7,
  product_name: "iRobot Demo",
  model_id: "d889f02ab86c42ab96abd3b96103d91f", // NEW: dcb854d69e87404e9ee780d375d0cd57 ||  OLD: f90c2ab14b274b12b7253920e68e3857
  initial_ar_img: {
    android: "iRobot_fv_210507_anim_1",
    ios: "iRobot_fv_210507_anim_1",
    serverRouting: "3d_files/irobot/simple",
    local: true,
  },
  initial_ar_img_v2: {
    android: "iRobot_fv_210512_anim_3_v2",
    ios: "iRobot_fv_210512_anim_3_v2",
    serverRouting: "3d_files/irobot/jumping",
    local: true,
  },
};

/* Bottle Product */

export const BOTTLE_PRODUCT = {
  id: 4,
  product_name: "Bk-X Gray",
  model_id: "06b6e4bd8bec41f5920e8e55ee889a1f",
  initial_ar_img: {
    android: "bk3_gray_4website_ik_200811_v1",
    ios: "bk3_gray_4website_ik_200811_v1",
    serverRouting: "3d_files/bk",
    local: true,
  },
  initial_qr_img: "some-qr.png",
  material_primary: "bk3_X_body_2",
  istexture: true,
  colors: {
    initial_color_primary: "Gray",
    color_primary: [
      {
        id: 1,
        hex: "717171",
        materials_toChange: ["bk3_X_body_2"],
        reference: "gray",
        value: "Gray",
        thumbnail: `none`,
        ar_files: {
          android: "bk3_gray_4website_ik_200811_v1",
          ios: "bk3_gray_4website_ik_200811_v1",
          serverRouting: "3d_files/bk",
          local: true,
        },
        changes: [
          {
            channel: "albedo",
            material: "bk3_X_body_2",
            value: {
              desktop: `${AWS_DOMAIN}bottle_objects/textures/main/2k_gray.png`,
              movil: `${AWS_DOMAIN}bottle_objects/textures/main/2k_gray.png`,
            },
          },
        ],
      },
      {
        id: 2,
        hex: "962c35",
        materials_toChange: ["bk3_X_body_2"],
        reference: "red",
        value: "Red",
        thumbnail: `none`,
        ar_files: {
          android: "bk3_red_ik_200811_v1",
          ios: "bk3_red_ik_200811_v1",
          serverRouting: "3d_files/bk",
          local: true,
        },
        changes: [
          {
            channel: "albedo",
            material: "bk3_X_body_2",
            value: {
              desktop: `${AWS_DOMAIN}bottle_objects/textures/main/2k_red.png`,
              movil: `${AWS_DOMAIN}bottle_objects/textures/main/2k_red.png`,
            },
          },
        ],
      },
      {
        id: 3,
        hex: "40593e",
        materials_toChange: ["bk3_X_body_2"],
        reference: "green",
        value: "Green",
        thumbnail: `none`,
        ar_files: {
          android: "bk3_green_ik_200811_v1",
          ios: "bk3_green_ik_200811_v1",
          serverRouting: "3d_files/bk",
          local: true,
        },
        changes: [
          {
            channel: "albedo",
            material: "bk3_X_body_2",
            value: {
              desktop: `${AWS_DOMAIN}bottle_objects/textures/main/2k_green.png`,
              movil: `${AWS_DOMAIN}bottle_objects/textures/main/2k_green.png`,
            },
          },
        ],
      },
      {
        id: 4,
        hex: "333333",
        materials_toChange: ["bk3_X_body_2"],
        reference: "charcoal",
        value: "Charcoal",
        thumbnail: `none`,
        ar_files: {
          android: "bk3_charcoal_ik_200811_v1",
          ios: "bk3_charcoal_ik_200811_v1",
          serverRouting: "3d_files/bk",
          local: true,
        },
        changes: [
          {
            channel: "albedo",
            material: "bk3_X_body_2",
            value: {
              desktop: `${AWS_DOMAIN}bottle_objects/textures/main/2k_charcoal.png`,
              movil: `${AWS_DOMAIN}bottle_objects/textures/main/2k_charcoal.png`,
            },
          },
        ],
      },
    ],
  },
};

/* SLIDERS INFO */

export const SLIDERS_3DMODEL_STEPS = [
  {
    id: 1,
    sliderImg: "step1.gif",
    description: "Create and optimize 3d model",
  },
  {
    id: 2,
    sliderImg: "step2.gif",
    description: "Photo real material applied to 3d model",
  },
  {
    id: 3,
    sliderImg: "step3.gif",
    description: "3d viewer on the web",
  },
  {
    id: 4,
    sliderImg: "step4.gif",
    description: "Augmented reality on mobile/tablet devices",
  },
  {
    id: 5,
    sliderImg: "step5.gif",
    description: "3d content hosting for your website",
  },
  {
    id: 6,
    sliderImg: "step6.gif",
    description: "Ongoing support of 3d model and web viewer",
  },
];
