import React, { useEffect } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom"; // BrowserRouter || HashRouter

//HELPERS
import { Site24x7Rum } from "./helpers/index";

//PAGES
import ImmersiveCommerce from "./pages/ImmersiveCommerce";
import FrescoSole from "./pages/FrescoSole";
import HouseplantLighter from "./pages/HouseplantLighter";
import FrescoEBike from "./pages/FrescoEBike";
import KeurigCafe from "./pages/KeurigCafe";
import Spokeshave from "./pages/Spokeshave";
import SwandooMarie from "./pages/SwandooMarie";
import CharmeleonLaunch from "./pages/CharmeleonLaunch";
import CharmeleonModConcepts from "./pages/CharmeleonModConcepts";
import Prototype from "./pages/Prototypes";
import Models from "./pages/Models";
import CharmeleonConfig from "./pages/CharmeleonConfig";
import NotFound from "./pages/NotFound";
import MamaRooPage from "./pages/MamaRooPage";
import Irobot from "./pages/Irobot";

import CreateQR from "./pages/CreateQR";

import BikeConfig from "./pages/BikeConfig";
import ShoeConfig from "./pages/ShoeConfig";
import BottleKeeper from "./pages/BottleKeeper";

//TEST
import OuterDemo from "./pages/OuterDemo";

//LANDING PAGES
import Reality from "./pages/Reality";

function App() {
  useEffect(() => {
    Site24x7Rum();
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/immersive-commerce" />
        </Route>

        {/* Immersive Commerce */}
        <Route exact path="/immersive-commerce" component={ImmersiveCommerce} />
        {/* <Route exact path="/immersive-commerce/state/:drivetrain/:bikemodel/:framecolor/:forkcolor/:decals/:accessories" component={ImmersiveCommerce} />  */}

        {/* FrescoSole */}
        <Route exact path="/Fresco_sole" component={FrescoSole} />

        {/* HouseplantLighter */}
        <Route exact path="/houseplant_lighter" component={HouseplantLighter} />

        {/* FrescoEBike */}
        <Route exact path="/e_bike" component={FrescoEBike} />

        {/* KeurigCafe */}
        <Route exact path="/Keurig_Cafe" component={KeurigCafe} />

        {/* Spokeshave */}
        <Route exact path="/Spokeshave" component={Spokeshave} />

        {/* SwandooMarie */}
        <Route exact path="/Swandoo_marie" component={SwandooMarie} />

        {/* Outer Demo */}
        <Route exact path="/outer_demo" component={OuterDemo} />

        {/* Irobot Demo */}
        <Route exact path="/iRobot_demo" component={Irobot} />

        {/* CharmeleonLaunch */}
        <Route exact path="/Charmeleon_launch" component={CharmeleonLaunch} />

        {/* CharmeleonModConcepts */}
        <Route
          exact
          path="/modConcepts/prototype/:product"
          component={Prototype}
        />
        <Route exact path="/modConcepts/models/:product" component={Models} />
        <Route exact path="/modConcepts" component={CharmeleonModConcepts} />

        {/* CharmeleonConfig */}
        <Route exact path="/Charmeleon_config" component={CharmeleonConfig} />

        {/* UTILS */}
        {/* <Route exact path="/createqr/:product" component={CreateQR} /> */}
        <Route exact path="/createqr" component={CreateQR} />

        {/* BikeConfig */}
        <Route exact path="/bikeconfigurator" component={BikeConfig} />
        {/* <Route exact path="/bikeconfigurator/state/:drivetrain/:bikemodel/:framecolor/:forkcolor/:decals/:accessories" component={BikeConfig} /> */}

        {/* ShoeConfig */}
        <Route exact path="/Shoe_config" component={ShoeConfig} />

        {/* ShoeConfig */}
        <Route exact path="/3d-ecommerce/BKX" component={BottleKeeper} />

        <Route exact path="/3d-ecommerce/BKX">
          <Redirect to="/BKX" />
        </Route>

        <Route exact path="/BKX" component={BottleKeeper} />

        {/* 4Moms */}

        {/* V1 */}
        <Route
          exact
          path="/4moms_site/4moms_demo_site.html"
          component={() => {
            window.location =
              "https://fresco-design.com.ar/4moms_site/4moms_demo_site.html";
          }}
        />

        {/* V2 */}
        <Route exact path="/4moms_site" component={MamaRooPage} />

        <Route exact path="/4moms_site/4moms_demo_site.html">
          <Redirect to="/4moms_site" />
        </Route>

        {/* Landing Page */}
        {/* <Route exact path="/reality/:product/:description/:price/:buyInfoInAr/:iosCallToActionTextInAr/:android3d/:ios3d" component={Reality} /> */}
        {/* <Route exact path="/reality/:product/:android3d/:ios3d" component={Reality} /> */}
        <Route
          exact
          path="/:modelPage/reality/:product/:ServerRouting/:androidAndIosFileName"
          component={Reality}
        />
        <Route
          exact
          path="/:modelPage/reality/:product/:ServerRouting/:androidFileName/:iosFileName"
          component={Reality}
        />
        {/* --- */}

        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
