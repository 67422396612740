import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';

//PAGE STYLES 
import './styles.css';

//COMPONENTS
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

import ShoeModelConfig from '../../components/ShoeModelConfig';

function ShoeConfig() {

    const refIframeContainerShoeConfig = useRef(null);

    return (<>
        <Helmet>
            <title>Shoe Configurator | Fresco Design</title>
            <meta name="description" content="3D Footwear Configurator" /> 
            {/* opengraph protocol */}
            <meta property="og:description" content="3D Footwear Configurator" /> 
            <meta property="og:title" content="Shoe Configurator | Fresco Design" />
            <meta property="og:url" content="https://fresco-augmented-reality.com/Shoe_config" />
            <link rel="canonical" href="https://fresco-augmented-reality.com/Shoe_config" />
            <link rel="alternate" href="https://www.fresco-design.com.ar/Shoe_config" hreflang="es-AR" />
            <link rel="alternate" href="https://www.fresco-design.com/Shoe_config" />
        </Helmet>

        <Navbar noShowOptions={true} />

        <div className="container-fluid container_3decommerce_iframe" ref={refIframeContainerShoeConfig}> 

            {/* DESKTOP */}
            <section className="shoe-title-iframe mt-4 mb-3">
                <div className="row">
                    <div className="col-12">
                        <h3>3D Footwear Configurator</h3>
                    </div>
                </div>  
            </section>
            {/* </DESKTOP> */}
            <ShoeModelConfig refIframeContainer={refIframeContainerShoeConfig} />  
        </div>

        <div className="container container_3decommerce">

            <section className="section_contact_3decommerce mt-5 mb-3">
                <div className="row">
                    <div className="col-12">
                       <p>                     
                            Fresco Immersive Commerce leverages AR to create online experiences for consumers to discover and explore your products.
                       </p>
                    </div> 
                    <div className="col-12">
                        <p>
                            <a href="https://www.fresco-design.com/contact">
                                Let’s discuss how Fresco can grow your eCommerce business
                                <i className="fa fa-angle-right fa-lg" aria-hidden="true"></i>
                            </a>
                        </p>
                    </div>
                </div>  
            </section>
             
        </div> 

        <Footer />
    </>)
}

export default ShoeConfig
