import React, { useRef } from 'react';
import './styles.css';

//HOOKS
import useSkecthfabViewer from '../../hooks/useSkecthfabViewer';
import ArButton from '../ArButton';

//IMAGES
import arIconIrobotSimple from '../../images/icons/irobot_simple_ar_icon_v3.png'; 
import arIconIrobotJumping from '../../images/icons/irobot_jumping_ar_icon_v3.png'; 

//DATA
import { IROBOT_PRODUCT } from '../../services/data';

export default function IrobotModel({ refViewerContainer }) {

    //IFRAME REFS
    const refIframeRow = useRef(null);
    const refIframeCol = useRef(null);

    const { viewerIframe, setFullScreen } =
        useSkecthfabViewer(IROBOT_PRODUCT.model_id,  
            { 
            productName: IROBOT_PRODUCT.product_name, 
            initial_ar_img: { },  
            refIframeContainer: refViewerContainer,
            refIframeRow: refIframeRow,
            refIframeCol: refIframeCol,
            iframeBorder: true,
            arbtn: false,
            viewerContainerClassList: ['container-fluid', 'container_3decommerce_iframe'],
            viewerRowClassList: ['section-3dmodel-grid'],
            viewerColClassList: ['swandoo-viewer-grid'],  
            //fullScreenElementID: 'swandoo-viewer-grid',
            },
            {
                ui_animations: 1,
            }
        );

    return (<>

        <section className="desktop-title-iframe mt-5">
            <div className="row">
                <div className="col-12">
                    <h3>iRobot Demo</h3>
                </div>
            </div>  
        </section>
            
        <section className="section-3dmodel-grid" ref={refIframeRow}>   

            <div className="swandoo-viewer-grid" id="swandoo-viewer-grid" ref={refIframeCol}>

                <div className="section-ar-buttons_ab-swandoo-marie"> 
                    <ArButton pageNameForQr='iRobot_demo' icon={arIconIrobotSimple} productName={`${IROBOT_PRODUCT.product_name}`} 
                    arFiles={IROBOT_PRODUCT.initial_ar_img} setFullScreen={setFullScreen} /> 
                    <ArButton pageNameForQr='iRobot_demo' icon={arIconIrobotJumping} productName={`${IROBOT_PRODUCT.product_name} jumping`} 
                    arFiles={IROBOT_PRODUCT.initial_ar_img_v2} setFullScreen={setFullScreen} /> 
                </div> 

                {viewerIframe}
            </div>  

        </section>

    </>) 
}