import React, { useEffect, useRef, useState } from "react";
import "./styles.css";

//PORTAL
import ReactDOM from "react-dom";

function Modal({ children, title, onClose }) {

  //REF
  const refModalSection = useRef(null);

  //STATES
  const [canBeClose, setCanBeClose] = useState(false);

  useEffect(() => {   

    let internalRefModalSection = refModalSection.current;

    internalRefModalSection.addEventListener("mouseleave", HandleMouseLeave, false);
    internalRefModalSection.addEventListener("mouseenter", HandleMouseEnter, false);
  
    return () => {
      internalRefModalSection.removeEventListener("mouseleave", HandleMouseLeave, false);
      internalRefModalSection.removeEventListener("mouseenter", HandleMouseEnter, false);
    }

  }, [onClose]);

  function HandleClick(){
    if(canBeClose){
      onClose();
    }
  }

  function HandleMouseLeave(){
    setCanBeClose(true);
  }

  function HandleMouseEnter(){
    setCanBeClose(false);
  }

  return (
    <div className="modal-background" onClick={() => HandleClick()}> 
      <div ref={refModalSection} className="modal-section">
        {title !== "" && (
          <div className="modal-top">
            <h3 className="title">
              <strong>{title}</strong>
            </h3>
          </div>
        )}
        <button className="btn-close" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>    
  );
}

export default function ModalPortal({ children, title, onClose }) {
  return ReactDOM.createPortal(
    <Modal title={title} onClose={onClose}>
      {children}
    </Modal>,
    document.getElementById("modal-root")
  );
}
