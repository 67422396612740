import React, { useRef, useEffect } from "react";
import "./styles.css";

//HOOKS
import useSkecthfabViewer from "../../hooks/useSkecthfabViewer";

//HELPERS
import { VisibilityEvent } from "../../helpers/index";

export default function Spokeshave({ refIframeContainer, height }) {
  //IFRAME REFS
  const refIframeRow = useRef(null);
  const refIframeCol = useRef(null);

  //CUSTOM HOOK
  const {
    viewerIframe,
    api,
    loadingViewer,
    iframeStart,
    setIframeStart,
    viewerSectionHook,
  } = useSkecthfabViewer("1333effd8b11421fb5de41bdd9a97ad2", {
    productName: "Spokeshave",
    description: "Spokeshave designed by Fresco Design",
    price: "$50",
    initial_ar_img: {
      android: "spoke_animated_ik_200804_v1",
      ios: "spoke_animated_ik_200804_v1",
      serverRouting: "3d_files/spokeshave",
      landingPage: "",
      local: true,
    },
    refIframeContainer: refIframeContainer,
    refIframeRow: refIframeRow,
    refIframeCol: refIframeCol,
    /* bgStartViewer: {
        desktop: `${AWS_DOMAIN}sposhave_objects/iframe_bg/spokeshave_desktop.png`,
        movil: `${AWS_DOMAIN}sposhave_objects/iframe_bg/spokeshave_mobile.png`,
      }, */
    buyInfoInAr: true,
    iosCallToActionTextInAr: "Add to cart",
    viewerContainerClassList: ["container", "container_3decommerce"],
    viewerRowClassList: ["row"],
    viewerColClassList: ["col-12"],
    iframeBorder: false,
  });

  function stopViewer() {
    api.stop(function () {
      console.log("Viewer stopped");
      setIframeStart(false);
    });
  }

  window.stopSpokeViewer = stopViewer;

  /* == Carousel Arrows == */
  useEffect(() => {
    const carouselControlPrev = document.querySelectorAll(
      ".viewerCarousel .carousel-control-prev"
    );
    const carouselControlNext = document.querySelectorAll(
      ".viewerCarousel .carousel-control-next"
    );
    if (iframeStart) {
      carouselControlPrev.forEach((prevArrow) => {
        prevArrow.addEventListener("click", stopViewer);
      });
      carouselControlNext.forEach((prevArrow) => {
        prevArrow.addEventListener("click", stopViewer);
      });
    }
    return () => {
      carouselControlPrev.forEach((prevArrow) => {
        prevArrow.removeEventListener("click", stopViewer);
      });
      carouselControlNext.forEach((prevArrow) => {
        prevArrow.removeEventListener("click", stopViewer);
      });
    };
  }, [iframeStart]);
  /* ==== */

  //IMPROVE PERFORMANCE
  useEffect(() => {
    if (!loadingViewer) {
      //Visibility Functionality

      let taskToDo = {
        isVisibility: () => {},
        isNotVisibility: () => {
          if (iframeStart) {
            api.stop(function () {
              setIframeStart(false);
            });
          }
        },
      };

      VisibilityEvent(taskToDo);

      //----
    }
  }, [api, loadingViewer, iframeStart, setIframeStart]);

  return (
    <>
      {/* <div className="row" ref={refIframeRow}>
        <div className="col-12" ref={refIframeCol}> */}
      {viewerIframe}
      {/* </div>
      </div> */}
    </>
  );
}
