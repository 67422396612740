import stringify  from 'safe-stable-stringify';
import SHA1 from 'crypto-js/sha1';
import UTF8 from 'crypto-js/enc-utf8';

/* AR FILES AUTOMATIC GENERATION APP */

export function Hash_dict(config) {
  return new Promise((resolve, reject) => {
      try {
        const string = UTF8.parse(stringify(config));
        resolve(SHA1(string).toString());
      } catch (error) {
        reject(error);
      }
  });
}

/* */

var rumMOKey='ff8f88a54e3a485268582d0574c16ea8';

export function Site24x7Rum(){

    if(window.performance && window.performance.timing && window.performance.navigation) {
        var site24x7_rum_beacon=document.createElement('script');
        site24x7_rum_beacon.async = true;
        site24x7_rum_beacon.setAttribute('src','//static.site24x7rum.com/beacon/site24x7rum-min.js?appKey='+rumMOKey);
        document.getElementsByTagName('head')[0].appendChild(site24x7_rum_beacon);
      }

};

export function SetBackgroundToClassName(className){

    var elements = document.getElementsByClassName(className);    
    var elementsArr = Array.from(elements);    

    elementsArr.forEach(array => {           
        array.style.background = array.getAttribute('data-bgcolor');
    }); 
}

export function CutTextWithDots(text, limit, isEllipsis){
  let ellipsis = '';

  if(text.length > limit){
    ellipsis = (isEllipsis) ? '...' : '';

    text = text.substring(0, limit) + `${ellipsis}` ;  
  }

    return text;
}

/* USESKECTHFAB HELPERS */ 

export function StartAndHidePlayButton(api, iframeStart, setIframeStart, isLoading, setLoadingEffect, refStartViewerBtn){

  return new Promise((resolve, reject) => {  

    try {
      if(!iframeStart){
        api.start(function() { 
            setIframeStart(true);  
            //refStartViewerBtn.current.style.display = 'none'; 
            
            /* */
            if(isLoading){
              setLoadingEffect(true);
              api.addEventListener('viewerready', function() {  
                setTimeout(() => {
                  resolve('Viewer is Ready'); 
                }, 700); 
              });  
            } else {
              resolve('Viewer is Ready');
            }
            
            /* */
        });      
  
        //refStartViewerBtn.current.style.display = 'none';
      } 
    } catch (error) {
      console.error('An error has ocurred', error);
        reject(error);
    }

  });

}

export function Platform(setIos, setAndroid, iframeStart, bgStartViewer, refStartViewerImg, setStartViewerImg){   
                      
    //IOS
    if ((navigator.platform.includes('iPad') || navigator.platform === 'iPhone') 
      || (navigator.platform.includes('Mac') && navigator.maxTouchPoints > 1) ){ 
        setIos(true);    
        if(!iframeStart && bgStartViewer !== undefined){
          if(bgStartViewer.movil){
            //refStartViewerImg.current.src = `${bgStartViewer.movil}`;
            setStartViewerImg(bgStartViewer.movil);
          } else{ //The image of the attribute that is not empty is placed by default (Desktop in this case)
              /* if(bgStartViewer.desktop){
                refStartViewerImg.current.src = `${bgStartViewer.desktop}`;
              }*/
              refStartViewerImg.current.style.display = 'none';
          }
        }  
    }
            
    //DESKTOP
    else if (navigator.platform.includes('Win') || navigator.platform.includes('Mac')){
        if(!iframeStart && bgStartViewer !== undefined){
          if(bgStartViewer.desktop){
            //refStartViewerImg.current.src = `${bgStartViewer.desktop}`;
            setStartViewerImg(bgStartViewer.desktop);
          } else { //The image of the attribute that is not empty is placed by default (Movil in this case)
            /* if(bgStartViewer.movil){
              refStartViewerImg.current.src = `${bgStartViewer.movil}`;
            } */
            refStartViewerImg.current.style.display = 'none';
          }
        }
    }               
        
    //ANDROID
    else {  
      setAndroid(true);
      if(!iframeStart && bgStartViewer !== undefined){
        if(bgStartViewer.movil){
          //refStartViewerImg.current.src = `${bgStartViewer.movil}`;
          setStartViewerImg(bgStartViewer.movil);
        } else { //The image of the attribute that is not empty is placed by default (Movil in this case)
          /* if(bgStartViewer.desktop){
            refStartViewerImg.current.src = `${bgStartViewer.desktop}`;
          } */
          refStartViewerImg.current.style.display = 'none';
        }
      }   
    } 

}

//-------------------- 

export function SetTexture(api, textureUid, sketchfabMaterialList, channel, materialToModify){

  return new Promise((resolve, reject) => {
        try {
          //console.log('textureUid >', textureUid);
          let materialUpdated;

          sketchfabMaterialList.forEach(material => {

            if(material.name === materialToModify){
              
                //************** CHANNELS
                switch (channel.toLowerCase()) { 
                  case 'albedo':
                    //console.log('Channel albedo');
                    material.channels.AlbedoPBR.enable = true;
                    material.channels.AlbedoPBR.texture.uid = textureUid;
                    break;

                  case 'diffuse':
                    //console.log('Channel DiffuseColor');
                    //material.channels.DiffusePBR.enable = true;
                    //material.channels.DiffusePBR.texture.uid = textureUid; 
                    material.channels.DiffuseColor.enable = true;
                    material.channels.DiffuseColor.texture.uid = textureUid;
                    break;  

                  case 'glossiness':
                    //console.log('Channel Glossiness');
                    material.channels.GlossinessPBR.enable = true;
                    material.channels.GlossinessPBR.texture.uid = textureUid;  
                    break;

                  case 'roughness':
                    //console.log('Channels Roughness');
                    material.channels.RoughnessPBR.enable = true;
                    material.channels.RoughnessPBR.texture.uid = textureUid;  
                    break;

                  case 'bumpmap':
                    //console.log('Channels BumpMap');  
                    material.channels.BumpMap.enable = true;
                    material.channels.BumpMap.texture.uid = textureUid;     
                    break;

                  case 'metalness':
                    //console.log('Channels MetalnessPBR');  
                    material.channels.MetalnessPBR.enable = true;
                    material.channels.MetalnessPBR.texture.uid = textureUid;     
                    break;

                  case 'normal':
                    //console.log('Channels NormalMap');  
                    material.channels.NormalMap.enable = true;
                    material.channels.NormalMap.texture.uid = textureUid;     
                    break;
                
                  default:
                    break;
                } 
                //************* ONLY ALBEDO CHANNEL
                //sketchfabMaterialList.channels.AlbedoPBR.texture.uid = textureUid;   
                materialUpdated = material;                         
            } 
   
          });

          resolve(materialUpdated);

      } catch (error) {
        console.error('The texture could not be applied:', error);
        reject(error);
      }
  });

}

//---

export function ChangeTexture(api, textureName, materialModel){   

  api.getTextureList(function(err, textures) {
    if(!err){
                                             
        var textureHosted = textures.find( 
          (texture) => texture.name === `${textureName}`
        );   

          api.getMaterialList(function(err, materials){
            const materialToChange = materials.find(
              (material) => material.name === materialModel
            );

            //**************************** 
            if(textureHosted === undefined){
              api.addTexture(`${textureName}`, function(err, textureUid) {
                if(!err){
                  materialToChange.channels.AlbedoPBR.texture.uid = textureUid;
                  api.setMaterial(materialToChange); 
                }
              });
            }else{
              var { uid } = textureHosted;
              materialToChange.channels.AlbedoPBR.texture.uid = uid;
              api.setMaterial(materialToChange);
            }
            // **************************** 
          }); 
    }
  });
  
};

/* --- Functions to transform color from Hexadecimal to RGB Linear --- */

const GAMMA = 2.4;

function srgbToLinear1(c) {
    var v = 0.0;
    if (c < 0.04045) {
        if (c >= 0.0) v = c * (1.0 / 12.92);
    } else {
        v = Math.pow((c + 0.055) * (1.0 / 1.055), GAMMA);
    }
    return v;
}

function srgbToLinear(c, out) {
    var col = out || new Array(c.length);

    if (c.length > 2 && c.length < 5) {
        col[0] = srgbToLinear1(c[0]);
        col[1] = srgbToLinear1(c[1]);
        col[2] = srgbToLinear1(c[2]);
        if (col.length > 3 && c.length > 3) col[3] = c[3];
    } else {
        throw new Error('Invalid color. Expected 3 or 4 components, but got ' + c.length);
    }
    return col;
}

function hexToRgb(hexColor) {
    var m = hexColor.match(/^#([0-9a-f]{6})$/i);
    if (m) {
        return [
            parseInt(m[1].substr(0, 2), 16) / 255,
            parseInt(m[1].substr(2, 2), 16) / 255,
            parseInt(m[1].substr(4, 2), 16) / 255
        ];
    } else {
        throw new Error('Invalid color: ' + hexColor);
    }
}

//////////////////////////////////
// End Color Math Code
//////////////////////////////////

function setColor(api, hexColor, materialToChange) {

  var parsedColor = srgbToLinear(hexToRgb(hexColor));
  materialToChange.channels.AlbedoPBR.enable = true;
  materialToChange.channels.AlbedoPBR.color = parsedColor;

  api.setMaterial(materialToChange);
}

/* ------ */

export function ChangeColor(api, colorModel, materialModel){

  api.getMaterialList(function(err, materials){

    const materialToChange = materials.find(
      (material) => material.name === materialModel
    );

    /*bodyBattle.channels.AlbedoPBR.texture = false;   //(When the model use Texture)

    materialToChange.channels.AlbedoPBR.color = [Math.random(), Math.random(), Math.random(), Math.random()];
    api.setMaterial(materialToChange); */

    setColor(api, colorModel, materialToChange);

  }); 

}

/* Visibility EVENT */

export function VisibilityEvent(taskToDo, callback) {

  // Set the name of the hidden property and the change event for visibility
  let hidden, visibilityChange;
  if (typeof document.hidden !== "undefined") {  // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  }

  // If the page is hidden, pause the video;
  // if the page is shown, play the video
  function handleVisibilityChange() {
    if (document[hidden]) {
      //videoElement.pause();
      taskToDo.isNotVisibility();
    } else {
      //videoElement.play();
      taskToDo.isVisibility();
    }
  }

  // Warn if the browser doesn't support addEventListener or the Page Visibility API
  if (typeof document.addEventListener === "undefined" || hidden === undefined) {
    console.log("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
  } else {
    // Handle page visibility change
    document.addEventListener(visibilityChange, handleVisibilityChange, false);

  }

}

