import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';

//PAGE STYLES 
import './styles.css';

//COMPONENTS
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

import BikeModelConfig from '../../components/BikeModelConfig';

function BikeConfig() {

    const refIframeContainerBikeConfig = useRef(null);

    return (<>
        <Helmet>
            <title>Bike Configurator | Fresco Design</title>
            <meta name="description" content="Fresco E-Bike configurator" />
            {/* opengraph protocol */}
            <meta property="og:description" content="Fresco E-Bike configurator" /> 
            <meta property="og:title" content="Bike Configurator | Fresco Design" />
            <meta property="og:url" content="https://fresco-augmented-reality.com/bikeconfigurator" />
            <link rel="canonical" href="https://fresco-augmented-reality.com/bikeconfigurator" />
            <link rel="alternate" href="https://www.fresco-design.com.ar/bikeconfigurator" hreflang="es-AR" />
            <link rel="alternate" href="https://www.fresco-design.com/bikeconfigurator" />
            {/* opengraph protocol */}
        </Helmet>

        <Navbar noShowOptions={true} />

        <div className="container-fluid container_3decommerce_iframe" ref={refIframeContainerBikeConfig}> 

            {/* DESKTOP */}
            <section className="desktop-title-iframe mt-5">
                <div className="row">
                    <div className="col-12">
                        <h3>Product customization, simplified</h3>
                    </div>
                </div>  
            </section>
            {/* </DESKTOP> */}
            <BikeModelConfig refIframeContainer={refIframeContainerBikeConfig} pageName="bikeconfigurator" />  
        </div>

        <div className="container container_3decommerce">

            <section className="section_contact_3decommerce mt-5 mb-3">
                <div className="row">
                    <div className="col-12">
                       <p>                     
                            Fresco Immersive Commerce leverages AR to create online experiences for consumers to discover and explore your products.
                       </p>
                    </div> 
                    <div className="col-12">
                        <p>
                            <a href="https://www.fresco-design.com/contact">
                                Let’s discuss how Fresco can grow your eCommerce business
                                <i className="fa fa-angle-right fa-lg" aria-hidden="true"></i>
                            </a>
                        </p>
                    </div>
                </div>  
            </section>
             
        </div> 

        <Footer />
    </>)
}

export default BikeConfig
