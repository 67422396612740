import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

//PAGE STYLES 
import './styles.css';

//DATA
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

//COMPONENTS
import Navbar from '../../components/Navbar'; 
import Footer from '../../components/Footer';
import Login from '../../components/Login';

//WIDGET
import FrescoWidget from "../../components/FrescoWidget";

export default function CharmeleonModConcepts(){ 

    const [user, setUser] = useState(sessionStorage.getItem('access'));

   return(<>
    
    {!user ? <>
    <Helmet>
        <title>Login | Fresco Design</title>
    </Helmet> 
    <Login setUser={setUser} />
    </> : <> 

    <Helmet>
        <title>Charmeleon ModConcepts | Fresco Design</title>
        <meta name="description" content="Charmeleon modConcepts" />
        {/* opengraph protocol */}
        <meta property="og:description" content="Charmeleon modConcepts" /> 
        <meta property="og:title" content="Charmeleon ModConcepts | Fresco Design" />
        <meta property="og:url" content="https://fresco-augmented-reality.com/modConcepts" />
        <link rel="canonical" href="https://fresco-augmented-reality.com/modConcepts" />
        <link rel="alternate" href="https://www.fresco-design.com.ar/modConcepts" hreflang="es-AR" />
        <link rel="alternate" href="https://www.fresco-design.com/modConcepts" />
    </Helmet> 
    
    <Navbar noShowOptions={true} />  

    <div className="container-fluid container_3decommerce_iframe"> 
        <section className="section_title_3dmodel">
            <div className="row">
                <div className="col">
                    <h3 style={{textAlign: 'center'}} className="mt-lg-2">Neurable Charmeleon</h3>
                </div>
            </div>
        </section>   
    </div>    

    <div className="container-fluid container_3decommerce_iframe"> 
        <section className="desktop-title-iframe"> 
            <div className="row">
                <div className="col-12">
                    <h3>Speckled White Resting</h3>
                </div>
            </div>  
        </section>
    </div>

    <div className="container-fluid container_3decommerce_iframe"> 
        <FrescoWidget elementID="hp-white-restingmodel" frescoKey="dd0aea5ec9854024aef165afb2133a5b" />
    </div>

    <div className="container-fluid container_3decommerce_iframe"> 
        <section className="desktop-title-iframe"> 
            <div className="row">
                <div className="col-12">
                    <h3>Olive Resting</h3>
                </div>
            </div>  
        </section>
    </div>

    <div className="container-fluid container_3decommerce_iframe"> 
        <FrescoWidget elementID="hp-olive-restingmodel" frescoKey="86eef893487e4f0a83aad3f3a7175d44" />
    </div>

    <div className="container-fluid container_3decommerce_iframe"> 
        <section className="desktop-title-iframe"> 
            <div className="row">
                <div className="col-12">
                    <h3>Charcoal Resting</h3>
                </div>
            </div>  
        </section>
    </div>

    <div className="container-fluid container_3decommerce_iframe"> 
        <FrescoWidget elementID="hp-charcoal-restingmodel" frescoKey="6814aa0df4f1482790753239c88318f9" />
    </div>

    <div className="container-fluid container_3decommerce_iframe"> 
        <section className="desktop-title-iframe"> 
            <div className="row">
                <div className="col-12">
                    <h3>Speckled White</h3>
                </div>
            </div>  
        </section>
    </div>

    <div className="container-fluid container_3decommerce_iframe"> 
        <FrescoWidget elementID="hp-white-model" frescoKey="4da4dfc15c6245ec913f4300f8744371" />
    </div>

    <div className="container-fluid container_3decommerce_iframe"> 
        <section className="section_title_3dmodel"> {/* desktop-title-iframe mt-4 */}
            <div className="row">
                <div className="col">
                    <h3 style={{fontSize:'28px', color: 'var(--green-dark)'}}>Spark AR</h3>
                    <article className="section_links">
                        <div className="link">
                            <a href="https://www.facebook.com/fbcameraeffects/testit/3454327104602910/ZDkxYjI3ZmU2OWE4OWRiNTUyODNlNTFmZTI3YzkwYmU=/" target="_blank" rel="noopener noreferrer">
                            <FacebookIcon fontSize="large" /> </a>
                            <a href="https://www.instagram.com/ar/400164737851871/?ch=N2I4YWI5ZmJlNDc1NzY3MDJlNGM4MjdmN2Q2YTA4NDM%3D" target="_blank" rel="noopener noreferrer">
                            <InstagramIcon fontSize="large"  /></a>
                        </div>
                    </article>
                </div>
            </div>
        </section>   
    </div>

    <div className="container-fluid container_3decommerce_iframe"> 
        <section className="desktop-title-iframe">
            <div className="row">
                <div className="col-12">
                    <h3>Olive</h3>  
                </div>
            </div>  
        </section>
    </div>    

    <div className="container-fluid container_3decommerce_iframe"> 
        <FrescoWidget elementID="hp-olive-model" frescoKey="800a7362ab9e4def9d54eaf90aaefac9" />
    </div>

    <div className="container-fluid container_3decommerce_iframe"> 
        <section className="section_title_3dmodel"> 
            <div className="row">
                <div className="col">
                    <h3 style={{fontSize:'28px', color: 'var(--green-dark)'}}>Spark AR</h3>
                    <article className="section_links">
                        <div className="link">
                            <a href="https://www.facebook.com/fbcameraeffects/testit/237377041135392/NWU3MzVjOGZkNTc3YmQ3MzI1NzlhNjBmMjliZDM5ZDk=/" target="_blank" rel="noopener noreferrer">
                            <FacebookIcon fontSize="large" /> </a>
                            <a href="https://www.instagram.com/ar/441781243485168/?ch=ZWRhMjUwYTMxNDJlMGFhMTBhYTBkMmUzMzY0MzY4N2Q%3D" target="_blank" rel="noopener noreferrer">
                            <InstagramIcon fontSize="large"  /></a>
                        </div>
                    </article>
                </div>
            </div>
        </section>   
    </div>

    <div className="container-fluid container_3decommerce_iframe"> 
        <section className="desktop-title-iframe">
            <div className="row">
                <div className="col-12">
                    <h3>Charcoal</h3>  
                </div>
            </div>  
        </section>
    </div>    

    <div className="container-fluid container_3decommerce_iframe"> 
        <FrescoWidget elementID="hp-charcoal-model" frescoKey="13b904b7b53c49a89335b87e67d35563" />
    </div>

    <div className="container-fluid container_3decommerce_iframe"> 
        <section className="section_title_3dmodel"> 
            <div className="row">
                <div className="col">
                    <h3 style={{fontSize:'28px', color: 'var(--green-dark)'}}>Spark AR</h3>
                    <article className="section_links">
                        <div className="link">
                            <a href="https://www.facebook.com/fbcameraeffects/testit/143012673905209/ZTYzZmJmYTBhM2RlZGY2ZDIwMTQ1Yzg0MmM0MTM0OTA=/" target="_blank" rel="noopener noreferrer">
                            <FacebookIcon fontSize="large" /> </a>
                            <a href="https://www.instagram.com/ar/408943950519275/?ch=NWM2MTg2YWM1NTQ0YTYwYjIyOTExNmU4ZjA3YmZhMzg%3D" target="_blank" rel="noopener noreferrer">
                            <InstagramIcon fontSize="large" /></a>
                        </div>
                        {/* <div className="link">
                           <a href="https://www.instagram.com/ar/1803439189811276/?ch=YjIzZmFiMDA5ODJlNzM5ODljZDIwOGM0YWZmODQ4MWQ%3D">
                            Instagram</a>
                        </div> */}
                    </article>
                </div>
            </div>
        </section>   
    </div>

    <div className="container container_3decommerce"> 

        <section className="section_contact_3decommerce mt-5 mb-3">
            <div className="row">
                <div className="col-12">
                <p>                     
                    Fresco Immersive Commerce leverages AR to create online experiences for consumers to discover and explore your products.
                </p>
                </div> 
                <div className="col-12">
                    <p>
                        <a href="https://www.fresco-design.com/contact">
                            Let’s discuss how Fresco can grow your eCommerce business
                            <i className="fa fa-angle-right fa-lg" aria-hidden="true"></i>
                        </a>
                    </p>
                </div>
            </div>  
        </section>
        
    </div> 

    <Footer /> </>}
         
   </>)

}

