import React, { useEffect, useState } from 'react'; /* THIS IS A NEURABLE unknown models page  */
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

//Styles
import './styles.css';

//DATA
import { AWS_DOMAIN } from '../../services/data';

import FrescoIcon from '../../images/icons/fresco_icon_transparent.png';

export default function Models(){  

    //STATES 
    const [productName, setProductName] = useState('');

    //URL PARAMS
    const { product } = useParams();

    useEffect(function(){ 

        try {
            if(product !== 'none'){
                //setProductName(atob(product));
                setProductName(decodeURI(product));
                window.location = `https://neurable.com`;  
            }
        } catch (error) {
            window.location = `${AWS_DOMAIN}modConcepts`;   
        } 

         console.log('Info Block:', {
            productName:  productName,
        }); 

    },[product, productName]);  

    return (<>

    <Helmet>
        <title>Fresco Design | Models</title>
    </Helmet> 
        
        <main className="models_page">
            <div className="models_grid">
                <figure>
                    <img src={FrescoIcon} alt="Fresco Desing Icon"/>
                </figure>
                <div className="models_text">
                    <h1>Model number {productName}</h1>       
                </div>
                <div className="models_button">
                    <Link className="btn_fresco_rounded_outline" to="/" >Back Home</Link>
                </div>
            </div>
        </main>

    </>)
}