import React, { useEffect, useRef, useState, useCallback }  from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

//Styles
import './styles.css';
//import '../BottleKeeper/styles.css';
//import './animations.css';

//IMAGES
import FourMomsLogo from '../../images/content/4moms/icons/4moms-logo.png';
import BabyListLogo from '../../images/content/4moms/icons/babylist-logo.png';
import FullScreenIcon from '../../images/icons/fullscreen_icon.png';

//SLIDERS
import MamaRooAssetOne from '../../images/content/4moms/asset_1.png';
import MamaRooScene02Af from '../../images/content/4moms/scene002_720_af_200903.png';
import MamaRooScene02CropAf from '../../images/content/4moms/scene002_720_crop_af_200903.png';
import MamaRooScene06Af from '../../images/content/4moms/scene006_720_af_200903.png';
import MamaRooScene06CropAf from '../../images/content/4moms/scene006_720_crop_af_200903.png';
import MamaRooScene07Af from '../../images/content/4moms/scene007_720_af_200903.png';
import MamaRooScene07CropAf from '../../images/content/4moms/scene007_720_crop_af_200903.png';

//2K (FOR FULLSCREEN)
import MamaRooScene02Af2K from '../../images/content/4moms/fullscreen/scene002_2k_af_200903.png';
import MamaRooScene02CropAf2K from '../../images/content/4moms/fullscreen/scene002_2k_crop_af_200903.png';
import MamaRooScene06Af2K from '../../images/content/4moms/fullscreen/scene006_2k_af_200903.png';
import MamaRooScene06CropAf2K from '../../images/content/4moms/fullscreen/scene006_2k_crop_af_200903.png';
import MamaRooScene07Af2K from '../../images/content/4moms/fullscreen/scene007_2k_af_200903.png';
import MamaRooScene07CropAf2K from '../../images/content/4moms/fullscreen/scene007_shot01_16-9_2k_crop_af_200903.png';

//HOOK
import useSketchfabViewer from '../../hooks/useSkecthfabViewer';

//DATA
import { MAMAROO_PRODUCT } from '../../services/data';

//HELPERS
//import { StartAndHidePlayButton } from '../../helpers';

function MamaRooPage() {

    //States
    const [slideIndex, setSlideIndex] = useState(1);
    const [startXValue, setStartXValue] = useState(null);
    //const [endXValue, setEndXValue] = useState(null);
    //const [range, setRange] = useState(null);

    //CONFIG
    const [mamaRooHaveBasket, setMamaRooHaveBasket] = useState(false);

    //FEATURES
    const [modalFullScreenImg, setModalFullScreenImg] = useState(MamaRooAssetOne);
    const [isImageFullScreen, setIsImageFullScreen] = useState(false);

    //REF
    const refIframeContainer = useRef(null); 
    const refIframeRow = useRef(null);
    const refIframeCol = useRef(null);

    const refPriceTag = useRef(null);

    const basketID = 65;

    //LOAD SCRIPT
    useEffect(() => {
        const linkStyle = document.createElement('link');
        linkStyle.setAttribute('href', 'https://stackpath.bootstrapcdn.com/bootstrap/4.5.1/css/bootstrap.min.css');
        linkStyle.rel = 'stylesheet';
        linkStyle.integrity = 'sha384-VCmXjywReHh4PwowAiWNagnWcLhlEJLA5buUprzK8rxFgeH0kww/aWY76TfkUoSX';
        linkStyle.crossOrigin = 'anonymous';
        //document.head.appendChild(linkStyle) //body.appendChild(s);
    }, []); 

    const { viewerIframe, api, loadingViewer, setArFiles } =
        useSketchfabViewer(MAMAROO_PRODUCT.model_id,  
            { 
            pageNameForQr: '4moms_site',
            productName: MAMAROO_PRODUCT.product_name, 
            initial_ar_img: MAMAROO_PRODUCT.initial_ar_img,  
            refIframeContainer: refIframeContainer,
            refIframeRow: refIframeRow,
            refIframeCol: refIframeCol,
            iframeBorder: false,
            //arbtn: false,
            viewerContainerClassList: ['container', 'container_3decommerce_iframe'],
            viewerRowClassList: ['row'],
            viewerColClassList: ['col-md-7', 'product-section'],   
            //fullScreenElementID: 'swandoo-viewer-grid',
            },
            {
                autostart: 1,
                ui_animations: 0,
            }
        );

        /* */

        const showSlides = useCallback((n) => {
            let i;
            let slides = document.getElementsByClassName("big_slides");
            let dots = document.getElementsByClassName("demo");
            //var captionText = document.getElementById("caption");

            let slideIndexInMemory = slideIndex;

            if (n > slides.length) {
                //slideIndex = 1
                setSlideIndex(1);
                slideIndexInMemory = 1;
            }
            if (n < 1) {
                //slideIndex = slides.length
                setSlideIndex(slides.length);
                slideIndexInMemory = slides.length;
            }
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" active", "");
            }
            slides[slideIndexInMemory-1].style.display = "flex";
            dots[slideIndexInMemory-1].className += "active";
            //captionText.innerHTML = dots[slideIndex-1].alt;
        }, [slideIndex]);

        useEffect(() => {
            showSlides(slideIndex);
        }, [showSlides, slideIndex]);

        useEffect(() => {
            
            switch (slideIndex) {
                case 1:
                    setModalFullScreenImg(null);
                    break;
                case 2:
                    setModalFullScreenImg(MamaRooScene02CropAf2K);
                    break;
                case 3:
                    setModalFullScreenImg(MamaRooScene02Af2K);
                break;
                case 4:
                    setModalFullScreenImg(MamaRooScene06CropAf2K);
                break;
                case 5:
                    setModalFullScreenImg(MamaRooScene06Af2K);
                break;
                case 6:
                    setModalFullScreenImg(MamaRooScene07CropAf2K);
                break;
                case 7:
                    setModalFullScreenImg(MamaRooScene07Af2K);
                break;
            
                default:
                    break;
            }

        }, [slideIndex]);

        useEffect(() => {
            if(!loadingViewer){
                api.hide(basketID);
            }
        }, [loadingViewer, api]);
        
        function plusSlides(n) {
            showSlides(slideIndex + n);  //slideIndex += n
            setSlideIndex(slideIndex + n);
        }

        function currentSlide(n) {
            showSlides(n);  //slideIndex = n
            setSlideIndex(n)
        }

        /* */
        //var varStartX;
        //var varEndX;
        //var range;

        function startX(event){
            //varStartX = event.changedTouches[0].clientX;

            setStartXValue(event.changedTouches[0].clientX);
        }
 
        function endX(event){
            //varEndX = event.changedTouches[0].clientX;

            //setEndXValue(event.changedTouches[0].clientX);
            let startXMemory = startXValue;
            let endXMemory = event.changedTouches[0].clientX;

            //setRange(Math.abs(startXMemory - endXMemory));
            let rangeMemory = Math.abs(startXMemory - endXMemory);   //varStartX-varEndX
            if(startXMemory < endXMemory && rangeMemory > 100){
                console.log(rangeMemory);
                plusSlides(-1);
            }
            else if (startXMemory > endXMemory && rangeMemory > 100){
                console.log(rangeMemory);
                plusSlides(1);
            }
        }

        /* CONFIGURATOR */

        function showAndHideGeometry(e, node) {

            if (e.target.checked){
                api.show(basketID);
                refPriceTag.current.innerText = '$379.99';

                setArFiles(MAMAROO_PRODUCT.initial_ar_img_v2); 
            } else {
                api.hide(basketID);
                refPriceTag.current.innerText = '$349.99';

                setArFiles(MAMAROO_PRODUCT.initial_ar_img); 
            }
        }

        /* */

        function ShowOrHideImageFullScreen(){ 
            if(isImageFullScreen){
                setIsImageFullScreen(false);
            } else {
                setIsImageFullScreen(true);
            }
        } 
        
    return (
        <>

        <Helmet>
            <title>4Moms | Fresco Design</title>
            <meta name="description" content="MamaRoo sleep bassinet model" /> 
            {/* opengraph protocol */}
            <meta property="og:description" content="MamaRoo sleep bassinet model" /> 
            <meta property="og:title" content="4Moms | Fresco Design" />
            <meta property="og:url" content="https://fresco-augmented-reality.com/4moms_site" />
            <link rel="canonical" href="https://fresco-augmented-reality.com/4moms_site" />
            <link rel="alternate" href="https://www.fresco-design.com.ar/4moms_site" hreflang="es-AR" />
            <link rel="alternate" href="https://www.fresco-design.com/4moms_site" />
        </Helmet>

            {isImageFullScreen && <div id="myModal-mamaroo" className="mamaroo-modal" onClick={() => { setIsImageFullScreen(false); }}>
                <img className="modal-content" src={modalFullScreenImg} alt="" /> 
                <div id="caption"></div>
            </div>}  

            <div className="fondo" id="fondo"></div>

            <div className="fs-slider_v2_5 fs-desktop fs-prepended-detail fs-wrapper">
                <div id="fs-timeline-detail-286161" className="fs-timeline-detail fs-hidden"></div>
            </div>

            <div className="layout-wrapper">

                {/* HEADER */}

                <header className="mamaroo-header">            
                    <div className="container"> 
                        <div className="row">  
                            <div className="col-12 mamaroo-header-col">
                                <Link className="" to="/">
                                    <img src={FourMomsLogo} alt="" />
                                </Link>  
                            </div>                           
                        </div>
                    </div>   
                </header>

                {/* MAIN */}

                <main>

                    <div ref={refIframeContainer} className="container iframe-grid-container-mamaRoo"> {/* id="iframe-grid-container-mamaRoo" */}

                        <div ref={refIframeRow} className="row" id="iframe-grid-background-mamaRoo">

                            <div ref={refIframeCol} className="col-md-7 product-section" id="iframe-grid-section">                                                              

                                {/* SLIDER LIST */}

                                <div className="mamaRooSlider">

                                    <div className="container_big">                          

                                        {/* <div className="slide-namber-mamaroo">
                                            {slideIndex} / 7
                                        </div> */}

                                        {(slideIndex !== 1) && <> 
                                            {/* <div className="arrows-mamaroo">
                                                <a className="prev" onClick={() => { plusSlides(-1); }} id="arrows">❮</a>
                                                <a className="next" onClick={() => { plusSlides(1); }} id="arrows">❯</a>
                                            </div>  */}

                                            <div className="iframe-buttons-ui-section_mamaroo">
                                                <button className="fullscreen-button" onClick={() => { ShowOrHideImageFullScreen(); }}>
                                                    <img src={FullScreenIcon} alt="Fullscreen icon" />
                                                </button>
                                            </div>

                                        </>}

                                        <div className="big_slides" style={{display: 'none'}}>

                                            {(slideIndex === 1) && <>
                                                <span className="prev prev-arrow-on-viewer" onClick={() => { plusSlides(-1); }} id="arrows">❮</span>
                                                <span className="next next-arrow-on-viewer" onClick={() => { plusSlides(1); }} id="arrows">❯</span>
                                            </>}                                       

                                            {viewerIframe}                                            
                                            
                                        </div>
                                            
                                        <div className="big_slides slide-image" style={{display: 'none'}}>
                                            <span className="prev prev-arrow-on-image" onClick={() => { plusSlides(-1); }} id="arrows">❮</span>
                                            <span className="next next-arrow-on-image" onClick={() => { plusSlides(1); }} id="arrows">❯</span>
                                            <img alt="" className="bigImgs" id="bigIms2" src={MamaRooScene02CropAf} onTouchStart={(event) => { startX(event); }}
                                            onTouchEnd={(event) => { endX(event); }} style={{width: '100%'}} /> 
                                        </div> 
                                            
                                        <div className="big_slides slide-image" style={{display: 'none'}}>
                                            <span className="prev prev-arrow-on-image" onClick={() => { plusSlides(-1); }} id="arrows">❮</span>
                                            <span className="next next-arrow-on-image" onClick={() => { plusSlides(1); }} id="arrows">❯</span>
                                            <img alt="" className="bigImgs" id="bigIms3" src={MamaRooScene02Af} onTouchStart={(event) => { startX(event); }} onTouchEnd={(event) => { endX(event); }} style={{width: '100%'}} />
                                        </div>
                                            
                                        <div className="big_slides slide-image" style={{display: 'none'}}>
                                            <span className="prev prev-arrow-on-image" onClick={() => { plusSlides(-1); }} id="arrows">❮</span>
                                            <span className="next next-arrow-on-image" onClick={() => { plusSlides(1); }} id="arrows">❯</span>
                                            <img alt="" className="bigImgs" id="bigIms4" src={MamaRooScene06CropAf} onTouchStart={(event) => { startX(event); }} onTouchEnd={(event) => { endX(event); }} style={{width: '100%'}} />                              
                                        </div>
                                            
                                        <div className="big_slides slide-image" style={{display: 'none'}}>
                                            <span className="prev prev-arrow-on-image" onClick={() => { plusSlides(-1); }} id="arrows">❮</span>
                                            <span className="next next-arrow-on-image" onClick={() => { plusSlides(1); }} id="arrows">❯</span>
                                            <img alt="" className="bigImgs" id="bigIms5" src={MamaRooScene06Af} onTouchStart={(event) => { startX(event); }} onTouchEnd={(event) => { endX(event); }} style={{width: '100%'}} />
                                        </div>
                                                    
                                        <div className="big_slides slide-image" style={{display: 'none'}}>
                                            <span className="prev prev-arrow-on-image" onClick={() => { plusSlides(-1); }} id="arrows">❮</span>
                                            <span className="next next-arrow-on-image" onClick={() => { plusSlides(1); }} id="arrows">❯</span>
                                            <img alt="" className="bigImgs" id="bigIms6" src={MamaRooScene07CropAf} onTouchStart={(event) => { startX(event); }} onTouchEnd={(event) => { endX(event); }} style={{width: '100%'}} />
                                        </div>
                                                
                                        <div className="big_slides slide-image" style={{display: 'none'}}>
                                            <span className="prev prev-arrow-on-image" onClick={() => { plusSlides(-1); }} id="arrows">❮</span>
                                            <span className="next next-arrow-on-image" onClick={() => { plusSlides(1); }} id="arrows">❯</span>
                                            <img alt="" className="bigImgs" id="bigIms7" src={MamaRooScene07Af} onTouchStart={(event) => { startX(event); }} onTouchEnd={(event) => { endX(event); }} style={{width: '100%'}} />
                                        </div>

                                    </div>                              
        
                                    <div className="caption-container">
                                        <p id="caption"></p>
                                    </div>
        
                                    <div className="row_sml" id="row_sml">

                                        <div className="column"> {/* ${(currentSlide === 1) ? 'demo' : ''} */}
                                            <img className={`demo cursor active`} src={MamaRooAssetOne} onClick={() => { currentSlide(1); }} alt="The Woods" />
                                        </div>

                                        <div className="column"> 
                                            <img className={`demo cursor`} src={MamaRooScene02CropAf} onClick={() => { currentSlide(2); }} alt="Cinque Terre" />
                                        </div>

                                        <div className="column"> 
                                            <img className={`demo cursor`} src={MamaRooScene02Af} onClick={() => { currentSlide(3); }} alt="Mountains and fjords" />
                                        </div>

                                        <div className="column"> 
                                            <img className={`demo cursor`} src={MamaRooScene06CropAf} onClick={() => { currentSlide(4); }} alt="mamaRoo sleep" />
                                        </div>

                                        <div className="column"> 
                                            <img className={`demo cursor`} src={MamaRooScene06Af} onClick={() => { currentSlide(5); }} alt="Mountains and fjords" />
                                        </div>

                                        <div className="column"> 
                                            <img className={`demo cursor`} src={MamaRooScene07CropAf} onClick={() => { currentSlide(6); }} alt="mamaRoo sleep" />
                                        </div>
                                            
                                        <div className="column"> 
                                            <img className={`demo cursor`} src={MamaRooScene07Af} onClick={() => { currentSlide(7); }} alt="mamaRoo sleep" />
                                        </div>
                                                        
                                    </div>

                                </div>
  
                                {/* */}

                            </div>             
                                    
                            <div className="col-md-5 mamaroo-description-section" id="other-grid"> 
                                <h2 className="">mamaRoo sleep <br /> bassinet</h2>

                                {/* MamaRoo */}

                                <div className="mamaroo-prices-sections">

                                    <div className="affirm-block affirm-block--price block-one-price text-center">
                                        <span className="affirm__block-descriptor">Pay now</span>
                                        <div className="price" data-product-price="" ref={refPriceTag} id="price_tag">$349.99</div>
                                        <span>One-Time Payment </span>
                                    </div>

                                    <div className="affirm-block affirm-block--affirm-price block-two-price-affirm">
                                        <p className="p1 affirm-as-low-as" data-page-type="product" data-amount="34999">Buy in
                                            monthly payments with Affirm on orders over $50. <span aria-label="Learn more (opens in modal)">Learn more</span>
                                        </p>
                                    </div>                              
 
                                </div>

                                {(!loadingViewer && slideIndex === 1) && <div className="basket_options">     
                                    <label className="controls1" id="controls1">
                                        <input className="mamaroo-checkbox" type="checkbox" checked={mamaRooHaveBasket} onChange={(e) => { setMamaRooHaveBasket(e.target.checked); showAndHideGeometry(e, 105) }} />  
                                        Add Bassinet storage basket
                                    </label>
                                </div>}

                                <div type="submit" name="" className="mamaRoo-submit-btn"> 
                                    <span>Add to Cart</span>
                                </div>

                                <div className="product__registry-integration">
                                     <img src={BabyListLogo} alt="" />              
                                </div>

                                <div className="product__notice">
                                    <div> 
                                        <p>Available in the US only.&nbsp;</p>
                                    </div>
                                </div>

                                <div className="product-description rte">
                                    <div className="short-description" data-short-description="">
                                        mamaRoo sleep&nbsp;grey bassinet sheet is included.
                                        The 4moms®&nbsp;mamaRoo sleep™&nbsp;bassinet uses the same proven motions of the
                                        4moms
                                        mamaRoo®&nbsp;infant seat to help your baby fall asleep and stay asleep longer.
                                        Soothe and
                                        comfort baby in the 4moms mamaRoo sl...
                                        <p className="product__description-toggler"></p>
                                    </div>
                                </div>        

                            </div>             
                            
                        </div>

                    </div>
                </main>

                {/* FOOTER */}
                
            </div>

        </>
    )
}

export default MamaRooPage



