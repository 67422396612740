import React, { useEffect, useRef, useState, Fragment } from "react";
import "./styles.css";

//HELPERS
import {
  StartAndHidePlayButton,
  ChangeColor,
  ChangeTexture,
  Hash_dict,
} from "../../helpers/index";

//HOOKS
import useSkecthfabViewer from "../../hooks/useSkecthfabViewer";

//DATA
import {
  BIKE_PRODUCT,
  AWS_DOMAIN,
  FRESCO_API,
  blenderMaterialsNameForModel,
  blenderMaterialsNameForFork,
} from "../../services/data";

//IMAGES
import bgConfig from "../../images/utils/config_bg.png";
import plusImg from "../../images/icons/plus.png";
import bgShare from "../../images/utils/bg-share.png";
import backImg from "../../images/icons/back.png";
import copyIcon from "../../images/icons/copyIcon.png";

//COMPONENTS
import Modal from "../../components/Modal";

//REACT ROUTER
//import { useParams } from 'react-router-dom';

//PACKAGES
import screenfull from "screenfull";
//import SpinnerTwo from '../SpinnerTwo';

export default function BikeModelConfig({
  refIframeContainer,
  setIsBikeConfigActive,
  isShoeConfigActive,
  viewerContainerClassListProperty = [
    "container-fluid",
    "container_3decommerce_iframe",
  ],
  pageName = "immersive-commerce",
  height,
}) {
  //IFRAME REFS
  const refIframeRow = useRef(null);
  const refIframeCol = useRef(null);

  //CONFIGURATOR REFS
  const refColConfigInfo = useRef(null);

  /* STEP VALUES AND NAMES */

  //ONE
  const refColStepOne = useRef(null);
  const [stepOneValue, setStepOneValue] = useState("0"); // 0 is the default option || BIKE_PRODUCT.features[0][0].default_option_id

  //TWO
  const refColStepTwoA = useRef(null);
  const [stepTwoAValue, setStepTwoAValue] = useState(
    BIKE_PRODUCT.features[1][0].default_option_id
  );
  const [stepTwoAName, setStepTwoAName] = useState(
    BIKE_PRODUCT.features[1][0].default_option_value
  );

  const refColStepTwoB = useRef(null);
  const [stepTwoBValue, setStepTwoBValue] = useState(
    BIKE_PRODUCT.features[2][0].default_option_id
  );
  const [stepTwoBName, setStepTwoBName] = useState(
    BIKE_PRODUCT.features[2][0].default_option_value
  );

  const [stepTwoUrlReference, setStepTwoUrlReference] = useState("");

  //THREE
  const refColStepThree = useRef(null);
  const refColStepThreeMovil = useRef(null);
  const [frameColorName, setFrameColorName] = useState(
    BIKE_PRODUCT.colors.initial_color_primary
  );

  //FOUR
  const refColStepFour = useRef(null);
  const [brushedColorName, setBrushedColorName] = useState(
    BIKE_PRODUCT.colors.initial_color_secondary
  );

  //FIVE
  const refColStepFive = useRef(null);
  const [brandTextureName, setBrandTextureName] = useState(
    BIKE_PRODUCT.colors.initial_color_tertiary
  );

  //SIX
  const refColStepSix = useRef(null);
  const [extrasSelected, setExtrasSelected] = useState({
    mirrors: BIKE_PRODUCT.extras[0].default ? BIKE_PRODUCT.extras[0].value : "",
    mudguards: BIKE_PRODUCT.extras[1].default
      ? BIKE_PRODUCT.extras[1].value
      : "",
    daytime_leds: BIKE_PRODUCT.extras[2].default
      ? BIKE_PRODUCT.extras[2].value
      : "",
  });

  //STEP Seven (finish)
  const refColStepSeven = useRef(null);
  const refColStepSevenMovil = useRef(null);

  //ACTUAL STEP
  const [actualStepNumber, setActualStepNumber] = useState(0);

  //VARIABLES
  const [timeDelay, setTimeDelay] = useState(1000);
  const [showModal, setShowModal] = useState(false);
  const [urlForToShare, setUrlForToShare] = useState(null);

  /* #### FOR FULLSCREEN FUNCTIONABILITY IN THIS COMPONENT ###  */
  const viewerContainerClassList = viewerContainerClassListProperty;
  const viewerRowClassList = ["row"];
  const viewerColClassList = ["col-md-12", "col-lg-9", "col-movil-styles"];

  //const { drivetrain, bikeModel, frameColor, forkColor, decals, accessories } = useParams();

  /* QUESRY */
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);

  //VALUES
  let drivetrain = params.has("drivetrain");
  let bikeModel = params.has("bikeModel");
  let frameColor = params.has("frameColor");
  let forkColor = params.has("forkColor");
  let decals = params.has("decals");
  let accessories = params.has("accessories");
  /* -- */

  if (
    drivetrain &&
    bikeModel &&
    frameColor &&
    forkColor &&
    decals &&
    accessories
  ) {
    drivetrain = params.get("drivetrain");
    bikeModel = params.get("bikeModel");
    frameColor = params.get("frameColor");
    forkColor = params.get("forkColor");
    decals = params.get("decals");
    accessories = params.get("accessories");
  }

  const viewerLoadingTime =
    drivetrain && bikeModel && frameColor && forkColor && decals && accessories
      ? 2000
      : 500;

  //CUSTOM HOOK
  const {
    viewerIframe,
    api,
    loadingViewer,
    iframeStart,
    setIframeStart,
    ios,
    android,
    refStartViewerBtn,
    fullScreen,
    setFullScreen,
    fullScreenElementState,
    viewerSectionHook,
    refViewer,
    gridBtnUiState,
    setLoadingEffect,
    setArFiles,
    setShowArBtn,
  } = useSkecthfabViewer(
    BIKE_PRODUCT.model_id,
    {
      arBtnClassNameList: ["ebike-configurator-arBtn"],
      productName: BIKE_PRODUCT.product_name,
      description: BIKE_PRODUCT.description,
      price: BIKE_PRODUCT.price,
      initial_ar_img: BIKE_PRODUCT.initial_ar_img,
      pageNameForQr: pageName,
      refIframeContainer: refIframeContainer,
      refIframeRow: refIframeRow,
      refIframeCol: refIframeCol,
      // bgStartViewer: {
      //     //desktop: `${AWS_DOMAIN}bike_objects_v2/iframe_cover/1k_config_animation.gif`,
      //     movil: `${AWS_DOMAIN}bike_objects_v2/iframe_cover/movil_config_animation.gif`
      // },
      bgStartViewer: {
        // movil: `${AWS_DOMAIN}bike_objects_v2/iframe_cover/bikeconfig_mobile_800px_fv_v4_211026.gif`,
        // desktop: `${AWS_DOMAIN}bike_objects_v2/iframe_cover/bikeconfig_mobile_800px_fv_v4_211026.gif`, // bikeconfig_desktop_hd_fv_v5_211026
        desktop: `${AWS_DOMAIN}bike_objects_v2/iframe_cover/eBike_configurator_screenshot_mobile_03_fv_211105.jpg`,
        movil: `${AWS_DOMAIN}bike_objects_v2/iframe_cover/eBike_configurator_screenshot_desktop_03_fv_211105.jpg`,
      },
      arbtn: false,
      buyInfoInAr: true,
      iosCallToActionTextInAr: "Add to cart",
      //
      playdesign: 2,
      //
      addTimeToLoading: viewerLoadingTime,
      iframeBorder: false,
      fullScreenElementID: "ebikeconfig-viewer-grid",
      viewerContainerClassList: viewerContainerClassList,
      viewerRowClassList: viewerRowClassList,
      viewerColClassList: viewerColClassList,
      forToStartBtn: {
        condition: isShoeConfigActive ? false : true,
        function: () => {
          //This function only happens when the "codition" is FALSE or it is not fulfilled
          let option = window.confirm(
            "For Mobile devices, only one Configurator can be running at the same time. Do you want to open this configurator?"
          );
          if (option === true) {
            sessionStorage.setItem("ConfiguratorToUse", "Bike");
            window.location.reload();
          }
        },
        device: "ios",
      },
    }
    /*{
            Sketchfab Args
        } */
  );

  /* ##################### EFFECTS #####################  */

  function stopViewer() {
    api.stop(function () {
      console.log("Viewer stopped");
      setIframeStart(false);
    });
  }

  window.stopBikeConfigurator = stopViewer;

  /* MAIN EFFECT */

  useEffect(() => {
    if (!loadingViewer) {
      /* -- */
      if (setIsBikeConfigActive) {
        setIsBikeConfigActive(true);
      }
      /* -- */

      if (!forkColor) {
        //COPPER BIKE TUBE (HARDCODING CODE)
        ChangeColor(
          api,
          BIKE_PRODUCT.colors.color_secondary[1].color,
          BIKE_PRODUCT.material_secondary
        );
      }

      //TEST
      if (ios || android) {
        ChangeTexture(
          api,
          `${AWS_DOMAIN}bike_objects_v2/textures/frame/mid-drive/${BIKE_PRODUCT.colors.color_tertiary[1].reference}/${BIKE_PRODUCT.colors.initial_color_primary}.jpg`,
          BIKE_PRODUCT.material_primary
        );
      }
      //---

      setTimeout(() => {
        setTimeDelay(0);
      }, timeDelay);
    }
  }, [api, loadingViewer, timeDelay, ios, android]);

  /* EFFECT FOR LOAD A EXISTING MODEL */

  useEffect(() => {
    if (
      api &&
      drivetrain &&
      bikeModel &&
      frameColor &&
      forkColor &&
      decals &&
      accessories
    ) {
      /* console.log('Configurator Summary (QUERYS):', {
                drivetrain: drivetrain,
                bikeModel: bikeModel,
                frameColor: frameColor,
                forkColor: forkColor,
                decals: decals,
                accessories: accessories,
                viewerLoadingTime: viewerLoadingTime
            }); */

      //`${AWS_DOMAIN}${pageName}?drivetrain=${stepOneValue}&bikeModel=${bikeModelValue}&frameColor=${frameColorName}&forkColor=${brushedColorName}&decals=${brandTextureName}&accessories=${(extrasSelected.mirrors === '') ? 'none' : extrasSelected.mirrors}-${(extrasSelected.mudguards === '') ? 'none' : extrasSelected.mudguards}-${(extrasSelected.daytime_leds === '') ? 'none' : extrasSelected.daytime_leds}`;

      setStepOneValue(drivetrain);

      setFrameColorName(frameColor);
      setBrushedColorName(forkColor);
      setBrandTextureName(decals);

      refIframeCol.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });

      /* ##### STEP 5 (ACCESORIES) ##### */

      //DATA
      let accessoriesArray = accessories.split("-");
      let accessoriesState = {
        mirrors: BIKE_PRODUCT.extras[0].default
          ? BIKE_PRODUCT.extras[0].value
          : "",
        mudguards: BIKE_PRODUCT.extras[1].default
          ? BIKE_PRODUCT.extras[1].value
          : "",
        daytime_leds: BIKE_PRODUCT.extras[2].default
          ? BIKE_PRODUCT.extras[2].value
          : "",
      };

      //FOR TEST
      //console.log("accessoriesArray:", accessoriesArray.length, Object.keys(extrasSelected).length);

      if (accessoriesArray.length === Object.keys(extrasSelected).length) {
        let infoExtraOne = BIKE_PRODUCT.extras.find(
          (extra) =>
            extra.value.toLowerCase() === accessoriesArray[0].toLowerCase()
        );

        if (infoExtraOne === undefined) {
          //Mirrors
          api.hide(BIKE_PRODUCT.extras[0].node_instanceID);
          accessoriesState.mirrors = "";
        } else {
          accessoriesState.mirrors = BIKE_PRODUCT.extras[0].value;
          //SHOW
        }

        let infoExtraTwo = BIKE_PRODUCT.extras.find(
          (extra) =>
            extra.value.toLowerCase() === accessoriesArray[1].toLowerCase()
        );

        if (infoExtraTwo === undefined) {
          //Mudguards
          api.hide(BIKE_PRODUCT.extras[1].node_instanceID);
          accessoriesState.mudguards = "";
        } else {
          accessoriesState.mudguards = BIKE_PRODUCT.extras[1].value;
          //SHOW
        }

        let infoExtraThree = BIKE_PRODUCT.extras.find(
          (extra) =>
            extra.value.toLowerCase() === accessoriesArray[2].toLowerCase()
        );

        if (infoExtraThree === undefined) {
          //Daytime LEDs
          api.hide(BIKE_PRODUCT.extras[2].node_instanceID);
          accessoriesState.daytime_leds = "";
        } else {
          accessoriesState.daytime_leds = BIKE_PRODUCT.extras[2].value;
          //SHOW
        }

        //FOR TEST
        //console.log(">>>>>>>>>>>> accessoriesState: <<<<<<<<<<<", accessoriesState);

        setExtrasSelected(accessoriesState);
      } else {
        //REDIRECT
        window.location = `${AWS_DOMAIN}${pageName}`;
      }

      /* ###### */

      //INITIALIZATION
      StartAndHidePlayButton(
        api,
        iframeStart,
        setIframeStart,
        loadingViewer,
        setLoadingEffect,
        refStartViewerBtn
      );

      let modelType;
      let blenderModelName;

      let blenderModelMatGroup;
      let blenderModelGeometry;

      if (!loadingViewer) {
        //STEP 0
        //HIDE OR SHOW OBJECTS

        if (
          drivetrain.toString() ===
          BIKE_PRODUCT.features[0][0].model_options[1].node_instanceID.toString()
        ) {
          //mid-drive_motor

          blenderModelName =
            BIKE_PRODUCT.features[0][0].model_options[1].blenderValue;
          modelType = "md";
          blenderModelMatGroup = "1";
        } else if (
          drivetrain.toString() ===
          BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
        ) {
          //rad_rear-hub

          blenderModelName =
            BIKE_PRODUCT.features[0][0].model_options[0].blenderValue;
          modelType = "rh";
          blenderModelMatGroup = "2";

          api.hide(BIKE_PRODUCT.features[0][0].default_option_id);
          api.show(drivetrain);
        } else {
          //REDIRECT
          window.location = `${AWS_DOMAIN}${pageName}`;
        }

        setStepOneValue(drivetrain);

        /* ##### STEP 1 ##### */
        let bikeModelExist;

        if (
          drivetrain.toString() ===
          BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
        ) {
          //Battery

          bikeModelExist = BIKE_PRODUCT.features[1][0].model_options.find(
            (option) =>
              option.node_instanceID.toString() === bikeModel.toString()
          );

          if (bikeModelExist) {
            if (
              bikeModel.toString() !==
              BIKE_PRODUCT.features[1][0].default_option_id.toString()
            ) {
              api.hide(stepTwoAValue);
              api.show(bikeModel);
            }

            blenderModelGeometry = bikeModelExist.blenderValue;

            setStepTwoAValue(bikeModel);
            setStepTwoAName(bikeModelExist.value);
            setStepTwoUrlReference(bikeModelExist.node_name);
          } else {
            //REDIRECT
            window.location = `${AWS_DOMAIN}${pageName}`;
          }
        } else if (
          drivetrain.toString() ===
          BIKE_PRODUCT.features[0][0].model_options[1].node_instanceID.toString()
        ) {
          //Top-tube

          bikeModelExist = BIKE_PRODUCT.features[2][0].model_options.find(
            (option) =>
              option.node_instanceID.toString() === bikeModel.toString()
          );

          if (bikeModelExist) {
            if (
              bikeModel.toString() !==
              BIKE_PRODUCT.features[2][0].default_option_id.toString()
            ) {
              api.hide(stepTwoBValue);
              api.show(bikeModel);
            }

            blenderModelGeometry = bikeModelExist.blenderValue;

            setStepTwoBValue(bikeModel);
            setStepTwoBName(bikeModelExist.value);
            setStepTwoUrlReference(bikeModelExist.node_name);
          } else {
            //REDIRECT
            window.location = `${AWS_DOMAIN}${pageName}`;
          }
        } else {
          //REDIRECT
          window.location = `${AWS_DOMAIN}${pageName}`;
        }

        /* ##### STEP 2 (FRAME COLOR AND DECALS TEXTURE) ##### */

        //TYPE OF MOTOR
        let typeOfMotor = `${
          drivetrain.toString() ===
          BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
            ? "rear-hub"
            : "mid-drive"
        }`;

        //MATERIAL
        let materialStepTwoToChange = BIKE_PRODUCT.material_primary;

        //IF FRAME COLOR EXIST
        let frameColorExist = BIKE_PRODUCT.colors.color_primary.find(
          (color) => color.reference === frameColor.toLowerCase()
        );

        if (frameColorExist) {
          //PNG (HIGHT OR LOW QUALITY)
          let typeOfFormat = `${ios || android ? "jpg" : "png"}`;
          let timeOfDevice = `${ios || android ? 1000 : 0}`;

          //URL
          let textureUrl = `${AWS_DOMAIN}bike_objects_v2/textures/frame/${typeOfMotor}/`;
          //let texturePackBUrl = `${frameColorExist.color}${typeOfMotor}/pack_b/${frameColorExist.reference}.${typeOfFormat}`;

          //FOR TEST
          /* 
                    console.log("<<<<<<<<  TEXTURES URL >>>>>>>>>", {
                        textureUrl: textureUrl,
                        texturePackBUrl: texturePackBUrl,
                        decals: decals,
                    });
                    */

          if (decals === BIKE_PRODUCT.colors.color_tertiary[0].value) {
            //PACK A

            setTimeout(() => {
              ChangeTexture(
                api,
                `${
                  textureUrl + BIKE_PRODUCT.colors.color_tertiary[0].reference
                }/${frameColorExist.reference}.${typeOfFormat}`,
                materialStepTwoToChange
              );
            }, timeOfDevice);
          } else if (decals === BIKE_PRODUCT.colors.color_tertiary[1].value) {
            //PACK B

            setTimeout(() => {
              ChangeTexture(
                api,
                `${
                  textureUrl + BIKE_PRODUCT.colors.color_tertiary[1].reference
                }/${frameColorExist.reference}.${typeOfFormat}`,
                materialStepTwoToChange
              ); //texturePackBUrl
            }, timeOfDevice);
          } else if (decals === BIKE_PRODUCT.colors.color_tertiary[2].value) {
            //PACK C

            setTimeout(() => {
              ChangeTexture(
                api,
                `${
                  textureUrl + BIKE_PRODUCT.colors.color_tertiary[2].reference
                }/${frameColorExist.reference}.${typeOfFormat}`,
                materialStepTwoToChange
              );
            }, timeOfDevice);
          } else {
            //REDIRECT
            window.location = `${AWS_DOMAIN}${pageName}`;
          }

          setFrameColorName(frameColorExist.reference);
          //Restart Decals (Texture)
          setBrandTextureName(decals);
        } else {
          //REDIRECT
          window.location = `${AWS_DOMAIN}${pageName}`;
        }

        /* ##### STEP 3 (FORK COLOR) ##### */

        //MATERIAL
        let materialStepThreeToChange = BIKE_PRODUCT.material_secondary;

        //IF FORK COLOR EXIST
        let forkColorExist = BIKE_PRODUCT.colors.color_secondary.find(
          (color) => color.value.toLowerCase() === forkColor.toLowerCase()
        );

        //FOR TEST
        //console.log("forkColorExist:", forkColorExist, "forkColor:", forkColor);

        if (forkColorExist) {
          ChangeColor(api, forkColorExist.color, materialStepThreeToChange);
          setBrushedColorName(forkColorExist.value);
        } else {
          //REDIRECT
          window.location = `${AWS_DOMAIN}${pageName}`;
        }

        //TO THE LAST STEP
        if (ios || android) {
          setTimeout(() => {
            HandleNextOrPrevStep(
              refColStepOne,
              refColStepSevenMovil,
              false,
              6,
              undefined,
              false,
              true,
              undefined,
              false
            );
          }, 1000);
        } else {
          setTimeout(() => {
            HandleNextOrPrevStep(
              refColStepOne,
              refColStepSeven,
              false,
              6,
              undefined,
              undefined,
              undefined,
              undefined,
              false
            );
          }, 1000);
        }

        refColConfigInfo.current.style.visibility = "initial";

        //#########################

        let packType = decals.charAt(decals.length - 1).toLowerCase();
        let bikeForkColorName = forkColor.toLowerCase();

        /* if(stepOneValue === BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()){  //rear_hub
    
                        if(stepTwoAValue.toString() === '631'){  //single battery
                            blenderModelGeometry = BIKE_PRODUCT.features[1][0].model_options[0].blenderValue; 
                        } else if(stepTwoAValue.toString() === '615') {  //double battery
                            blenderModelGeometry = BIKE_PRODUCT.features[1][0].model_options[1].blenderValue; 
                        }         

                    } else {   //mid_drive

                        if(stepTwoBValue.toString() === '485'){  //top tube none
                            blenderModelGeometry = BIKE_PRODUCT.features[2][0].model_options[0].blenderValue;
                        } else if(stepTwoBValue.toString() === '501') {  //top tube short 
                            blenderModelGeometry = BIKE_PRODUCT.features[2][0].model_options[1].blenderValue; 
                        } else if(stepTwoBValue.toString() === '517') {  //top tube tall
                            blenderModelGeometry = BIKE_PRODUCT.features[2][0].model_options[2].blenderValue;             
                        }

                    } */

        let finaljson = {
          config: {
            ...configJSON.config,
            rad: [
              {
                name: blenderModelName,
                type: "collection",
                rad: [
                  {
                    name: blenderModelGeometry,
                    type: "collection",
                  },
                ],
              },
            ],
          },
        };

        //Bike ACCESORIES
        let accesoriesSelectedToJson = [];
        if (accessoriesState.mirrors) {
          accesoriesSelectedToJson.push({
            name: "sel_mirrors",
            type: "collection",
          });
        }
        if (accessoriesState.mudguards) {
          accesoriesSelectedToJson.push({
            name: "sel_mudguards",
            type: "collection",
          });
        }
        if (accessoriesState.daytime_leds) {
          accesoriesSelectedToJson.push({
            name: "sel_daytime-leds",
            type: "collection",
          });
        }

        if (accesoriesSelectedToJson.length > 0) {
          finaljson.config.sel = accesoriesSelectedToJson;
        }

        //${brandTextureName.charAt(brandTextureName.length-1)}   // <---- CHANGE
        let bikeModelAndPackColor =
          `${modelType}${packType}_${frameColor}`.toLowerCase();
        //console.log('bikeModelAndPackColor(1) >', bikeModelAndPackColor);
        //EJAMPLE: mdc_teal

        bikeModelAndPackColor = `${blenderMaterialsNameForModel[bikeModelAndPackColor]}${bikeModelAndPackColor}`;
        //console.log('bikeModelAndPackColor(2) >', bikeModelAndPackColor);

        let bikeForkColorMaterial = `${
          blenderMaterialsNameForFork[`fork_${bikeForkColorName}`]
        }fork_${bikeForkColorName}`;

        /* #### */

        //Bike MATERIALS
        let materialsToJson = {
          [blenderModelMatGroup]: bikeModelAndPackColor,
          3: bikeForkColorMaterial,
        };

        //console.log('materialsToJson =', materialsToJson);

        finaljson.config.material_groups = materialsToJson; //finaljson.config.materials (for materials without groups)

        setConfigJSON(finaljson);

        //console.log('finaljson =>', finaljson);

        let redirectURL = encodeURIComponent(`${AWS_DOMAIN}${pageName}`);

        //============
        Hash_dict(finaljson)
          .then((hashResult) => {
            /* console.log({
                            jsonName: 'configJSON (FINAL)',
                            hash: hashResult,
                            redirectURL: redirectURL,
                            completeUrl: `${FRESCO_API}openAR?sha1=${hashResult}&redirectTo=${redirectURL}`, 
                        }); */
            setArFiles({
              local: false,
              landingPage: `${FRESCO_API}openAR?sha1=${hashResult}&redirectTo=${redirectURL}`,
            });
            setShowArBtn(true);
          })
          .catch((error) =>
            console.log("Error in the coversion of JSON to HASH:", error)
          );

        //#########################
      }
    }
  }, [loadingViewer, api]);

  /* EFFECT FOR HIDE ACCESORIES OR FEATURES */

  useEffect(() => {
    if (api) {
      api.addEventListener("viewerready", function () {
        if (BIKE_PRODUCT.features) {
          try {
            BIKE_PRODUCT.features.forEach((features) => {
              features.forEach((feature) => {
                let dafaultOptionId = feature.default_option_id;
                feature.model_options.forEach((option) => {
                  if (option.node_instanceID !== dafaultOptionId) {
                    api.hide(option.node_instanceID);
                  }
                });
              });
            });
          } catch (error) {
            console.error(error);
          }
        }

        //HIDE OR SHOW NODES (EXTRAS)
        if (!accessories) {
          if (BIKE_PRODUCT.extras) {
            try {
              BIKE_PRODUCT.extras.forEach((extra) => {
                if (extra.default === false) {
                  api.hide(extra.node_instanceID);
                }
              });
            } catch (error) {
              console.error(error);
            }
          }
        }
      });
    }
  }, [api]);

  useEffect(() => {
    if (viewerSectionHook) {
      viewerSectionHook.current.style.height = height;
    }
  }, [viewerSectionHook, height]);

  /* ##################### FUNCTIONS ##################### */

  const HandleNextOrPrevStep = (
    elementToHide,
    elementToShow,
    back,
    numberStepToGo,
    nodeSelectedId,
    direct,
    movil,
    numberActualStep,
    createArLinkInFinalStep = true
  ) => {
    //alert(`${elementToHide} ${elementToShow} ${back} ${numberStepToGo} ${nodeSelectedId} ${direct} ${movil} ${numberActualStep}`);

    if (!firstClickOnMovilMenu) {
      setFirstClickOnMovilMenu(true);
    }

    if (movil && movilConfigMenuExpand) {
      //HIDE OPTIONS
      refMovilOptionsGrid.current.style.display = "";
      refMovilMenu.current.classList.remove("expand");
      setMovilConfigMenuExpand(false);

      //SHOW TITLE
      refMovilOptionTitle.current.style.display = "";

      /* <FULL SCREEN ACTION> */

      if (screenfull.isEnabled) {
        //CHANGE LATER

        if (screenfull.isFullscreen) {
          setFullScreen(false);
          screenfull.exit();
          viewerSectionHook.current.style.height = "";
        }
      } else {
        //FULL SCREEN SIMULATION

        let html = document.getElementsByTagName("html");

        if (fullScreen) {
          fullScreenElementState.style.border = "";
          viewerSectionHook.current.style.border = "";
          refViewer.current.style.height = "";
          gridBtnUiState.current.style.bottom = "";
          refIframeCol.current.classList.remove("iframe-fullscreen");
          /* Add Viewer ClassLists */
          if (viewerContainerClassList) {
            if (viewerContainerClassList.length !== 0) {
              refIframeContainer.current.classList.add(
                ...viewerContainerClassList
              );
            }
          }
          if (viewerRowClassList) {
            if (viewerRowClassList.length !== 0) {
              refIframeRow.current.classList.add(...viewerRowClassList);
            }
          }
          if (viewerColClassList) {
            if (viewerColClassList.length !== 0) {
              refIframeCol.current.classList.add(...viewerColClassList);
            }
          }
          /* --- */
          html[0].style.overflow = "auto";
          //window.scroll(0, 200);
          setFullScreen(false);
        }
      }

      /* </FULL SCREEN ACTION> */
    }

    if (direct) {
      //IS DIRECT WHEN THE USER IS NAVIGATING AT SEP UP OPTIONS (QUICK ACCESS)

      if (numberStepToGo === 6) {
      } else {
        setShowArBtn(false);
      }

      switch (numberActualStep) {
        case 1:
          let value =
            stepOneValue ===
            BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
              ? "Rear Hub"
              : "Mid Drive";

          if (value === "Rear Hub") {
            refColStepTwoA.current.style.display = "none";
          } else {
            refColStepTwoB.current.style.display = "none";
          }
          break;

        case 2:
          if (movil) {
            refColStepThreeMovil.current.style.display = "none";
          } else {
            refColStepThree.current.style.display = "none";
          }

          break;

        case 3:
          refColStepFour.current.style.display = "none";
          break;

        case 4:
          refColStepFive.current.style.display = "none";
          break;

        case 5:
          refColStepSix.current.style.display = "none";
          break;

        case 6:
          if (movil) {
            refColStepSevenMovil.current.style.display = "none";
          } else {
            refColStepSeven.current.style.display = "none";
          }

          break;

        default:
          break;
      }

      setActualStepNumber(numberStepToGo);
    } else {
      //THE USER IS NAVIGATING WITH THE ARROW (LEFT AND RIGHT)

      /*  if(!nodeSelectedId){ 
               elementToHide.current.style.display = 'none';
            } */

      if (numberStepToGo === 6) {
        /* console.log('Configurator Summary (STEPS):', {
                    stepOneValue: stepOneValue,
                    stepTwoAValue: stepTwoAValue,
                    stepTwoAName: stepTwoAName,
                    stepTwoBValue: stepTwoBValue,
                    stepTwoBName: stepTwoBName,
                    stepTwoUrlReference: stepTwoUrlReference,
                    frameColorName: frameColorName,
                    brushedColorName: brushedColorName,
                    brandTextureName: brandTextureName,
                    extrasSelected: extrasSelected,
                    actualStepNumber: actualStepNumber
                }); */

        let modelType;
        let packType = brandTextureName
          .charAt(brandTextureName.length - 1)
          .toLowerCase();
        let bikeForkColorName = brushedColorName.toLowerCase();

        //Bike MODEL
        let blenderModelName;
        let blenderModelGeometry;
        let blenderModelMatGroup;

        if (
          stepOneValue ===
          BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
        ) {
          //rear_hub
          blenderModelName =
            BIKE_PRODUCT.features[0][0].model_options[0].blenderValue;

          modelType = "rh";
          blenderModelMatGroup = "2";

          if (stepTwoAValue.toString() === "631") {
            //single battery
            blenderModelGeometry =
              BIKE_PRODUCT.features[1][0].model_options[0].blenderValue;
          } else if (stepTwoAValue.toString() === "615") {
            //double battery
            blenderModelGeometry =
              BIKE_PRODUCT.features[1][0].model_options[1].blenderValue;
          }
        } else {
          //mid_drive
          blenderModelName =
            BIKE_PRODUCT.features[0][0].model_options[1].blenderValue;

          modelType = "md";
          blenderModelMatGroup = "1";

          if (stepTwoBValue.toString() === "485") {
            //top tube none
            blenderModelGeometry =
              BIKE_PRODUCT.features[2][0].model_options[0].blenderValue;
          } else if (stepTwoBValue.toString() === "501") {
            //top tube short
            blenderModelGeometry =
              BIKE_PRODUCT.features[2][0].model_options[1].blenderValue;
          } else if (stepTwoBValue.toString() === "517") {
            //top tube tall
            blenderModelGeometry =
              BIKE_PRODUCT.features[2][0].model_options[2].blenderValue;
          }
        }

        let finaljson = {
          config: {
            ...configJSON.config,
            rad: [
              {
                name: blenderModelName,
                type: "collection",
                rad: [
                  {
                    name: blenderModelGeometry,
                    type: "collection",
                  },
                ],
              },
            ],
          },
        };

        //Bike ACCESORIES
        let accesoriesSelectedToJson = [];
        if (extrasSelected.mirrors) {
          accesoriesSelectedToJson.push({
            name: "sel_mirrors",
            type: "collection",
          });
        }
        if (extrasSelected.mudguards) {
          accesoriesSelectedToJson.push({
            name: "sel_mudguards",
            type: "collection",
          });
        }
        if (extrasSelected.daytime_leds) {
          accesoriesSelectedToJson.push({
            name: "sel_daytime-leds",
            type: "collection",
          });
        }

        if (accesoriesSelectedToJson.length > 0) {
          finaljson.config.sel = accesoriesSelectedToJson;
        }

        //${brandTextureName.charAt(brandTextureName.length-1)}   // <---- CHANGE
        let bikeModelAndPackColor =
          `${modelType}${packType}_${frameColorName}`.toLowerCase();
        //console.log('bikeModelAndPackColor(1) >', bikeModelAndPackColor);
        //EJAMPLE: mdc_teal

        /* # REMOVE # (3/4) */

        bikeModelAndPackColor = `${blenderMaterialsNameForModel[bikeModelAndPackColor]}${bikeModelAndPackColor}`;
        //console.log('bikeModelAndPackColor(2) >', bikeModelAndPackColor);

        let bikeForkColorMaterial = `${
          blenderMaterialsNameForFork[`fork_${bikeForkColorName}`]
        }fork_${bikeForkColorName}`;

        /* #### */

        //Bike MATERIALS
        let materialsToJson = {
          [blenderModelMatGroup]: bikeModelAndPackColor,
          3: bikeForkColorMaterial,
        };

        //console.log('materialsToJson =', materialsToJson);

        finaljson.config.material_groups = materialsToJson;

        setConfigJSON(finaljson);

        //console.log('finaljson =>', finaljson);

        let redirectURL = encodeURIComponent(`${AWS_DOMAIN}${pageName}`);

        //============
        if (createArLinkInFinalStep) {
          Hash_dict(finaljson)
            .then((hashResult) => {
              /* console.log({
                            jsonName: 'configJSON (FINAL)',
                            hash: hashResult,
                            redirectURL: redirectURL,
                            completeUrl: `${FRESCO_API}openAR?sha1=${hashResult}&redirectTo=${redirectURL}`, 
                        }); */
              setArFiles({
                local: false,
                landingPage: `${FRESCO_API}openAR?sha1=${hashResult}&redirectTo=${redirectURL}`,
              });
              setShowArBtn(true);
            })
            .catch((error) =>
              console.log("Error in the coversion of JSON to HASH:", error)
            );
        }
        //============
      } else {
        setShowArBtn(false);
      }

      elementToHide.current.style.display = "none";
      setActualStepNumber(numberStepToGo);
    }

    //setActualStepNumber(actualStepNumber);

    //ONLY FOR ONESTEP
    if (nodeSelectedId) {
      //TEST
      if (!back) {
        /* ----- If after the first step (when giving the arrow for the first time) the state "stepTwoUrlReference" is empty, 
                the default value of the model (Mid drive or Rear Hub) is given as a value by selecting in the first step, 
                For example: The default value of Mid drive is No-top-tube and Rear Hub is single-battery. ---- */
        if (stepTwoUrlReference === "") {
          let defaultValue =
            nodeSelectedId ===
            BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
              ? BIKE_PRODUCT.features[1][0].default_option_name
              : BIKE_PRODUCT.features[2][0].default_option_name;

          setStepTwoUrlReference(defaultValue);
        }
      }

      //----- Show model characteristics by selecting (those of REAR HUB or MID DRIVE)
      if (
        nodeSelectedId ===
        BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
      ) {
        refColStepTwoA.current.style.display = "flex";
        /* -----------------  CAMERA ANIMATION ----------------- */
        if (ios || android) {
          api.gotoAnnotation(16, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        } else {
          api.gotoAnnotation(3, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        }
        /* --- */
      } else if (
        nodeSelectedId ===
        BIKE_PRODUCT.features[0][0].model_options[1].node_instanceID.toString()
      ) {
        refColStepTwoB.current.style.display = "flex";
        /* -----------------  CAMERA ANIMATION ----------------- */
        if (ios || android) {
          api.gotoAnnotation(15, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        } else {
          api.gotoAnnotation(2, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        }
        /* --- */
      }
      //----------

      //SHOW SETUP
      if (!back) {
        refColConfigInfo.current.style.visibility = "initial";
      }

      return;
    }

    /* -----------------  CAMERA ANIMATION ----------------- */
    if (ios || android) {
      api.gotoAnnotation(15, {
        preventCameraAnimation: false,
        preventCameraMove: false,
      });
    } else {
      api.gotoAnnotation(2, {
        preventCameraAnimation: false,
        preventCameraMove: false,
      });
    }
    /* --- */

    elementToShow.current.style.display = "flex";
  };

  /* MODAL */
  function handleOpen() {
    let bikeModelValue =
      stepOneValue.toString() ===
      BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
        ? stepTwoAValue
        : stepTwoBValue;
    //let shareLinkOLD = `${AWS_DOMAIN}${pageName}/state/${stepOneValue}/${bikeModelValue}/${frameColorName}/${brushedColorName}/${brandTextureName}/${(extrasSelected.mirrors === '') ? 'none' : extrasSelected.mirrors}-${(extrasSelected.mudguards === '') ? 'none' : extrasSelected.mudguards}-${(extrasSelected.daytime_leds === '') ? 'none' : extrasSelected.daytime_leds}`;
    let shareLink = `${AWS_DOMAIN}${pageName}?drivetrain=${stepOneValue}&bikeModel=${bikeModelValue}&frameColor=${frameColorName}&forkColor=${brushedColorName}&decals=${brandTextureName}&accessories=${
      extrasSelected.mirrors === "" ? "none" : extrasSelected.mirrors
    }-${extrasSelected.mudguards === "" ? "none" : extrasSelected.mudguards}-${
      extrasSelected.daytime_leds === "" ? "none" : extrasSelected.daytime_leds
    }`;

    setUrlForToShare(encodeURI(shareLink));
    setShowModal(true);
  }

  function handleClose() {
    setShowModal(false);
  }

  /* ------------------------------------ */

  const HandleColorOnClick = (
    isTexture,
    colorOrTextureModel,
    materialModel,
    e,
    colorReference
  ) => {
    /* ---- HIDE THE MOVIL MENU ---- */
    try {
      if (movilConfigMenuExpand) {
        //HIDE OPTIONS
        refMovilOptionsGrid.current.style.display = "";
        refMovilMenu.current.classList.remove("expand");
        setMovilConfigMenuExpand(false);

        //SHOW TITLE
        refMovilOptionTitle.current.style.display = "";
      }
    } catch (error) {
      console.error(error);
    }
    /* --------------------------- */

    StartAndHidePlayButton(
      api,
      iframeStart,
      setIframeStart,
      loadingViewer,
      setLoadingEffect,
      refStartViewerBtn
    );

    let packSelected;

    if (isTexture) {
      //------
      if (brandTextureName !== BIKE_PRODUCT.colors.initial_color_tertiary) {
        let textureUrl = `${AWS_DOMAIN}bike_objects_v2/textures/frame/${
          stepOneValue ===
          BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
            ? "rear-hub"
            : "mid-drive"
        }/`;

        if (brandTextureName === BIKE_PRODUCT.colors.color_tertiary[0].value) {
          packSelected = BIKE_PRODUCT.colors.color_tertiary[0].value;
          //PACK A
          ChangeTexture(
            api,
            `${
              textureUrl + BIKE_PRODUCT.colors.color_tertiary[0].reference
            }/${colorReference}.${ios || android ? "jpg" : "png"}`,
            materialModel
          );
        } else if (
          brandTextureName === BIKE_PRODUCT.colors.color_tertiary[2].value
        ) {
          packSelected = BIKE_PRODUCT.colors.color_tertiary[2].value;
          //PACK C
          ChangeTexture(
            api,
            `${
              textureUrl + BIKE_PRODUCT.colors.color_tertiary[2].reference
            }/${colorReference}.${ios || android ? "jpg" : "png"}`,
            materialModel
          );
        }
      } else {
        packSelected = BIKE_PRODUCT.colors.color_tertiary[1].value;
        //DEFAULT
        //console.log('imagen movil:', colorOrTextureModel);        //<-----------------------
        ChangeTexture(api, colorOrTextureModel, materialModel);
      }
      //------

      //DEFAULT
      //ChangeTexture(api, colorOrTextureModel, materialModel);
    } else {
      ChangeColor(api, colorOrTextureModel, materialModel);
    }

    //Material Colors State
    if (materialModel === BIKE_PRODUCT.material_primary) {
      setFrameColorName(colorReference); //e.target.value
      //Restart Decals (Texture)
      setBrandTextureName(packSelected); //BIKE_PRODUCT.colors.initial_color_tertiary
      /* -----------------  CAMERA ANIMATION ----------------- */
      if (ios || android) {
        api.gotoAnnotation(15, {
          preventCameraAnimation: false,
          preventCameraMove: false,
        });
      } else {
        api.gotoAnnotation(2, {
          preventCameraAnimation: false,
          preventCameraMove: false,
        });
      }
    } else if (materialModel === BIKE_PRODUCT.material_secondary) {
      setBrushedColorName(e.target.value);
      /* -----------------  CAMERA ANIMATION ----------------- */
      if (ios || android) {
        api.gotoAnnotation(18, {
          preventCameraAnimation: false,
          preventCameraMove: false,
        });
      } else {
        api.gotoAnnotation(5, {
          preventCameraAnimation: false,
          preventCameraMove: false,
        });
      }
    }
  };

  const HandleTextureOnClick = (
    isTexture,
    colorOrTextureModel,
    materialModel,
    e,
    colorReference
  ) => {
    /* ---- HIDE THE MOVIL MENU ---- */
    try {
      if (movilConfigMenuExpand) {
        //HIDE OPTIONS
        refMovilOptionsGrid.current.style.display = "";
        refMovilMenu.current.classList.remove("expand");
        setMovilConfigMenuExpand(false);

        //SHOW TITLE
        refMovilOptionTitle.current.style.display = "";
      }
    } catch (error) {
      console.error(error);
    }
    /* --------------------------- */

    StartAndHidePlayButton(
      api,
      iframeStart,
      setIframeStart,
      loadingViewer,
      setLoadingEffect,
      refStartViewerBtn
    );

    let textureComplete = `${colorOrTextureModel + frameColorName + "."}${
      ios || android ? "jpg" : "png"
    }`;

    ChangeTexture(api, textureComplete, materialModel);

    //Material Colors State
    if (materialModel === BIKE_PRODUCT.material_primary) {
      setBrandTextureName(e.target.value);
      /* -----------------  CAMERA ANIMATION ----------------- */
      if (ios || android) {
        api.gotoAnnotation(25, {
          preventCameraAnimation: false,
          preventCameraMove: false,
        });
      } else {
        api.gotoAnnotation(12, {
          preventCameraAnimation: false,
          preventCameraMove: false,
        });
      }
    }
  };

  const HandleFeatures = (
    e,
    featureReference,
    featureOptionName,
    optionReference
  ) => {
    /* ---- HIDE THE MOVIL MENU ---- */
    try {
      if (movilConfigMenuExpand) {
        //HIDE OPTIONS
        refMovilOptionsGrid.current.style.display = "";
        refMovilMenu.current.classList.remove("expand");
        setMovilConfigMenuExpand(false);

        //SHOW TITLE
        refMovilOptionTitle.current.style.display = "";
      }
    } catch (error) {
      console.error(error);
    }
    /* --------------------------- */

    StartAndHidePlayButton(
      api,
      iframeStart,
      setIframeStart,
      loadingViewer,
      setLoadingEffect,
      refStartViewerBtn
    );

    let objectId = e.target.value;

    if (featureReference === BIKE_PRODUCT.features[0][0].reference) {
      //'drivetrain'

      let textureUrl;

      //CAMERA
      if (
        objectId ===
        BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
      ) {
        //rear-hub_motor

        /* -------- Establish the correct texture (if any PACK is selected in a Mid drive model, when changing to Rear hub, 
                also change the texture for said model) for the selected model (Mid or Rear)  --------- */
        if (brandTextureName && frameColorName) {
          textureUrl = `${AWS_DOMAIN}bike_objects_v2/textures/frame/rear-hub/`;

          if (
            brandTextureName === BIKE_PRODUCT.colors.color_tertiary[0].value
          ) {
            ChangeTexture(
              api,
              `${
                textureUrl + BIKE_PRODUCT.colors.color_tertiary[0].reference
              }/${frameColorName}.png`,
              BIKE_PRODUCT.material_primary
            );
          } else if (
            brandTextureName === BIKE_PRODUCT.colors.color_tertiary[1].value
          ) {
            ChangeTexture(
              api,
              `${
                textureUrl + BIKE_PRODUCT.colors.color_tertiary[1].reference
              }/${frameColorName}.png`,
              BIKE_PRODUCT.material_primary
            );
          } else if (
            brandTextureName === BIKE_PRODUCT.colors.color_tertiary[2].value
          ) {
            ChangeTexture(
              api,
              `${
                textureUrl + BIKE_PRODUCT.colors.color_tertiary[2].reference
              }/${frameColorName}.png`,
              BIKE_PRODUCT.material_primary
            );
          }
        }

        /* -----------------  CAMERA ANIMATION ----------------- */
        if (ios || android) {
          api.gotoAnnotation(22, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        } else {
          api.gotoAnnotation(9, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        }
        //-------- SUB FILE / SUB-FEATURE (FOR URL STATE) -----//
        if (
          stepTwoAValue.toString() ===
          BIKE_PRODUCT.features[1][0].model_options[0].node_instanceID.toString()
        ) {
          //'631'
          setStepTwoUrlReference(
            BIKE_PRODUCT.features[1][0].model_options[0].node_name
          );
        } else if (
          stepTwoAValue.toString() ===
          BIKE_PRODUCT.features[1][0].model_options[1].node_instanceID.toString()
        ) {
          //'615'
          setStepTwoUrlReference(
            BIKE_PRODUCT.features[1][0].model_options[1].node_name
          );
        }
        //--------------------------------------------------------
      }

      if (
        objectId ===
        BIKE_PRODUCT.features[0][0].model_options[1].node_instanceID.toString()
      ) {
        //mid-drive_motor

        /* -------- Establish the correct texture (if any PACK is selected in a Mid drive model, when changing to Rear hub, 
                also change the texture for said model) for the selected model (Mid or Rear)  --------- */
        if (brandTextureName && frameColorName) {
          textureUrl = `${AWS_DOMAIN}bike_objects_v2/textures/frame/mid-drive/`;

          if (
            brandTextureName === BIKE_PRODUCT.colors.color_tertiary[0].value
          ) {
            ChangeTexture(
              api,
              `${
                textureUrl + BIKE_PRODUCT.colors.color_tertiary[0].reference
              }/${frameColorName}.png`,
              BIKE_PRODUCT.material_primary
            );
          } else if (
            brandTextureName === BIKE_PRODUCT.colors.color_tertiary[1].value
          ) {
            ChangeTexture(
              api,
              `${
                textureUrl + BIKE_PRODUCT.colors.color_tertiary[1].reference
              }/${frameColorName}.png`,
              BIKE_PRODUCT.material_primary
            );
          } else if (
            brandTextureName === BIKE_PRODUCT.colors.color_tertiary[2].value
          ) {
            ChangeTexture(
              api,
              `${
                textureUrl + BIKE_PRODUCT.colors.color_tertiary[2].reference
              }/${frameColorName}.png`,
              BIKE_PRODUCT.material_primary
            );
          }
        }
        /* -----------------  CAMERA ANIMATION ----------------- */
        if (ios || android) {
          api.gotoAnnotation(14, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        } else {
          api.gotoAnnotation(1, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        }
        //-------- SUB FILE / SUB-FEATURE (FOR URL STATE) -----//
        if (
          stepTwoBValue.toString() ===
          BIKE_PRODUCT.features[2][0].model_options[0].node_instanceID.toString()
        ) {
          //'485'
          setStepTwoUrlReference(
            BIKE_PRODUCT.features[2][0].model_options[0].node_name
          );
        } else if (
          stepTwoBValue.toString() ===
          BIKE_PRODUCT.features[2][0].model_options[1].node_instanceID.toString()
        ) {
          //'501'
          setStepTwoUrlReference(
            BIKE_PRODUCT.features[2][0].model_options[1].node_name
          );
        } else if (
          stepTwoBValue.toString() ===
          BIKE_PRODUCT.features[2][0].model_options[2].node_instanceID.toString()
        ) {
          //'517'
          setStepTwoUrlReference(
            BIKE_PRODUCT.features[2][0].model_options[2].node_name
          );
        }
        //-------------------------------------------------------
      }

      //HIDE OR SHOW OBJECTS
      if (stepOneValue === "0") {
        api.hide(BIKE_PRODUCT.features[0][0].default_option_id);
      } else {
        api.hide(stepOneValue);
      }

      //api.hide(stepOneValue);

      api.show(objectId, function () {
        setStepOneValue(objectId);
      });
    } else if (featureReference === BIKE_PRODUCT.features[1][0].reference) {
      //'battery'

      api.hide(stepTwoAValue);

      api.show(objectId, function () {
        setStepTwoAValue(objectId);
        setStepTwoAName(featureOptionName);
        setStepTwoUrlReference(optionReference);
      });

      //CAMERA & JSON CONFIG
      /* if(objectId === '631'){       //single_battery
                 if(ios || android){
                    api.gotoAnnotation(3, {preventCameraAnimation: false, preventCameraMove: false});
                }else{
                    api.gotoAnnotation(3, {preventCameraAnimation: false, preventCameraMove: false});
                } 
            } else if(objectId === '615'){     //double_battery
                
                if(ios || android){
                api.gotoAnnotation(3, {preventCameraAnimation: false, preventCameraMove: false});
                }else{
                    api.gotoAnnotation(3, {preventCameraAnimation: false, preventCameraMove: false});
                } 
            } */

      /* -----------------  CAMERA ANIMATION ----------------- */
      if (ios || android) {
        api.gotoAnnotation(16, {
          preventCameraAnimation: false,
          preventCameraMove: false,
        });
      } else {
        api.gotoAnnotation(3, {
          preventCameraAnimation: false,
          preventCameraMove: false,
        });
      }
    } else if (featureReference === BIKE_PRODUCT.features[2][0].reference) {
      //'toptube'

      api.hide(stepTwoBValue);

      api.show(objectId, function () {
        setStepTwoBValue(objectId);
        setStepTwoBName(featureOptionName);
        setStepTwoUrlReference(optionReference);
      });

      //CAMERA & JSON CONFIG
      /*if(objectId === '485'){   
                if(ios || android){
                    api.gotoAnnotation(3, {preventCameraAnimation: false, preventCameraMove: false});
                }else{
                    api.gotoAnnotation(3, {preventCameraAnimation: false, preventCameraMove: false});
                } 
            } else if(objectId === '501'){
                if(ios || android){
                    api.gotoAnnotation(3, {preventCameraAnimation: false, preventCameraMove: false});
                }else{
                    api.gotoAnnotation(3, {preventCameraAnimation: false, preventCameraMove: false});
                } 
            } else if(objectId === '517'){ 
                if(ios || android){
                    api.gotoAnnotation(3, {preventCameraAnimation: false, preventCameraMove: false});
                }else{
                    api.gotoAnnotation(3, {preventCameraAnimation: false, preventCameraMove: false});
                } 
            } */

      /* -----------------  CAMERA ANIMATION ----------------- */
      if (ios || android) {
        api.gotoAnnotation(16, {
          preventCameraAnimation: false,
          preventCameraMove: false,
        });
      } else {
        api.gotoAnnotation(3, {
          preventCameraAnimation: false,
          preventCameraMove: false,
        });
      }
    }
  };

  const HandleOnChangeCheckBox = (e, extraName, circleId, labelId, titleId) => {
    /* ---- HIDE THE MOVIL MENU ---- */
    try {
      if (movilConfigMenuExpand) {
        //HIDE OPTIONS
        refMovilOptionsGrid.current.style.display = "";
        refMovilMenu.current.classList.remove("expand");
        setMovilConfigMenuExpand(false);

        //SHOW TITLE
        refMovilOptionTitle.current.style.display = "";
      }
    } catch (error) {
      console.error(error);
    }
    /* --------------------------- */

    StartAndHidePlayButton(
      api,
      iframeStart,
      setIframeStart,
      loadingViewer,
      setLoadingEffect,
      refStartViewerBtn
    );

    let objectId = e.target.value;

    let titleOption = document.getElementById(titleId);

    if (e.target.checked) {
      //SHOW GREEN TITLE OPTION
      titleOption.style.color = "var(--green-fresco)";

      api.show(objectId);

      //CAMERA AND ACCESSORIES SELECTED STATE
      if (extraName === BIKE_PRODUCT.extras[0].value) {
        /* -----------------  CAMERA ANIMATION ----------------- */
        if (ios || android) {
          api.gotoAnnotation(19, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        } else {
          api.gotoAnnotation(6, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        }
        /* --- */
        setExtrasSelected({ ...extrasSelected, mirrors: extraName });
      } else if (extraName === BIKE_PRODUCT.extras[1].value) {
        /* -----------------  CAMERA ANIMATION ----------------- */
        if (ios || android) {
          api.gotoAnnotation(20, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        } else {
          api.gotoAnnotation(7, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        }
        /* --- */
        setExtrasSelected({ ...extrasSelected, mudguards: extraName });
      } else if (extraName === BIKE_PRODUCT.extras[2].value) {
        /* -----------------  CAMERA ANIMATION ----------------- */
        if (ios || android) {
          api.gotoAnnotation(21, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        } else {
          api.gotoAnnotation(8, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        }
        /* --- */
        setExtrasSelected({ ...extrasSelected, daytime_leds: extraName });
      }
    } else {
      /* LOGIC FOR NOT TO SHOW THE GREEN BORDER AND TITLE OPTIONS (WHEN THE INPUT IS NOT CHECKED) */
      let circleOption = document.getElementById(circleId);
      let labelOption = document.getElementById(labelId);

      //NO Green border
      labelOption.style.border = "#ccc 3px solid";
      titleOption.style.color = "#212529";

      function handleMouseOver() {
        labelOption.style.border = "";
        titleOption.style.color = "";
        circleOption.removeEventListener("mouseleave", handleMouseOver);
      }

      circleOption.addEventListener("mouseleave", handleMouseOver);
      /* ----------- */

      api.hide(objectId);

      //CAMERA AND ACCESSORIES SELECTED STATE
      if (extraName === BIKE_PRODUCT.extras[0].value) {
        /* -----------------  CAMERA ANIMATION ----------------- */
        if (ios || android) {
          api.gotoAnnotation(19, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        } else {
          api.gotoAnnotation(6, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        }
        /* --- */
        setExtrasSelected({ ...extrasSelected, mirrors: "" });
      } else if (extraName === BIKE_PRODUCT.extras[1].value) {
        /* -----------------  CAMERA ANIMATION ----------------- */
        if (ios || android) {
          api.gotoAnnotation(20, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        } else {
          api.gotoAnnotation(7, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        }
        /* --- */
        setExtrasSelected({ ...extrasSelected, mudguards: "" });
      } else if (extraName === BIKE_PRODUCT.extras[2].value) {
        /* -----------------  CAMERA ANIMATION ----------------- */
        if (ios || android) {
          api.gotoAnnotation(21, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        } else {
          api.gotoAnnotation(8, {
            preventCameraAnimation: false,
            preventCameraMove: false,
          });
        }
        /* --- */
        setExtrasSelected({ ...extrasSelected, daytime_leds: "" });
      }
    }
  };

  /* **************  MOVIL FUNCTION  ******************* */

  const refMovilMenu = useRef(null);
  const refMovilOptionTitle = useRef(null);
  const refMovilOptionsGrid = useRef(null);

  const [movilConfigMenuExpand, setMovilConfigMenuExpand] = useState(false);
  const [firstClickOnMovilMenu, setFirstClickOnMovilMenu] = useState(false);

  const HandleStartMovil = () => {
    setFirstClickOnMovilMenu(true);

    if (iframeStart) {
    } else {
      StartAndHidePlayButton(
        api,
        iframeStart,
        setIframeStart,
        loadingViewer,
        setLoadingEffect,
        refStartViewerBtn
      );
    }

    //SHOW OPTIONS
    refMovilOptionsGrid.current.style.display = "block";
    refMovilMenu.current.classList.add("expand");
    setMovilConfigMenuExpand(true);

    //HIDE TITLE
    refMovilOptionTitle.current.style.display = "none";

    /* EVENT LISTENER */
    function handleViewerCamera() {
      //HIDE OPTIONS
      refMovilOptionsGrid.current.style.display = "";
      refMovilMenu.current.classList.remove("expand");
      //SHOW TITLE
      refMovilOptionTitle.current.style.display = "";

      setMovilConfigMenuExpand(false);
      api.removeEventListener("camerastart", handleViewerCamera);
    }
    api.addEventListener("camerastart", handleViewerCamera);
    /* --- */
  };

  /* ************************************* */

  function HandleClickOnIframe() {
    if (api) {
      StartAndHidePlayButton(
        api,
        iframeStart,
        setIframeStart,
        loadingViewer,
        setLoadingEffect,
        refStartViewerBtn
      );
    }
  }

  /* ********* DESKTOP BACKGROUND ON ROW ********* */

  function HandleClickOnRow() {
    if (api) {
      if (ios) {
        if (isShoeConfigActive) {
          let option = window.confirm(
            "For Mobile devices, only one Configurator can be running at the same time. Do you want to open this configurator?"
          );
          if (option === true) {
            sessionStorage.setItem("ConfiguratorToUse", "Bike");
            window.location.reload();
          }
        } else {
          StartAndHidePlayButton(
            api,
            iframeStart,
            setIframeStart,
            loadingViewer,
            setLoadingEffect,
            refStartViewerBtn
          );
        }
      } else {
        StartAndHidePlayButton(
          api,
          iframeStart,
          setIframeStart,
          loadingViewer,
          setLoadingEffect,
          refStartViewerBtn
        );
      }
    }
  }

  const HandleCopyShareLink = () => {
    let inputLink = document.getElementById("shareLink");
    inputLink.disabled = false;

    inputLink.focus();
    inputLink.select();
    document.execCommand("copy");

    inputLink.disabled = true;
  };

  /* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&& */

  const [configJSON, setConfigJSON] = useState({
    config: {
      name: "Master",
      type: "collection",
      id: "94eb3c32-24a0-4590-bde5-5dcbe5ea5dd5", //Bike Front view: e6002290-fcc8-4db6-8099-0ae92be41acb  || Perspective view:  94eb3c32-24a0-4590-bde5-5dcbe5ea5dd5
    },
  });

  return (
    <>
      <div
        className="row"
        ref={refIframeRow}
        onClick={() => HandleClickOnRow()}
        style={{ position: "relative", margin: 0 }}
      >
        {/* TEST */}

        {!iframeStart && (
          <div
            className="section-start-button white_background desktop-ds"
            style={{
              zIndex: "1",
              background: "white",
              display: "flex",
              placeContent: "center",
              alignItems: "center",
            }}
          >
            <img
              // style={{ opacity: 0.7 }}
              className="start-img_bg"
              src={
                window.innerWidth <= 1024
                  ? `${AWS_DOMAIN}bike_objects_v2/iframe_cover/eBike_configurator_screenshot_mobile_03_fv_211105.jpg` //bikeconfig_mobile_800px_fv_v4_211026.gif
                  : `${AWS_DOMAIN}bike_objects_v2/iframe_cover/eBike_configurator_screenshot_desktop_03_fv_211105.jpg`
              } // bikeconfig_desktop_hd_fv_v5_211026 || 1k_config_animation.gif
              alt=""
            />{" "}
            {/* objectFit: 'none'  style={{height: 'auto'}} */}
            {api && (
              <>
                <button className="model-play-button model-play-button_bike">
                  {/* <i
                    className="fa fa-play model-play-icon"
                    aria-hidden="true"
                  ></i>
                  <span className="model-label"></span> */}
                </button>
              </>
            )}
          </div>
        )}

        {/* ---- */}

        {/* VIEWER */}
        <div
          className="col-md-12 col-lg-9 col-movil-styles"
          id="ebikeconfig-viewer-grid"
          ref={refIframeCol}
          onClick={() => HandleClickOnIframe()}
        >
          {/* MOVIL */} {/* !loadingViewer || loadingEffect */}
          {!loadingViewer && (
            <div className="movil-options-grid" ref={refMovilMenu}>
              <button
                ref={refMovilOptionTitle}
                onClick={() => HandleStartMovil()}
                className="option_btn title"
              >
                {!firstClickOnMovilMenu ? (
                  <span style={{ padding: "2px 6px" }}>
                    Build your custom E-Bike
                  </span>
                ) : (
                  <>
                    {actualStepNumber < 6 ? (
                      <img src={plusImg} alt="Plus Icon" />
                    ) : (
                      <img src={backImg} alt="Back Icon" />
                    )}
                  </>
                )}
              </button>

              <div className="config-options" ref={refMovilOptionsGrid}>
                {/* ONE */}
                <div className="optionStart_grid">
                  <button
                    className="option_btn"
                    onClick={() =>
                      HandleNextOrPrevStep(
                        undefined,
                        refColStepOne,
                        true,
                        0,
                        undefined,
                        true,
                        true,
                        actualStepNumber
                      )
                    }
                  >
                    Drivetrain
                  </button>
                  {actualStepNumber >= 1 && (
                    <span className="step_completed">✓</span>
                  )}
                </div>
                <hr />

                {/* TWO */}
                <div className="optionConfig_grid">
                  <button
                    className="option_btn"
                    onClick={() =>
                      HandleNextOrPrevStep(
                        undefined,
                        undefined,
                        true,
                        1,
                        stepOneValue,
                        true,
                        true,
                        actualStepNumber
                      )
                    }
                    disabled={actualStepNumber >= 1 ? false : true}
                  >
                    Frame
                  </button>
                  {actualStepNumber >= 2 && (
                    <span className="step_completed">✓</span>
                  )}
                </div>
                <hr />

                {/* THREE */}
                <div className="optionConfig_grid">
                  <button
                    className="option_btn"
                    onClick={() =>
                      HandleNextOrPrevStep(
                        undefined,
                        refColStepThreeMovil,
                        true,
                        2,
                        undefined,
                        true,
                        true,
                        actualStepNumber
                      )
                    }
                    disabled={actualStepNumber >= 2 ? false : true}
                  >
                    Frame Color
                  </button>
                  {actualStepNumber >= 3 && (
                    <span className="step_completed">✓</span>
                  )}
                </div>
                <hr />

                {/* FOUR */}
                <div className="optionConfig_grid">
                  <button
                    className="option_btn"
                    onClick={() =>
                      HandleNextOrPrevStep(
                        undefined,
                        refColStepFour,
                        true,
                        3,
                        undefined,
                        true,
                        true,
                        actualStepNumber
                      )
                    }
                    disabled={actualStepNumber >= 3 ? false : true}
                  >
                    Fork Color
                  </button>
                  {actualStepNumber >= 4 && (
                    <span className="step_completed">✓</span>
                  )}
                </div>
                <hr />

                {/* FIVE */}
                <div className="optionConfig_grid">
                  <button
                    className="option_btn"
                    onClick={() =>
                      HandleNextOrPrevStep(
                        undefined,
                        refColStepFive,
                        true,
                        4,
                        undefined,
                        true,
                        true,
                        actualStepNumber
                      )
                    }
                    disabled={actualStepNumber >= 4 ? false : true}
                  >
                    Decals
                  </button>
                  {actualStepNumber >= 5 && (
                    <span className="step_completed">✓</span>
                  )}
                </div>
                <hr />

                {/* SIX */}
                <div className="optionConfig_grid">
                  <button
                    className="option_btn"
                    onClick={() =>
                      HandleNextOrPrevStep(
                        undefined,
                        refColStepSix,
                        true,
                        5,
                        undefined,
                        true,
                        true,
                        actualStepNumber
                      )
                    }
                    disabled={actualStepNumber >= 5 ? false : true}
                  >
                    Accessories
                  </button>
                  {actualStepNumber >= 6 && (
                    <span className="step_completed">✓</span>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* SIX */}
          {actualStepNumber >= 6 && (
            <>
              <button
                onClick={() => handleOpen()}
                style={{
                  backgroundImage: `url(${bgShare})`,
                  backgroundRepeat: "no-repeat",
                }}
                className="share_btn"
              >
                Share
              </button>
              {/* </ALL> */}
              {/*<div className="section-ar-buttons_ab">                    
                        <ArButton productName={BIKE_PRODUCT.product_name} arFiles={BIKE_PRODUCT.initial_ar_img} /> 
                    </div>}*/}
              {/* </ALL> */}
            </>
          )}
          {/* </MOVIL> */}
          {viewerIframe}
        </div>
        {/* </VIEWER> */}

        {/* DESKTOP */}
        <div className="col-3 col-configurator-info" ref={refColConfigInfo}>
          {/* ONE */}
          {actualStepNumber >= 1 && (
            <>
              <h4>E-Bike Configuration</h4>

              <div
                onClick={() =>
                  HandleNextOrPrevStep(
                    undefined,
                    refColStepOne,
                    true,
                    0,
                    undefined,
                    true,
                    false,
                    actualStepNumber
                  )
                }
                className=" option_selected_grid"
              >
                <div className="hover_text">Edit</div>
                <div className="title_text">
                  <strong>Drivetrain: </strong>
                  {stepOneValue ===
                  BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
                    ? " Rear Hub"
                    : " Mid Drive"}
                </div>
              </div>
            </>
          )}

          {/* TWO */}
          {actualStepNumber >= 2 && (
            <div
              onClick={() =>
                HandleNextOrPrevStep(
                  undefined,
                  undefined,
                  true,
                  1,
                  stepOneValue,
                  true,
                  false,
                  actualStepNumber
                )
              }
              className=" option_selected_grid"
            >
              <div className="hover_text">Edit</div>
              <div className="title_text">
                <strong>Frame: </strong>
                {stepOneValue ===
                BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
                  ? stepTwoAName
                  : stepTwoBName}
              </div>
            </div>
          )}

          {/* THREE */}
          {actualStepNumber >= 3 && (
            <div
              onClick={() =>
                HandleNextOrPrevStep(
                  undefined,
                  refColStepThree,
                  true,
                  2,
                  undefined,
                  true,
                  false,
                  actualStepNumber
                )
              }
              className=" option_selected_grid"
            >
              <div className="hover_text">Edit</div>
              <div className="title_text">
                <strong>Frame Color:</strong>
                {`${frameColorName}`}
              </div>
            </div>
          )}

          {/* FOUR */}
          {actualStepNumber >= 4 && (
            <div
              onClick={() =>
                HandleNextOrPrevStep(
                  undefined,
                  refColStepFour,
                  true,
                  3,
                  undefined,
                  true,
                  false,
                  actualStepNumber
                )
              }
              className=" option_selected_grid"
            >
              <div className="hover_text">Edit</div>
              <div className="title_text">
                <strong>Fork Color:</strong>
                {`${brushedColorName}`}
              </div>
            </div>
          )}

          {/* FIVE */}
          {actualStepNumber >= 5 && (
            <div
              onClick={() =>
                HandleNextOrPrevStep(
                  undefined,
                  refColStepFive,
                  true,
                  4,
                  undefined,
                  true,
                  false,
                  actualStepNumber
                )
              }
              className=" option_selected_grid"
            >
              <div className="hover_text">Edit</div>
              <div className="title_text">
                <strong>Decals:</strong>
                {`${brandTextureName}`}
              </div>
            </div>
          )}

          {/* SIX */}
          {actualStepNumber >= 6 && (
            <>
              <div className="list-extras">
                <div
                  onClick={() =>
                    HandleNextOrPrevStep(
                      undefined,
                      refColStepSix,
                      true,
                      5,
                      undefined,
                      true,
                      false,
                      actualStepNumber
                    )
                  }
                  className="option_selected_grid list_grid"
                >
                  <div className="hover_text">Edit</div>
                  <div className="title_text list_title">
                    <strong>Accessories:</strong>
                  </div>
                </div>

                <ul>
                  {extrasSelected.mirrors !== "" ||
                  extrasSelected.mudguards !== "" ||
                  extrasSelected.daytime_leds !== "" ? (
                    <>
                      {extrasSelected.mirrors !== "" && (
                        <li>{extrasSelected.mirrors}</li>
                      )}
                      {extrasSelected.mudguards !== "" && (
                        <li>{extrasSelected.mudguards}</li>
                      )}
                      {extrasSelected.daytime_leds !== "" && (
                        <li>{extrasSelected.daytime_leds}</li>
                      )}
                    </>
                  ) : (
                    <>
                      <li>None</li>
                    </>
                  )}
                </ul>
              </div>

              <hr />

              <h3>Total: $3,499</h3>
            </>
          )}
        </div>
        {/* </DESKTOP> */}
      </div>

      {/* DESKTOP */}

      {!loadingViewer && (
        <section className="desktop-iframe-configurator">
          {" "}
          {/* iframeStart */}
          {/*** STEP ONE (MID DRIVE OR REAR HUB) ***/}
          <div className="row row-config" ref={refColStepOne}>
            <div
              className="col-12 col-options"
              style={{
                backgroundImage: `url(${bgConfig})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "105% 100%",
              }}
            >
              {BIKE_PRODUCT.features[0].map((feature) => {
                return (
                  <Fragment key={`${feature.id}-${feature.reference}`}>
                    {feature.model_options.map((option) => {
                      let isSelected = false;

                      if (option.node_instanceID.toString() === stepOneValue) {
                        isSelected = true;

                        setTimeout(() => {
                          document.getElementById(
                            `bike-${feature.reference}-${option.id}`
                          ).checked = true;
                        }, timeDelay);
                      }

                      return (
                        <Fragment key={`${option.id}-${option.node_name}`}>
                          {/* col-2 */}{" "}
                          <div className="circle_option two">
                            <input
                              name={`option_${feature.reference}`}
                              type="radio"
                              value={option.node_instanceID}
                              id={`bike-${feature.reference}-${option.id}`}
                              onClick={(e) =>
                                HandleFeatures(
                                  e,
                                  feature.reference,
                                  option.value,
                                  option.node_name
                                )
                              }
                            />
                            <div className="swatch-element">
                              <label
                                className="option-item"
                                htmlFor={`bike-${feature.reference}-${option.id}`}
                                style={{
                                  backgroundImage: `url(${option.thumbnails})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "100% 100%",
                                }}
                              ></label>
                            </div>
                            <div className="title_object mt-2">
                              <h3
                                style={{
                                  color: `${
                                    isSelected ? "var(--green-fresco)" : ""
                                  }`,
                                }}
                              >
                                {option.value}
                              </h3>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}

                    <span className="config-object_name">{feature.name}</span>
                  </Fragment>
                );
              })}

              {stepOneValue !== "0" && (
                <span
                  className="col-1 config-arrow-absolute next"
                  onClick={() =>
                    HandleNextOrPrevStep(
                      refColStepOne,
                      undefined,
                      false,
                      1,
                      stepOneValue
                    )
                  }
                >
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </span>
              )}
            </div>

            <div className="col-12 movil-steps movil-ds mt-4">
              {stepOneValue !== "0" && (
                <button className="invisible">Back</button>
              )}

              <div className="movil-steps_title">
                <h3>Drivetrain</h3>
              </div>

              {stepOneValue !== "0" && (
                <button
                  onClick={() =>
                    HandleNextOrPrevStep(
                      refColStepOne,
                      undefined,
                      false,
                      1,
                      stepOneValue,
                      false,
                      true
                    )
                  }
                >
                  {" "}
                  {/* false, true, actualStepNumber  */}
                  Next
                </button>
              )}
            </div>
          </div>
          {/******** STEP 2 (A) (battery) *********/}
          <div className="row row-config step-next" ref={refColStepTwoA}>
            <div
              className="col-12 col-options"
              style={{
                backgroundImage: `url(${bgConfig})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "105% 100%",
              }}
            >
              <span
                className="col-1 config-arrow-absolute back"
                onClick={() =>
                  HandleNextOrPrevStep(refColStepTwoA, refColStepOne, true, 0)
                }
              >
                <i className="fa fa-angle-left" aria-hidden="true"></i>
              </span>

              {BIKE_PRODUCT.features[1].map((feature) => {
                return (
                  <Fragment key={`${feature.id}-${feature.reference}`}>
                    {feature.model_options.map((option) => {
                      let isSelected = false;

                      if (option.value === stepTwoAName) {
                        isSelected = true;

                        setTimeout(() => {
                          document.getElementById(
                            `bike-${feature.reference}-${option.id}`
                          ).checked = true;
                        }, timeDelay);
                      }

                      return (
                        <Fragment key={`${option.id}-${option.node_name}`}>
                          {/* col-2 */}{" "}
                          <div className="circle_option two">
                            <input
                              name={`option_${feature.reference}`}
                              type="radio"
                              value={option.node_instanceID}
                              id={`bike-${feature.reference}-${option.id}`}
                              onClick={(e) =>
                                HandleFeatures(
                                  e,
                                  feature.reference,
                                  option.value,
                                  option.node_name
                                )
                              }
                            />
                            <div className="swatch-element">
                              <label
                                className="option-item"
                                htmlFor={`bike-${feature.reference}-${option.id}`}
                                style={{
                                  backgroundImage: `url(${option.thumbnails})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "100% 100%",
                                }}
                              ></label>
                            </div>
                            <div className="title_object mt-2">
                              <h3
                                style={{
                                  color: `${
                                    isSelected ? "var(--green-fresco)" : ""
                                  }`,
                                }}
                              >
                                {option.value}
                              </h3>
                            </div>
                          </div>{" "}
                          {/*   */}
                        </Fragment>
                      );
                    })}

                    <span className="config-object_name">{feature.name}</span>

                    {/* BEFORE */}
                  </Fragment>
                );
              })}

              <span
                className="col-1 config-arrow-absolute next"
                onClick={() =>
                  HandleNextOrPrevStep(
                    refColStepTwoA,
                    refColStepThree,
                    false,
                    2
                  )
                }
              >
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </span>
            </div>

            <div className="col-12 movil-steps movil-ds mt-4">
              <button
                onClick={() =>
                  HandleNextOrPrevStep(
                    refColStepTwoA,
                    refColStepOne,
                    true,
                    0,
                    undefined,
                    false,
                    true
                  )
                }
              >
                Back
              </button>
              <div className="movil-steps_title">
                <h3>Battery</h3>
              </div>
              <button
                onClick={() =>
                  HandleNextOrPrevStep(
                    refColStepTwoA,
                    refColStepThreeMovil,
                    false,
                    2,
                    undefined,
                    false,
                    true
                  )
                }
              >
                Next
              </button>
            </div>
          </div>
          {/******** STEP 2 (B) (toptube) *********/}
          <div className="row row-config step-next" ref={refColStepTwoB}>
            <div
              className="col-12 col-options"
              style={{
                backgroundImage: `url(${bgConfig})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "105% 100%",
              }}
            >
              <span
                className="col-1 config-arrow-absolute back"
                onClick={() =>
                  HandleNextOrPrevStep(refColStepTwoB, refColStepOne, true, 0)
                }
              >
                <i className="fa fa-angle-left" aria-hidden="true"></i>
              </span>

              {BIKE_PRODUCT.features[2].map((feature) => {
                return (
                  <Fragment key={`${feature.id}-${feature.reference}`}>
                    {feature.model_options.map((option) => {
                      let isSelected = false;

                      if (option.value === stepTwoBName) {
                        isSelected = true;

                        setTimeout(() => {
                          document.getElementById(
                            `bike-${feature.reference}-${option.id}`
                          ).checked = true;
                        }, timeDelay);
                      }

                      return (
                        <Fragment key={`${option.id}-${option.node_name}`}>
                          {/* col-2 */}{" "}
                          <div className="circle_option">
                            <input
                              name={`option_${feature.reference}`}
                              type="radio"
                              value={option.node_instanceID}
                              id={`bike-${feature.reference}-${option.id}`}
                              onClick={(e) =>
                                HandleFeatures(
                                  e,
                                  feature.reference,
                                  option.value,
                                  option.node_name
                                )
                              }
                            />{" "}
                            {/* HandleSecondStep */}
                            <div className="swatch-element">
                              <label
                                className="option-item"
                                htmlFor={`bike-${feature.reference}-${option.id}`}
                                style={{
                                  backgroundImage: `url(${option.thumbnails})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "100% 100%",
                                }}
                              ></label>
                            </div>
                            <div className="title_object mt-2">
                              <h3
                                style={{
                                  color: `${
                                    isSelected ? "var(--green-fresco)" : ""
                                  }`,
                                }}
                              >
                                {option.value}
                              </h3>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}

                    <span className="config-object_name">{feature.name}</span>

                    {/* before */}
                  </Fragment>
                );
              })}

              <span
                className="col-1 config-arrow-absolute next"
                onClick={() =>
                  HandleNextOrPrevStep(
                    refColStepTwoB,
                    refColStepThree,
                    false,
                    2
                  )
                }
              >
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </span>
            </div>

            <div className="col-12 movil-steps movil-ds mt-3">
              <button
                onClick={() =>
                  HandleNextOrPrevStep(
                    refColStepTwoB,
                    refColStepOne,
                    true,
                    0,
                    undefined,
                    false,
                    true
                  )
                }
              >
                Back
              </button>
              <div className="movil-steps_title">
                <h3>Top Tube</h3>
              </div>
              <button
                onClick={() =>
                  HandleNextOrPrevStep(
                    refColStepTwoB,
                    refColStepThreeMovil,
                    false,
                    2,
                    undefined,
                    false,
                    true
                  )
                }
              >
                Next
              </button>
            </div>
          </div>
          {/****** STEP THREE (FRAME COLORS) ******/}
          <div className="row row-config step-next" ref={refColStepThree}>
            <div
              className="col-12 col-options desktop-ds"
              style={{
                backgroundImage: `url(${bgConfig})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "105% 100%",
              }}
            >
              <span
                className="col-1 config-arrow-absolute back"
                onClick={() =>
                  HandleNextOrPrevStep(
                    refColStepThree,
                    undefined,
                    true,
                    1,
                    stepOneValue
                  )
                }
              >
                <i className="fa fa-angle-left" aria-hidden="true"></i>
              </span>

              {BIKE_PRODUCT.colors.color_primary.map((color) => {
                let isSelected = false;

                if (color.reference === frameColorName) {
                  isSelected = true;

                  setTimeout(() => {
                    document.getElementById(
                      `Bike-color-primary-${color.id}`
                    ).checked = true;
                  }, timeDelay);
                }

                return (
                  <Fragment key={`${color.id}-${color.reference}-desktop`}>
                    <div className="circle_option_small">
                      {" "}
                      {/* col-1  */}
                      <input
                        onClick={(e) =>
                          HandleColorOnClick(
                            true,
                            `${color.color}${
                              stepOneValue ===
                              BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
                                ? "rear-hub"
                                : "mid-drive"
                            }/pack_b/${color.reference}.png`,
                            BIKE_PRODUCT.material_primary,
                            e,
                            color.reference
                          )
                        }
                        name="color_primary"
                        type="radio"
                        value={color.value}
                        id={`Bike-color-primary-${color.id}`}
                      />
                      <div className="swatch-element">
                        <label
                          title={color.value}
                          className="color-item-bike"
                          htmlFor={`Bike-color-primary-${color.id}`}
                        >
                          <img
                            src={`${color.thumbnails}${
                              stepOneValue ===
                              BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
                                ? "rear-hub"
                                : "mid-drive"
                            }/${stepTwoUrlReference}/${color.reference}.png`}
                            alt={color.value}
                          />
                        </label>
                      </div>
                      <div className="title_object mt-2">
                        <h3
                          style={{
                            color: `${isSelected ? "var(--green-fresco)" : ""}`,
                          }}
                        >
                          {color.value}
                        </h3>
                      </div>
                    </div>
                  </Fragment>
                );
              })}

              <span className="config-object_name">Frame Color</span>

              {frameColorName && (
                <span
                  className="col-1 config-arrow-absolute next"
                  onClick={() =>
                    HandleNextOrPrevStep(
                      refColStepThree,
                      refColStepFour,
                      false,
                      3
                    )
                  }
                >
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </span>
              )}
            </div>
          </div>
          {/* <MOVIL> */}
          <div
            className="row row-config step-next mt-5"
            ref={refColStepThreeMovil}
          >
            <div className="col-12 col-options col-options-movil_color movil-ds">
              {BIKE_PRODUCT.colors.color_primary.map((color) => {
                let isSelected = false;

                if (color.reference === frameColorName) {
                  isSelected = true;

                  setTimeout(() => {
                    document.getElementById(
                      `Bike-color-primary-${color.id}-movil`
                    ).checked = true;
                  }, timeDelay);
                }

                //TEST
                if (color.id > 3) {
                  return (
                    <Fragment
                      key={`${color.id}-${color.reference}-movilInvisible`}
                    >
                      {" "}
                    </Fragment>
                  );
                }

                return (
                  <Fragment key={`${color.id}-${color.reference}-movil`}>
                    <div className="circle_option_small">
                      <input
                        onClick={(e) =>
                          HandleColorOnClick(
                            true,
                            `${color.color}${
                              stepOneValue ===
                              BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
                                ? "rear-hub"
                                : "mid-drive"
                            }/pack_b/${color.reference}.jpg`,
                            BIKE_PRODUCT.material_primary,
                            e,
                            color.reference
                          )
                        }
                        name="color_primary_movil"
                        type="radio"
                        value={color.value}
                        id={`Bike-color-primary-${color.id}-movil`}
                      />
                      <div className="swatch-element">
                        <div className="title_object mt-2">
                          <h3
                            style={{
                              color: `${
                                isSelected ? "var(--green-fresco)" : ""
                              }`,
                            }}
                          >
                            {color.value}
                          </h3>
                        </div>
                        <label
                          title={color.value}
                          className="color-item-bike"
                          htmlFor={`Bike-color-primary-${color.id}-movil`}
                        >
                          <img
                            src={`${color.thumbnails}${
                              stepOneValue ===
                              BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
                                ? "rear-hub"
                                : "mid-drive"
                            }/${stepTwoUrlReference}/${color.reference}.png`}
                            alt={color.value}
                          />
                        </label>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>

            <div className="col-12 col-options col-options-movil_color movil-ds">
              {BIKE_PRODUCT.colors.color_primary.map((color) => {
                let isSelected = false;

                if (color.reference === frameColorName) {
                  isSelected = true;

                  setTimeout(() => {
                    document.getElementById(
                      `Bike-color-primary-${color.id}-movil`
                    ).checked = true;
                  }, timeDelay);
                }

                //TEST
                if (color.id <= 3) {
                  return (
                    <Fragment
                      key={`${color.id}-${color.reference}-movilInvisible`}
                    >
                      {" "}
                    </Fragment>
                  );
                }

                return (
                  <Fragment key={`${color.id}-${color.reference}-movil`}>
                    <div className="circle_option_small">
                      <input
                        onClick={(e) =>
                          HandleColorOnClick(
                            true,
                            `${color.color}${
                              stepOneValue ===
                              BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
                                ? "rear-hub"
                                : "mid-drive"
                            }/pack_b/${color.reference}.jpg`,
                            BIKE_PRODUCT.material_primary,
                            e,
                            color.reference
                          )
                        }
                        name="color_primary_movil"
                        type="radio"
                        value={color.value}
                        id={`Bike-color-primary-${color.id}-movil`}
                      />
                      <div className="swatch-element">
                        <div className="title_object mt-2">
                          <h3
                            style={{
                              color: `${
                                isSelected ? "var(--green-fresco)" : ""
                              }`,
                            }}
                          >
                            {color.value}
                          </h3>
                        </div>
                        <label
                          title={color.value}
                          className="color-item-bike"
                          htmlFor={`Bike-color-primary-${color.id}-movil`}
                        >
                          <img
                            src={`${color.thumbnails}${
                              stepOneValue ===
                              BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
                                ? "rear-hub"
                                : "mid-drive"
                            }/${stepTwoUrlReference}/${color.reference}.png`}
                            alt={color.value}
                          />
                        </label>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>

            <div className="col-12 movil-steps movil-ds mt-3">
              <button
                onClick={() =>
                  HandleNextOrPrevStep(
                    refColStepThreeMovil,
                    undefined,
                    true,
                    1,
                    stepOneValue,
                    false,
                    true
                  )
                }
              >
                Back
              </button>
              <div className="movil-steps_title">
                <h3>Frame Color</h3>
              </div>
              <button
                onClick={() =>
                  HandleNextOrPrevStep(
                    refColStepThreeMovil,
                    refColStepFour,
                    false,
                    3,
                    undefined,
                    false,
                    true
                  )
                }
              >
                Next
              </button>
            </div>
          </div>
          {/*** STEP FOUR (COLOR Secondary) (Copper Tube) (FORK) ***/}
          <div className="row row-config step-next" ref={refColStepFour}>
            <div
              className="col-12 col-options"
              style={{
                backgroundImage: `url(${bgConfig})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "105% 100%",
              }}
            >
              <span
                className="col-1 config-arrow-absolute back"
                onClick={() =>
                  HandleNextOrPrevStep(refColStepFour, refColStepThree, true, 2)
                }
              >
                <i className="fa fa-angle-left" aria-hidden="true"></i>
              </span>

              {BIKE_PRODUCT.colors.color_secondary.map((color, colorIndex) => {
                let isSelected = false;

                if (color.value === brushedColorName) {
                  isSelected = true;

                  setTimeout(() => {
                    document.getElementById(
                      `Bike-color-secondary-${color.id}`
                    ).checked = true;
                  }, timeDelay);
                }

                return (
                  <Fragment
                    key={`${color.id}-${color.reference}-${colorIndex}`}
                  >
                    {/* col-2 */}{" "}
                    <div className="circle_option two">
                      <input
                        onClick={(e) =>
                          HandleColorOnClick(
                            false,
                            color.color,
                            BIKE_PRODUCT.material_secondary,
                            e
                          )
                        }
                        name="color_secondary"
                        type="radio"
                        value={color.value}
                        id={`Bike-color-secondary-${color.id}`}
                      />
                      <div className="swatch-element">
                        <label
                          title={color.value}
                          className="color-item-bike"
                          htmlFor={`Bike-color-secondary-${color.id}`}
                          style={{
                            backgroundImage: `url(${
                              color.thumbnails + frameColorName
                            }.png)`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100% 100%",
                          }}
                        ></label>
                      </div>
                      <div className="title_object mt-2">
                        <h3
                          style={{
                            color: `${isSelected ? "var(--green-fresco)" : ""}`,
                          }}
                        >
                          {color.value}
                        </h3>
                      </div>
                    </div>
                  </Fragment>
                );
              })}

              <span className="config-object_name">Fork Color</span>

              {brushedColorName && (
                <span
                  className="col-1 config-arrow-absolute next"
                  onClick={() =>
                    HandleNextOrPrevStep(
                      refColStepFour,
                      refColStepFive,
                      false,
                      4
                    )
                  }
                >
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </span>
              )}
            </div>

            <div className="col-12 movil-steps movil-ds mt-4">
              <button
                onClick={() =>
                  HandleNextOrPrevStep(
                    refColStepFour,
                    refColStepThreeMovil,
                    true,
                    2,
                    undefined,
                    false,
                    true
                  )
                }
              >
                Back
              </button>
              <div className="movil-steps_title">
                <h3>Fork Color</h3>
              </div>
              <button
                onClick={() =>
                  HandleNextOrPrevStep(
                    refColStepFour,
                    refColStepFive,
                    false,
                    4,
                    undefined,
                    false,
                    true
                  )
                }
              >
                Next
              </button>
            </div>
          </div>
          {/***** STEP FIVE (Textures Packs, Decals) *****/}
          <div className="row row-config step-next" ref={refColStepFive}>
            <div
              className="col-12 col-options"
              style={{
                backgroundImage: `url(${bgConfig})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "105% 100%",
              }}
            >
              <span
                className="col-1 config-arrow-absolute back"
                onClick={() =>
                  HandleNextOrPrevStep(refColStepFive, refColStepFour, true, 3)
                }
              >
                <i className="fa fa-angle-left" aria-hidden="true"></i>
              </span>

              {BIKE_PRODUCT.colors.color_tertiary.map((color, colorIndex) => {
                let isSelected = false;

                if (color.value === brandTextureName) {
                  isSelected = true;

                  setTimeout(() => {
                    document.getElementById(
                      `Bike-color-tertiary-${color.id}`
                    ).checked = true;
                  }, timeDelay);
                }

                return (
                  <Fragment
                    key={`${color.id}-${color.reference}-${colorIndex}`}
                  >
                    {/* col-2 */}{" "}
                    <div className="circle_option">
                      {" "}
                      {/* col-1 */}
                      <input
                        onClick={(e) =>
                          HandleTextureOnClick(
                            true,
                            `${color.color}${
                              stepOneValue ===
                              BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
                                ? "rear-hub"
                                : "mid-drive"
                            }/${color.reference}/`,
                            BIKE_PRODUCT.material_primary,
                            e,
                            color.reference
                          )
                        }
                        name="color_tertiary"
                        type="radio"
                        value={color.value}
                        id={`Bike-color-tertiary-${color.id}`}
                      />
                      <div className="swatch-element">
                        <label
                          title={color.value}
                          className="color-item-bike"
                          htmlFor={`Bike-color-tertiary-${color.id}`}
                          style={{
                            backgroundImage: `url(${color.thumbnails}${
                              stepOneValue ===
                              BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
                                ? "rear-hub"
                                : "mid-drive"
                            }/${color.reference}/${frameColorName}.png)`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100% 100%",
                          }}
                        ></label>
                      </div>
                      <div className="title_object mt-2">
                        <h3
                          style={{
                            color: `${isSelected ? "var(--green-fresco)" : ""}`,
                          }}
                        >
                          {color.value}
                        </h3>
                      </div>
                    </div>
                  </Fragment>
                );
              })}

              {/* WITHOT DETAILS url(${color.thumbnails + frameColorName}.png) */}

              <span className="config-object_name">Decals</span>

              {brandTextureName && (
                <span
                  className="col-1 config-arrow-absolute next"
                  onClick={() =>
                    HandleNextOrPrevStep(
                      refColStepFive,
                      refColStepSix,
                      false,
                      5
                    )
                  }
                >
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </span>
              )}
            </div>

            <div className="col-12 movil-steps movil-ds mt-3">
              <button
                onClick={() =>
                  HandleNextOrPrevStep(
                    refColStepFive,
                    refColStepFour,
                    true,
                    3,
                    undefined,
                    false,
                    true
                  )
                }
              >
                Back
              </button>
              <div className="movil-steps_title">
                <h3>Decals</h3>
              </div>
              <button
                onClick={() =>
                  HandleNextOrPrevStep(
                    refColStepFive,
                    refColStepSix,
                    false,
                    5,
                    undefined,
                    false,
                    true
                  )
                }
              >
                Next
              </button>
            </div>
          </div>
          {/************ STEP Six (NODES) **************/}
          <div className="row row-config step-next" ref={refColStepSix}>
            <div
              className="col-12 col-options"
              style={{
                backgroundImage: `url(${bgConfig})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "105% 100%",
              }}
            >
              <span
                className="col-1 config-arrow-absolute back"
                onClick={() =>
                  HandleNextOrPrevStep(refColStepSix, refColStepFive, true, 4)
                }
              >
                <i className="fa fa-angle-left" aria-hidden="true"></i>
              </span>

              {BIKE_PRODUCT.extras.map((extra, extraIndex) => {
                let isSelected = false;

                if (extrasSelected.mirrors === extra.value) {
                  isSelected = true;
                } else if (extrasSelected.mudguards === extra.value) {
                  isSelected = true;
                } else if (extrasSelected.daytime_leds === extra.value) {
                  isSelected = true;
                }

                if (isSelected) {
                  setTimeout(() => {
                    document.getElementById(
                      `extra-${extra.reference}-${extra.id}`
                    ).checked = true;
                  }, timeDelay);
                }

                return (
                  <Fragment
                    key={`${extra.id}-${extra.reference}-${extraIndex}`}
                  >
                    {/* col-2 */}{" "}
                    <div
                      className="circle_option"
                      id={`circle-extra-${extra.reference}`}
                    >
                      <input
                        name={`bike_${extra.reference}`}
                        type="checkbox"
                        value={extra.node_instanceID}
                        id={`extra-${extra.reference}-${extra.id}`}
                        onClick={(e) =>
                          HandleOnChangeCheckBox(
                            e,
                            extra.value,
                            `circle-extra-${extra.reference}`,
                            `label-extra-${extra.reference}`,
                            `title-extra-${extra.reference}`
                          )
                        }
                      />
                      <div className="swatch-element">
                        <label
                          id={`label-extra-${extra.reference}`}
                          className="option-item"
                          htmlFor={`extra-${extra.reference}-${extra.id}`}
                          style={{
                            backgroundImage: `url(${extra.thumbnails})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100% 100%",
                          }}
                        ></label>
                      </div>
                      <div className="title_object mt-2">
                        <h3 id={`title-extra-${extra.reference}`}>
                          {extra.value}
                        </h3>
                      </div>
                    </div>
                  </Fragment>
                ); //{color: `${isSelected ? 'var(--green-fresco)' : ''}`}
              })}

              <span className="config-object_name">Accessories</span>

              {brandTextureName && (
                <span
                  className="col-1 config-arrow-absolute next"
                  onClick={() =>
                    HandleNextOrPrevStep(
                      refColStepSix,
                      refColStepSeven,
                      false,
                      6
                    )
                  }
                >
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </span>
              )}
            </div>

            <div className="col-12 movil-steps movil-ds mt-3">
              <button
                onClick={() =>
                  HandleNextOrPrevStep(
                    refColStepSix,
                    refColStepFive,
                    true,
                    4,
                    undefined,
                    false,
                    true
                  )
                }
              >
                Back
              </button>
              <div className="movil-steps_title">
                <h3>Accessories</h3>
              </div>
              <button
                onClick={() =>
                  HandleNextOrPrevStep(
                    refColStepSix,
                    refColStepSevenMovil,
                    false,
                    6,
                    undefined,
                    false,
                    true
                  )
                }
              >
                Next
              </button>
            </div>
          </div>
          {/*********** STEP Seven (Finish) ***********/}
          <div className="row row-config step-finish" ref={refColStepSeven}>
            <div
              className="col-12 col-options desktop-ds"
              style={{
                backgroundImage: `url(${bgConfig})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "105% 100%",
              }}
            >
              <span
                className="col-1 config-arrow-absolute back"
                onClick={() =>
                  HandleNextOrPrevStep(refColStepSeven, refColStepSix, true, 5)
                }
              >
                <i className="fa fa-angle-left" aria-hidden="true"></i>
              </span>

              <div className="col-3 grid-btn">
                <button
                  onClick={() => handleOpen()}
                  className="btn_fresco_rounded"
                >
                  Share
                </button>
              </div>

              <div className="col-3 grid-btn">
                <button
                  onClick={() => handleOpen()}
                  className="btn_fresco_rounded"
                >
                  Place Order
                </button>
              </div>
            </div>
          </div>
          {/* <MOVIL> */}
          <div
            className="row row-config step-finish"
            ref={refColStepSevenMovil}
          >
            <div className="col-12 setup-movil_title movil-block-ds">
              <h3>E-Bike Configuration</h3>
            </div>

            <div
              className="col-6 setup-movil_info movil-block-ds"
              style={{ borderRight: "1px solid #59616F" }}
            >
              {/* ONE */}
              {actualStepNumber >= 1 && (
                <div className="option_selected_grid_movil">
                  <div className="option_name">
                    <strong>Drivetrain: </strong>
                  </div>
                  <div className="option_value">
                    <span>
                      {stepOneValue ===
                      BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
                        ? " Rear Hub"
                        : " Mid Drive"}
                    </span>
                  </div>
                </div>
              )}

              {/* TWO */}
              {actualStepNumber >= 2 && (
                <div className="option_selected_grid_movil">
                  <div className="option_name">
                    <strong>Frame: </strong>
                  </div>
                  <div className="option_value">
                    <span>
                      {stepOneValue ===
                      BIKE_PRODUCT.features[0][0].model_options[0].node_instanceID.toString()
                        ? stepTwoAName
                        : stepTwoBName}
                    </span>
                  </div>
                </div>
              )}

              {/* THREE */}
              {actualStepNumber >= 3 && (
                <div className="option_selected_grid_movil">
                  <div className="option_name">
                    <strong>Frame Color: </strong>
                  </div>
                  <div className="option_value">
                    <span>{`${frameColorName}`}</span>
                  </div>
                </div>
              )}

              {/* FOUR */}
              {actualStepNumber >= 4 && (
                <div className="option_selected_grid_movil">
                  <div className="option_name">
                    <strong>Fork Color:</strong>
                  </div>
                  <div className="option_value">
                    <span>{`${brushedColorName}`}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="col-6 setup-movil_info movil-block-ds">
              {/* FIVE */}
              {actualStepNumber >= 5 && (
                <div className="option_selected_grid_movil">
                  <div className="option_name">
                    <strong>Decals:</strong>
                  </div>
                  <div className="option_value">
                    <span>{`${brandTextureName}`}</span>
                  </div>
                </div>
              )}

              {/* SIX */}
              {actualStepNumber >= 6 && (
                <div className="option_selected_grid_movil">
                  <div className="option_name">
                    <strong>Accessories:</strong>
                  </div>

                  <ul>
                    {extrasSelected.mirrors !== "" ||
                    extrasSelected.mudguards !== "" ||
                    extrasSelected.daytime_leds !== "" ? (
                      <>
                        {extrasSelected.mirrors !== "" && (
                          <li>{extrasSelected.mirrors}</li>
                        )}
                        {extrasSelected.mudguards !== "" && (
                          <li>{extrasSelected.mudguards}</li>
                        )}
                        {extrasSelected.daytime_leds !== "" && (
                          <li>{extrasSelected.daytime_leds}</li>
                        )}
                      </>
                    ) : (
                      <>
                        <li>None</li>
                      </>
                    )}
                  </ul>
                </div>
              )}
            </div>

            <div className="col-12 setup-movil_finish movil-ds">
              <div className="price">
                <h3>Total: $3,499</h3>
              </div>

              <button onClick={() => handleOpen()}>Place Order</button>
            </div>
          </div>
        </section>
      )}

      {showModal && (
        <Modal onClose={handleClose}>
          <section className="thanks">
            <h3 className="thanks__h3">
              Thank you for trying our 3D configurator, can we help you build
              yours?
            </h3>
            <p className="thanks__p">
              <a href="https://www.fresco-design.com/contact">
                Let’s discuss how Fresco can grow your eCommerce business.
              </a>{" "}
              <br />
            </p>
            <h3 className="thanks__h3">
              With the following link you will can to share your bike
              personalized
            </h3>
            <div className="share__section">
              <input
                type="text"
                className=""
                id="shareLink"
                defaultValue={urlForToShare}
                disabled={true}
              />
              <br className="movilxs-only" />
              <button onClick={() => HandleCopyShareLink()}>
                <img src={copyIcon} alt="Copy Icon" />
              </button>
            </div>
          </section>
        </Modal>
      )}
    </>
  );
}
