import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';

//PAGE STYLES 
import './styles.css';

//DATA
//import { SLIDERS_3DMODEL_STEPS } from '../../services/data';

//COMPONENTS
import Navbar from '../../components/Navbar';
//import HouseLighterModel from '../../components/HouseLighterModel';
//import Carousel from '../../components/Carousel';
import Footer from '../../components/Footer';
import FrescoWidget from '../../components/FrescoWidget';
//import GoUpBtn from '../../components/GoUpBtn';

//IMAGES
//import vrCar from '../../images/content/vr_car.png';

export default function Ecommerce3D(){ 

    //const refIframeContainerSpoke = useRef(null);
    const refGenericViewerContainer = useRef(null); 

   return(<>

    <Helmet>
        <title>Outer Demo | Fresco Design</title>
        <meta name="description" content="Outer Demo model" /> 
        {/* opengraph protocol */}
        <meta property="og:description" content="Outer Demo model" /> 
        <meta property="og:title" content="Outer Demo | Fresco Design" />
        <meta property="og:url" content="https://fresco-augmented-reality.com/outer_demo" />
        <link rel="canonical" href="https://fresco-augmented-reality.com/outer_demo" />
        <link rel="alternate" href="https://www.fresco-design.com.ar/outer_demo" hreflang="es-AR" />
        <link rel="alternate" href="https://www.fresco-design.com/outer_demo" />
    </Helmet> 

    <Navbar noShowOptions={true} />

        <div className="container-fluid container_3decommerce_iframe" ref={refGenericViewerContainer}> 
            <FrescoWidget elementID="outer_demo" frescoKey="69413f55d83f4df2885210ef55821ba8" />     
        </div>

        <div className="container container_3decommerce">

            <section className="section_contact_3decommerce mt-5 mb-3">
                <div className="row">
                    <div className="col-12">
                       <p>                     
                            Fresco Immersive Commerce leverages AR to create online experiences for consumers to discover and explore your products.
                       </p>
                    </div> 
                    <div className="col-12">
                        <p>
                            <a href="https://www.fresco-design.com/contact">
                                Let’s discuss how Fresco can grow your eCommerce business
                                <i className="fa fa-angle-right fa-lg" aria-hidden="true"></i>
                            </a>
                        </p>
                    </div>
                </div>  
            </section>
             
        </div> 
        
        <Footer />
         
   </>)

}

