import React, { useRef, useEffect } from "react";
import "./styles.css";

//HELPERS
//import { CutTextWithDots } from '../../helpers/index';

//IMAGES
import iconQr from "../../images/icons/guide_icon_qr.png";
import iconFrescoQr from "../../images/icons/fresco_icon_qr.png";

//DATA
//import { AWS_DOMAIN } from '../../services/data';

export default function Qr({ qrPageToRedirect, actualPage }) {
  const refQrGrid = useRef(null);

  /* console.log({
    qrPageToRedirect: qrPageToRedirect,
    actualPage: actualPage
  }); */

  useEffect(() => {
    new window.QRCode(refQrGrid.current, {
      text: `${qrPageToRedirect}`,
      width: 350,
      height: 350,
      colorDark: "#57616e",
      colorLight: "#ffffff",
    });
  }, [qrPageToRedirect]);

  return (
    <div className="main-modal text-center">
      <p>Augmented Reality is only available on mobile or tablet devices</p>

      <div className="qr-section">
        <div className="qr-img" ref={refQrGrid}>
          <figure className="logo-grid">
            <img src={iconFrescoQr} alt="Fresco Design Logo on QR" />
          </figure>
        </div>

        <img className="icon-qr" src={iconQr} alt="Fresco Design icon Qr" />
      </div>

      <small className="ligtherText">
        Scan this QR code to open the model in your device
      </small>
      <p>
        Or, Open this link with your mobile: <br />
        <a
          href={`https://www.fresco-design.com/${actualPage}`}
          className="url-link"
        >
          {`https://www.fresco-design.com/${actualPage}`}
        </a>
      </p>
    </div>
  );
}
