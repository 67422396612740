import React, { useEffect } from 'react';
import './styles.css';

export default function FrescoWidget({ elementID, frescoKey }) {

    const snippetUrl = `https://fresco-augmented-reality.com/fresco.js?t=${frescoKey}`;

    useEffect(() => {
        const s = document.createElement('script');
        s.setAttribute('src', snippetUrl);
        s.setAttribute('data-fresco', elementID);
        s.async = true;
        document.body.appendChild(s);
    }, [snippetUrl, elementID]);

    return (<>
            
        <div className="row">
            {/* VIEWER */} 
            <div className="col-12">
                <section id={elementID} className="section-iframe" />
            </div>  
            {/* </VIEWER> */}
        </div>

    </>)
}