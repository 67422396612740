import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';

//PAGE STYLES 
import '../HouseplantLighter/styles.css';

//COMPONENTS
import Navbar from '../../components/Navbar';
//import FrescoWidget from '../../components/FrescoWidget';
import Footer from '../../components/Footer';
import SwandooMarieModel from '../../components/SwandooMarieModel';

function SwandooMarie() {

    const refViewerContainer = useRef(null); 

    return (<>
        <Helmet>
            <title>Swandoo Marie | Fresco Design</title>
            <meta name="description" content="Swandoo Marie Model" /> 
            {/* opengraph protocol */}
            <meta property="og:description" content="Swandoo Marie Model" /> 
            <meta property="og:title" content="Swandoo Marie | Fresco Design" />
            <meta property="og:url" content="https://fresco-augmented-reality.com/Swandoo_marie" />
            <link rel="canonical" href="https://fresco-augmented-reality.com/Swandoo_marie" />
            <link rel="alternate" href="https://www.fresco-design.com.ar/Swandoo_marie" hreflang="es-AR" />
            <link rel="alternate" href="https://www.fresco-design.com/Swandoo_marie" />
        </Helmet>

        <Navbar noShowOptions={true} />

        <div className="container-fluid container_3decommerce_iframe" ref={refViewerContainer}> 
            {/* <FrescoWidget elementID="swandoo-marie-model" frescoKey="43612f291c204f30aba50f6492cc4ba5" />  */}
            <SwandooMarieModel refViewerContainer={refViewerContainer} />  
        </div>

        <div className="container container_3decommerce">

            <section className="section_contact_3decommerce mt-5 mb-3">
                <div className="row">
                    <div className="col-12">
                       <p>                     
                            Fresco Immersive Commerce leverages AR to create online experiences for consumers to discover and explore your products.
                       </p>
                    </div> 
                    <div className="col-12">
                        <p>
                            <a href="https://www.fresco-design.com/contact">
                                Let’s discuss how Fresco can grow your eCommerce business
                                <i className="fa fa-angle-right fa-lg" aria-hidden="true"></i>
                            </a>
                        </p>
                    </div>
                </div>  
            </section>
             
        </div> 

        <Footer />

    </>)
}

export default SwandooMarie;
