import React from "react";
import "./styles.css";

//IMAGES
import arrowLeftDesktopDefault from "../../images/icons/arrow_left.png";
import arrowRightDesktopDefault from "../../images/icons/arrow_right.png";

import arrowLeftMobileDefault from "../../images/icons/arrow_left_movil.png";
import arrowRightMobileDefault from "../../images/icons/arrow_right_movil.png";

export default function CarouselViewer({
  id,
  data,
  sliderActiveNumber = 1,
  height,
  showArrows = true,
  showIndicators = true,
  leftArrowDesktop = arrowLeftDesktopDefault,
  rightArrowDesktop = arrowRightDesktopDefault,
  leftArrowMobile = arrowLeftMobileDefault,
  rightArrowMobile = arrowRightMobileDefault,
  carouselItemClassName,
}) {
  return (
    <section
      id={id}
      className="carousel slide"
      data-ride="false"
      data-pause="false"
      data-touch="false"
      style={{ height }}
    >
      {/* === INDICATORS === */}
      {showIndicators && (
        <ol className="carousel-indicators">
          {data.map((slider, index) => {
            let isActive = slider.id === sliderActiveNumber ? "active" : "";

            return (
              <li
                key={slider.id}
                data-target={`#${id}`}
                data-slide-to={index}
                className={isActive}
              ></li>
            );
          })}
        </ol>
      )}

      {/* === ITEMS === */}
      <div className="carousel-inner">
        {data.map((slider) => {
          let isActive = slider.id === sliderActiveNumber ? "active" : "";

          return (
            <div
              key={slider.id}
              className={`carousel-item carousel-item-dynamic ${isActive} ${
                carouselItemClassName ? carouselItemClassName : ""
              }`}
              style={{
                border: `${slider.border ? "1px solid lightgray" : ""}`,
              }}
            >
              {slider.children}
            </div>
          );
        })}
      </div>
      {/* === ARROWS === */}
      {showArrows && (
        <>
          <a
            className="carousel-control-prev carousel-control-prev-viewer"
            href={`#${id}`}
            role="button"
            data-slide="prev"
          >
            <span className="" aria-hidden="true">
              <img
                className="arrows-desktop"
                src={leftArrowDesktop}
                alt="Arrow Left Icon"
              />

              <img
                className="arrows-movil"
                src={leftArrowMobile}
                alt="Arrow Left Icon"
              />
            </span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next carousel-control-next-viewer"
            href={`#${id}`}
            role="button"
            data-slide="next"
          >
            <span className="" aria-hidden="true">
              <img
                className="arrows-desktop"
                src={rightArrowDesktop}
                alt="Arrow Right Icon"
              />

              <img
                className="arrows-movil"
                src={rightArrowMobile}
                alt="Arrow Right Icon"
              />
            </span>
            <span className="sr-only">Next</span>
          </a>
        </>
      )}
    </section>
  );
}
