import React from 'react';
import './styles.css';

//IMAGES 
import arrowLeftDesktop from '../../images/icons/arrow_left.png';
import arrowRightDesktop from '../../images/icons/arrow_right.png';

import arrowLeftMovil from '../../images/icons/arrow_left_movil.png';
import arrowRightMovil from '../../images/icons/arrow_right_movil.png';

export default function Carousel({ data, sliderActiveNumber = 1 }) {

    //const [sliderActive, setSliderActive] = useState(sliderNumber);

    //const sliderActive = sliderActiveNumber;

    return (<section id="carousel3dModelSteps" className="carousel slide" data-ride="carousel" data-pause="false">

                <ol className="carousel-indicators">
                    {data.map((slider, index) => {

                        let isActive = slider.id === sliderActiveNumber ? 'active' : '';    

                        return(
                            <li key={slider.id} data-target="#carousel3dModelSteps" data-slide-to={index} className={isActive}></li>
                        )
                    })}
                </ol> 

                <div className="carousel-inner">         
                    {data.map((slider) => {
    
                        let isActive = slider.id === sliderActiveNumber ? 'active' : '';    

                        return(
                        <div key={slider.id} className={`carousel-item ${isActive}`}>
                            <img className="d-block" src={`${process.env.PUBLIC_URL}/images/sliders/${slider.sliderImg}`} 
                            alt={slider.description} />
                        </div>)

                    })}
                </div> 

                <a className="carousel-control-prev" href="#carousel3dModelSteps" role="button" data-slide="prev">
                    <span className="" aria-hidden="true">

                        <img className="arrows-desktop" src={arrowLeftDesktop} alt="Arrow Left Icon"/>

                        <img className="arrows-movil" src={arrowLeftMovil} alt="Arrow Left Icon"/>

                    </span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carousel3dModelSteps" role="button" data-slide="next">
                    <span className="" aria-hidden="true">

                        <img className="arrows-desktop" src={arrowRightDesktop} alt="Arrow Right Icon"/>

                        <img className="arrows-movil" src={arrowRightMovil} alt="Arrow Right Icon"/>

                    </span>
                    <span className="sr-only">Next</span>
                </a>

        </section>)
}
 
/* COMMENT (OF OLD BOOTSTRAP CODE)

<a className="carousel-control-prev" href="#carousel3dModelSteps" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carousel3dModelSteps" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>

*/


