import React from 'react';
import { Helmet } from 'react-helmet';

//PAGE STYLES 
import '../HouseplantLighter/styles.css';

//COMPONENTS
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import FrescoWidget from '../../components/FrescoWidget';

function KeurigCafe() {
    return (<>
        <Helmet>
            <title>Keurig Cafe Model | Fresco Design</title>
            <meta name="description" content="Keurig Cafe model" />
            {/* opengraph protocol */}
            <meta property="og:description" content="Keurig Cafe model" /> 
            <meta property="og:title" content="Keurig Cafe Model | Fresco Design" />
            <meta property="og:url" content="https://fresco-augmented-reality.com/Keurig_Cafe" />
            <link rel="canonical" href="https://fresco-augmented-reality.com/Keurig_Cafe" />
            <link rel="alternate" href="https://www.fresco-design.com.ar/Keurig_Cafe" hreflang="es-AR" />
            <link rel="alternate" href="https://www.fresco-design.com/Keurig_Cafe" />
        </Helmet>

        <Navbar noShowOptions={true} />

        <div className="container-fluid container_3decommerce_iframe"> 

            {/* DESKTOP */}
            <section className="desktop-title-iframe mt-5">
                <div className="row">
                    <div className="col-12">
                        <h3>Keurig K-Cafe</h3>
                    </div>
                </div>  
            </section>
            {/* </DESKTOP> */}

            <FrescoWidget elementID="fresco-keurigcafe" frescoKey="8fa611511d784b54964149713ca3333c" />  
        </div>

        <div className="container container_3decommerce">

            <section className="section_contact_3decommerce mt-5 mb-3">
                <div className="row">
                    <div className="col-12">
                       <p>                     
                            Fresco Immersive Commerce leverages AR to create online experiences for consumers to discover and explore your products.
                       </p>
                    </div> 
                    <div className="col-12">
                        <p>
                            <a href="https://www.fresco-design.com/contact">
                                Let’s discuss how Fresco can grow your eCommerce business
                                <i className="fa fa-angle-right fa-lg" aria-hidden="true"></i>
                            </a>
                        </p>
                    </div>
                </div>  
            </section>
             
        </div> 

        <Footer />
    </>)
}

export default KeurigCafe
