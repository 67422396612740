import React, { useState } from 'react';
import './styles.css';

//IMAGES
import frescoIcon from '../../images/icons/fresco_icon_transparent.png';

function Login({ setUser }) {

    const [password, setPassword] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const signIn = (e) => {
        e.preventDefault();
        if(password === 'COLOR_CONCEPTS'){
            sessionStorage.setItem('access', true);
            setUser(sessionStorage.getItem('access'));
        }else {
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 2500);
        }

        setPassword('');
    };

    return (<>
  
    {showAlert && <div className="alert alert-danger" role="alert">
        Incorrect Password
    </div>}

            <div className="Login">
                <div className="login__logo">
                    <figure>
                        <img src={frescoIcon} alt="Fresco Design Logo"/>
                    </figure>
                    <h1 style={{textAlign: 'center', color: 'var(--grey-dark)'}}>Login</h1>
                </div>

                <form className="form" onSubmit={(e) => signIn(e)}>
                    <div className="form-group">
                        <label>
                            <input type="password" className="form-control" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} />
                        </label>
                    </div>
                    <input type="submit" className="btn_fresco_rounded_outline" value="Sign In" />
                </form>

            </div>
    </>)
}

export default Login