import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./styles.css";

//IMAGES
import frescoLogo from "../../images/icons/Fresco_logo_updated.webp";

import movilMenuBottomArrow from "../../images/icons/movilNavbar-bottomArrow.png";
import movilMenuTopArrow from "../../images/icons/movilNavbar-topArrow.png";

export default function Navbar({ noShowOptions = false }) {
  const refBtnHamburguer = useRef(null);
  const refMovilMenu = useRef(null);

  var html = document.getElementsByTagName("html");

  const [menuOpen, setMenuOpen] = useState(false);

  const [isDropdowmAboutDeployed, setIsDropdowmAboutDeployed] = useState(false);

  function HandleMenu() {
    if (!menuOpen) {
      refBtnHamburguer.current.classList.add("open");
      refMovilMenu.current.style.display = "flex";
      html[0].style.overflow = "hidden";
      setMenuOpen(true);

      // Set
      setZindex(1);
    } else {
      refBtnHamburguer.current.classList.remove("open");
      refMovilMenu.current.style.display = "none";
      html[0].style.overflow = "auto";
      setMenuOpen(false);

      // Reset
      setZindex("");
    }
  }

  function setZindex(value) {
    const carouselIndicators = document.querySelectorAll(
      ".carousel-indicators"
    );
    if (carouselIndicators) {
      carouselIndicators.forEach((elem) => {
        elem.style.zIndex = value;
      });
    }

    // Arrows
    const carouselLeftArrows = document.querySelectorAll(
      ".carousel-control-prev"
    );
    if (carouselLeftArrows) {
      carouselLeftArrows.forEach((elem) => {
        elem.style.zIndex = value;
      });
    }

    const carouselRightArrows = document.querySelectorAll(
      ".carousel-control-next"
    );
    if (carouselRightArrows) {
      carouselRightArrows.forEach((elem) => {
        elem.style.zIndex = value;
      });
    }

    // Viewers
    const viewersStatesScreen = document.querySelectorAll(
      ".fresco-viewer__states-section"
    );
    if (viewersStatesScreen) {
      viewersStatesScreen.forEach((elem) => {
        elem.style.zIndex = value;
      });
    }
  }

  const IconToShow = isDropdowmAboutDeployed
    ? movilMenuTopArrow
    : movilMenuBottomArrow;

  function HandleDropdowmAbout() {
    if (isDropdowmAboutDeployed) {
      setIsDropdowmAboutDeployed(false);
    } else {
      setIsDropdowmAboutDeployed(true);
    }
  }

  return (
    <header>
      {/* MOVIL */}

      <section ref={refMovilMenu} className="navbar_movil">
        <div className="navbar_options_movil">
          <ul>
            <li>
              <a href="https://www.fresco-design.com">Home</a>
            </li>
            <li>
              <a href="https://www.fresco-design.com/work">Work</a>
            </li>
            <li>
              <a href="https://www.fresco-design.com/approach">Approach</a>
            </li>
            <li>
              <a href="https://www.fresco-design.com/models-prototypes">
                Models & Prototypes
              </a>
            </li>
            {/* <li>
							<a href='https://www.fresco-design.com/3d-digital-transformation'>3D Digital Transformation</a>
						</li> */}
            <li>
              <a href="https://www.fresco-design.com/vr-xr">VR/XR</a>
            </li>
            <li>
              {/* <Link to="/" className="active_page">
                Immersive Commerce
              </Link> */}
              <a href="#" className="active_page">
                Immersive Commerce
              </a>
            </li>
            {/* Drop Option */}
            {/* <li className="drop-option-movil">
              <img
                className="drop-icon"
                src={IconToShow}
                alt=""
                onClick={() => {
                  HandleDropdowmAbout();
                }}
              />

              <a href="https://www.fresco-design.com/about">About</a>
            </li>
            {isDropdowmAboutDeployed && (
              <li>
                <a
                  className="sub-option"
                  href="https://www.fresco-design.com/careers"
                >
                  Careers
                </a>
              </li>
            )} */}
            {/* - */}
            <li>
              <a href="https://www.fresco-design.com/about">About</a>
            </li>
            <li>
              <a href="https://www.fresco-design.com/contact">Let's Talk</a>
            </li>
          </ul>
        </div>
      </section>

      {/* DESKTOP */}

      <div className="container-fluid navbar_container">
        {" "}
        {/* container */}
        <div className="row navbar_row">
          <div className="col-12 col-lg-3 navbar_logo">
            {" "}
            {/* col-lg-4 */}
            <a href="https://www.fresco-design.com">
              <img src={frescoLogo} alt="Fresco Design Logo" />
            </a>
          </div>

          {/* MOVIL */}
          <div
            onClick={() => HandleMenu()}
            ref={refBtnHamburguer}
            className={`btn_menu_movil ${noShowOptions ? "d-none" : ""}`}
          >
            <div className="menu-btn__burger"></div>
          </div>
          {/* /MOVIL */}

          <div
            className={`col-lg-9 navbar_options ${
              noShowOptions ? "invisible" : ""
            }`}
          >
            {" "}
            {/* col-lg-8 */}
            <ul>
              <li>
                <a href="https://www.fresco-design.com/work">Work</a>
              </li>
              <li>
                <a href="https://www.fresco-design.com/approach">Approach</a>
              </li>
              <li>
                <a href="https://www.fresco-design.com/models-prototypes">
                  Models & Prototypes
                </a>
              </li>
              {/* <li>
								<a href='https://www.fresco-design.com/3d-digital-transformation'>3D Digital Transformation</a>
							</li> */}
              <li>
                <a href="https://www.fresco-design.com/vr-xr">VR/XR</a>
              </li>
              <li>
                {/* <Link to="/" className="active_page">
                  Immersive Commerce
                </Link> */}
                <a href="#" className="active_page">
                  Immersive Commerce
                </a>
              </li>
              {/* Drop Option */}
              {/* <li className="drop-option">
                <a href="https://www.fresco-design.com/about">About</a>
                <span className="list">
                  <a href="https://www.fresco-design.com/careers">Careers</a>
                </span>
              </li> */}
              <li>
                <a href="https://www.fresco-design.com/about">About</a>
              </li>
              <li>
                <a href="https://www.fresco-design.com/contact">Let's Talk</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}
