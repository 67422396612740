import React, { useState, useEffect } from 'react';
import './styles.css';

//IMAGES
import footerArrowRight from '../../images/icons/footer_arrow_right.png';

//REQUEST
import axios from 'axios';

export default function Footer() {
	//INPUTS
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [lastName, setLastName] = useState('');
	const [company, setCompany] = useState('');

	//VALIDATIONS
	const [sendSuccess, setSendSuccess] = useState(false);
	const [validationText, setValidationText] = useState('');

	const captchaScript = `https://www.google.com/recaptcha/api.js`;

	useEffect(() => {
		const s = document.createElement('script');
		s.setAttribute('src', captchaScript);
		s.async = true;
		document.body.appendChild(s);
	}, [captchaScript]);

	function HandleSubmit(e) {
		e.preventDefault();
		let catchaValidation = window.grecaptcha.getResponse();
		//console.log('catcha Validation:', catchaValidation);
		if (catchaValidation.length === 0) {
			setValidationText(`You can't leave Captcha Code empty.`);
			setTimeout(() => {
				setValidationText('');
			}, 3000);
			return false;
		} else {
			if (email !== '' || name !== '' || lastName !== '' || company !== '') {
				axios
					.post('https://data.fresco-augmented-reality.com/api/emails', {
						//http://localhost:3001
						email: email,
						firstName: name,
						lastName: lastName,
						company: company,
					})
					.then(
						(response) => {
							setSendSuccess(true);
							//TimeOut
							setTimeout(() => {
								setSendSuccess(false);
							}, 3000);
							console.log('Email request:', response);
						},
						(error) => {
							setValidationText(`Error sending data to server.`);
							setTimeout(() => {
								setValidationText('');
							}, 3000);
							console.log(error);
						}
					);

				//Bussines Logic
				setEmail('');
				setName('');
				setLastName('');
				setCompany('');
			} else {
				setValidationText(`You cannot leave any fields empty.`);
				setTimeout(() => {
					setValidationText('');
				}, 3000);
				return false;
			}
		}
	}

	return (
		<footer>
			<div className='container footer-container'>
				<div className='row pt-4 pb-lg-5'>
					<div className='order-2 order-lg-1 col-12 col-lg-3 footer_location'>
						<hr className='dividing_line_horizontal' />
						<br className='dividing_line_horizontal_space' />

						<div className='dividing_line'>
							<p>
								<strong>Fresco Design</strong>
							</p>
							{/* <p className="mt-lg-4">Framingham, MA USA</p>  */}
							<p className='mt-lg-4'>Marlborough, MA USA</p>
							<p className='mt-lg-4 mb-lg-4'>Buenos Aires, Argentina</p>
						</div>
					</div>

					<div className='order-3 order-lg-2 col-12 col-lg-4 footer_emails'>
						<div className='dividing_line'>
							<p className='careers-movil'>
								<strong>Careers</strong> <br />
								<a href='mailto:careers@fresco-design.com?subject=Check%20out%20my%20resume%20and%20portfolio'>
									careers@fresco-design.com
								</a>
							</p>

							<p>
								<strong>New Business</strong>
								<br />
								<a href='mailto:hello@fresco-design.com?subject=I%27d%20like%20to%20discuss%20a%20project%20with%20Fresco'>
									hello@fresco-design.com
								</a>
							</p>

							<p className='careers-desktop mt-lg-4'>
								<strong>Careers</strong> <br />
								<a href='mailto:careers@fresco-design.com?subject=Check%20out%20my%20resume%20and%20portfolio'>
									careers@fresco-design.com
								</a>
							</p>
						</div>
					</div>

					<div className='order-1 order-lg-3 col-12 col-lg-5 suscribete_form'>
						<p className='mt-lg-2'>
							Join our mailing list to hear about product <br /> launches, events & updates
						</p>
						<form onSubmit={(e) => HandleSubmit(e)} encType='text/plain' style={{ textAlign: 'center' }}>
							{' '}
							{/* action="mailto:danielle@fresco-design.com" method="post"  */}
							<input
								value={email}
								type='email'
								name='email'
								placeholder='Email*'
								onChange={(e) => setEmail(e.target.value)}
								required
								style={{ marginTop: '0' }}
							/>
							<input
								value={name}
								type='text'
								name='name'
								placeholder='First Name*'
								autoComplete='off'
								onChange={(e) => setName(e.target.value)}
								required
							/>
							<input
								value={lastName}
								type='text'
								name='last-name'
								placeholder='Last Name*'
								autoComplete='off'
								onChange={(e) => setLastName(e.target.value)}
								required
							/>
							<input
								value={company}
								type='text'
								name='company'
								placeholder='Company*'
								autoComplete='off'
								onChange={(e) => setCompany(e.target.value)}
								required
							/>
							<div className='container-catcha-and-submit'>
								{/* <div className="g-recaptcha" data-sitekey="6LeK46kaAAAAAF1oa1HqTtgfxeoTrrub2eeKuXKD"></div> */}
								<div
									className='g-recaptcha captcha-styles'
									data-sitekey='6LddAKoaAAAAAIJrEgI6fQQPEz9S880mYmOQCrnw'></div>
								<button>
									<img src={footerArrowRight} alt='Arrow Right Icon' />
								</button>
							</div>
						</form>
						{validationText && (
							<div className='notify-message mt-2' style={{ color: '#db2a2a' }}>
								{validationText}
							</div>
						)}
						{sendSuccess && (
							<div style={{ color: 'var(--green-fresco)' }} className='notify-message mt-2'>
								Thanks for subscribing!
							</div>
						)}
					</div>
				</div>
			</div>
		</footer>
	);
}
