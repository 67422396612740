import React, { useCallback, useRef, useEffect, useState } from 'react';

//PACKAGES
import screenfull from 'screenfull';

//HELPERS
import { StartAndHidePlayButton } from '../../helpers/index';

//IMAGES
import fullScreenIcon from '../../images/icons/fullscreen_icon.png';

export default function UiButtons({ api, iframeStart, setIframeStart, refViewerGrid, iframeSection, fullScreen, setFullScreen, setGridBtnUiState,
  refIframeContainer, refIframeRow, refIframeCol, refViewer, refStartViewerBtn, ios, android, 
  viewerContainerClassList, viewerRowClassList, viewerColClassList, loadingViewer, setLoadingEffect }){

    const refGridBtnUi = useRef(null);
    var html = document.getElementsByTagName('html');  

    const [showFullScreenBtn, setShowFullScreenBtn] = useState(true);

    useEffect(() => {

      setGridBtnUiState(refGridBtnUi);

      if (!screenfull.isEnabled && (!refIframeContainer || !refIframeRow || !refIframeCol)){  
        console.error('You are not passing ref props for fullscreen functionality in case the devices are not supported', {
          refIframeContainer: refIframeContainer,
          refIframeRow: refIframeRow,
          refIframeCol: refIframeCol,
        });
        setShowFullScreenBtn(false);
      } 

      if(!screenfull.isEnabled && (!viewerContainerClassList || !viewerRowClassList || !viewerColClassList)) {
        console.error('You are not passing ClassList props for fullscreen functionality in case the devices are not supported', {
          viewerContainerClassList: viewerContainerClassList,
          viewerRowClassList: viewerRowClassList,
          viewerColClassList: viewerColClassList,
        });
        setShowFullScreenBtn(false);
      }
      
    }, [refIframeContainer, refIframeRow, refIframeCol, viewerContainerClassList, viewerRowClassList, viewerColClassList, setGridBtnUiState]);

    const HandleFullScreen = useCallback(() => {

      StartAndHidePlayButton(api, iframeStart, setIframeStart, loadingViewer, setLoadingEffect, refStartViewerBtn);  
  
        if (screenfull.isEnabled){  //CHANGE LATER  
          screenfull.toggle(refViewerGrid); 
          screenfull.on('change', () => {      
            if(screenfull.isFullscreen){
              iframeSection.current.style.height = '100%';
            } else {
              iframeSection.current.style.height = '';
            }
            setFullScreen(screenfull.isFullscreen); 
          });
        }else{  //FULL SCREEN SIMULATION  
      
          if(!fullScreen){  
            refViewerGrid.style.border = 'none';
            iframeSection.current.style.border = 'none'; 
            iframeSection.current.style.height = '100%';
            refViewer.current.style.height = '85%';     
            /* Remove Viewer ClassLists */
            if(viewerContainerClassList){
              if(viewerContainerClassList.length !== 0){
                refIframeContainer.current.classList.remove(...viewerContainerClassList); 
              }  
            }
            if(viewerRowClassList){
              if(viewerRowClassList.length !== 0){
                refIframeRow.current.classList.remove(...viewerRowClassList);
              }
            }   
            if(viewerColClassList){
              if(viewerColClassList.length !== 0){
                refIframeCol.current.classList.remove(...viewerColClassList); 
              }   
            }
            /* --- */
            refIframeCol.current.classList.add('iframe-fullscreen');
            refGridBtnUi.current.style.bottom = '16%';
            html[0].style.overflow = 'hidden';
            //window.scroll(0, 0);
            setFullScreen(true); 
          }else{          
            refViewerGrid.style.border = '';
            iframeSection.current.style.border = '';
            iframeSection.current.style.height = '';
            refViewer.current.style.height = '';
            refGridBtnUi.current.style.bottom = '';
            refIframeCol.current.classList.remove('iframe-fullscreen');
            /* Add Viewer ClassLists */
            if(viewerContainerClassList){
              if(viewerContainerClassList.length !== 0){
                refIframeContainer.current.classList.add(...viewerContainerClassList);
              }
            }  
            if(viewerRowClassList){ 
              if(viewerRowClassList.length !== 0){
                refIframeRow.current.classList.add(...viewerRowClassList);
              }
            }
            if(viewerColClassList){
              if(viewerColClassList.length !== 0){
                refIframeCol.current.classList.add(...viewerColClassList);
              }  
            }                                
            /* --- */
            html[0].style.overflow = 'auto';
            //window.scroll(0, 200);
            setFullScreen(false);
          } 

        }
        
      }, [ api, iframeStart, setIframeStart, refIframeContainer, refIframeRow, refIframeCol, refViewerGrid, 
        iframeSection, refViewer, refGridBtnUi, fullScreen, setFullScreen, refStartViewerBtn, html, 
        viewerContainerClassList, viewerRowClassList, viewerColClassList, loadingViewer, setLoadingEffect ]);

    return(<div ref={refGridBtnUi} className="iframe-buttons-ui-section"> 
     
      {showFullScreenBtn && <button className="fullscreen-button" onClick={() => HandleFullScreen()}>
          <img src={fullScreenIcon} alt="Fullscreen icon"/>
        </button>}

    </div>)

}

