import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

//PAGE STYLES
import "./styles.css";

//DATA
import { AWS_DOMAIN, SLIDERS_3DMODEL_STEPS } from "../../services/data";

//COMPONENTS
import Navbar from "../../components/Navbar";
import Carousel from "../../components/Carousel";
import Footer from "../../components/Footer";

import ImmersiveExperiencesMod from "../../components/ImmersiveExperiencesMod";

//IMAGES
import benefit_1 from "../../images/icons/benefit_1.png";
import benefit_2 from "../../images/icons/benefit_2.png";
import benefit_3 from "../../images/icons/benefit_3.png";
import benefit_4 from "../../images/icons/benefit_4.png";
import benefit_5 from "../../images/icons/benefit_5.png";

// Viewers
import SwandooCharlieViewer from "../../components/SwandooCharlieViewer";

//VIDEOS
import CarouselViewer from "../../components/CarouselViewer";

export default function ImmersiveCommerce() {
  useEffect(() => {
    let configToMove = sessionStorage.getItem("ConfiguratorToUse");

    if (configToMove !== undefined || configToMove !== null) {
      if (configToMove === "Shoe") {
        document.querySelector("#shoeConfig-section").scrollIntoView();
      } else if (configToMove === "Bike") {
        document.querySelector("#bikeConfig-section").scrollIntoView();
      } else {
      }

      sessionStorage.removeItem("ConfiguratorToUse");
    }
  }, []);

  const videoBreakPoint = 920;
  // const equifitVideoSq = `${AWS_DOMAIN}assets/videos/equifit_configurator_v16_no_ending_no_intro_logo_nm_220418.mp4`;
  const equifitVideoSq = `${AWS_DOMAIN}assets/videos/equifit_configurator_v16_compressed_no_ending_no_intro_logo_fv_220504.mp4`;
  const equifitVideoHD = `${AWS_DOMAIN}assets/videos/equifit_configurator_v16_no_ending_no_intro_logo_16-9_bigger_text_comp_fv_220428.mp4`;

  const SLIDERS_3DMODEL_VIEWERS = [
    {
      id: 1,
      children: (
        <div className="equifitVideo__container">
          <div className="equifitVideo__container2">
            {/* Button */}
            <div className="equifitVideo__btnLink_container">
              <a
                className="equifitVideo__btnLink"
                target="_blank"
                rel="noreferrer"
                href="https://equifit.net/collections/customlab/products/custom-d-teq-front-boot-2"
              >
                Try it here
              </a>
            </div>

            {/* Video */}
            <video
              className="equifitVideo"
              autoPlay
              loop
              muted
              playsInline
              // src={`${AWS_DOMAIN}assets/videos/equifit_configurator_v16_no_ending_no_intro_logo_nm_220418.mp4`}
              src={
                window.innerWidth >= videoBreakPoint
                  ? equifitVideoHD
                  : equifitVideoSq
              }
            ></video>
          </div>

          <p className="slider_subtitle">3D Product Configurator</p>
        </div>
      ),
      border: false,
    },
    {
      id: 2,
      children: (
        <div className="swandooViewer__container">
          <SwandooCharlieViewer
            height={{ desktop: "100%", mobile: "450px" }}
            maxWidth={{ desktop: null, mobile: "450px" }}
            width={{ desktop: "100%", mobile: null }}
          />
          <p className="slider_subtitle">AR (Augmented Reality)</p>
        </div>
      ),
      border: false,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Immersive Commerce | Fresco Design </title>
        <meta
          name="description"
          content="Fresco Immersive Commerce leverages Augmented and
        Virtual Reality to create online experiences for consumers to
        discover and explore your products, driving conversion
        and delighting people"
        />
        <link
          rel="canonical"
          href="https://fresco-augmented-reality.com/immersive-commerce"
        />
        <link
          rel="alternate"
          href="https://www.fresco-design.com.ar/immersive-commerce"
          hreflang="es-AR"
        />
        <link
          rel="alternate"
          href="https://www.fresco-design.com/immersive-commerce"
        />
        {/* opengraph protocol */}
        <meta property="og:title" content="Immersive Commerce" />
        <meta
          property="og:description"
          content="Fresco Immersive Commerce leverages Augmented and
        Virtual Reality to create online experiences for consumers to
        discover and explore your products, driving conversion
        and delighting people"
        />
        <meta
          property="og:url"
          content="https://fresco-augmented-reality.com/immersive-commerce"
        />
      </Helmet>

      <Navbar />

      <div className="container-fluid ">
        <section className="Section_Title_container">
          <h1 className="Section_Title font1 section_title-mobile">
            Fresco creates online <span>Immersive Commerce</span> experiences
            for consumers to discover & explore your products.
          </h1>
        </section>
      </div>

      <CarouselViewer
        data={SLIDERS_3DMODEL_VIEWERS}
        id="carousel3dModelStepsViewer"
        leftArrowDesktop={`${AWS_DOMAIN}assets/logos/carousel_arrow_left_02.svg`}
        leftArrowMobile={`${AWS_DOMAIN}assets/logos/carousel_arrow_left_02.svg`}
        rightArrowDesktop={`${AWS_DOMAIN}assets/logos/carousel_arrow_right_02.svg`}
        rightArrowMobile={`${AWS_DOMAIN}assets/logos/carousel_arrow_right_02.svg`}
      />

      <div className="cta__container--1">
        <a className="cta" href="https://www.fresco-design.com/contact">
          Let's transform your eCommerce experience. <br />
          Get started &gt;
        </a>
      </div>

      <ImmersiveExperiencesMod />

      <div className="boxes-container">
        <h3 className="boxes_title font1 section_title-mobile">
          Immersive Commerce gives shoppers the confidence they need when making
          highly considered purchases.
        </h3>

        <div className="boxesMainContainer">
          <div className="grid-parent">
            <div className="grid-child">
              {/* BOX 1 */}
              <img
                className="img-desktop"
                src="https://s3.amazonaws.com/fresco-augmented-reality.com/assets/logos/ecom_square-01.svg"
                alt=""
              />
              <img
                className="img-mobile"
                src="https://s3.amazonaws.com/fresco-augmented-reality.com/assets/logos/ecom_square-01_mobile.svg"
                alt=""
              />

              {/* BOX 2 */}
              <img
                className="img-desktop"
                src="https://s3.amazonaws.com/fresco-augmented-reality.com/assets/logos/ecom_square-02.svg"
                alt=""
              />
              <img
                className="img-mobile"
                src="https://s3.amazonaws.com/fresco-augmented-reality.com/assets/logos/ecom_square-02_mobile.svg"
                alt=""
              />
            </div>

            <div className="grid-child">
              {/* BOX 3 */}
              <img
                className="img-desktop"
                src="https://s3.amazonaws.com/fresco-augmented-reality.com/assets/logos/ecom_square-03.svg"
                alt=""
              />
              <img
                className="img-mobile"
                src="https://s3.amazonaws.com/fresco-augmented-reality.com/assets/logos/ecom_square-03_mobile.svg"
                alt=""
              />

              {/* BOX 4 */}
              <img
                className="img-desktop"
                src="https://s3.amazonaws.com/fresco-augmented-reality.com/assets/logos/ecom_square-04.svg"
                alt=""
              />
              <img
                className="img-mobile"
                src="https://s3.amazonaws.com/fresco-augmented-reality.com/assets/logos/ecom_square-04_mobile.svg"
                alt=""
              />
            </div>
          </div>

          <p className="boxes-info">*Data by Shopify</p>
        </div>
      </div>

      <div className="cta__container--2">
        <a className="cta" href="https://www.fresco-design.com/contact">
          Schedule a live demo and learn more &gt;
        </a>
      </div>

      <div className="process_section">
        <h3 className="process_title section_title-mobile">
          Highest quality 3D assets, created with our design expertise
        </h3>
      </div>

      <Carousel data={SLIDERS_3DMODEL_STEPS} />

      {/* TEST */}
      <div className="container container_3decommerce">
        <section className="section_Why_partner_with">
          <div className="row">
            <div className="col-12">
              <h2 className="font1 colorGray section_title-mobile">
                Why partner with Fresco for your Immersive Commerce?
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-1 col-md-2 col-3">
              <img src={benefit_1} alt="Benefit One" />
            </div>
            <div className="col-lg-11 col-md-10 col-9">
              <h4 className="colorGray">Strategic Consulting</h4>
              <p>
                We make the complex simple. We craft the right Immersive
                Commerce solutions for <br className="only-desktop" />
                each client's unique customer and business needs.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-1 col-md-2 col-3">
              <img src={benefit_2} alt="Benefit Two" />
            </div>
            <div className="col-lg-11 col-md-10 col-9">
              <h4 className="colorGray">Platform Independent</h4>
              <p>
                We aren't tied to one platform. We believe in flexibility, we
                integrate into your <br className="only-desktop" />
                eCommerce platform with best in class 3D technologies.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-1 col-md-2 col-3">
              <img src={benefit_3} alt="Benefit Three" />
            </div>
            <div className="col-lg-11 col-md-10 col-9">
              <h4 className="colorGray">Highest Quality 3D Assets</h4>
              <p>
                We are product designers with expertise in 3D visualization,
                giving us the ability to <br className="only-desktop" />
                bring a level of precision and detail unavailable with other 3D
                asset developers.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-1 col-md-2 col-3">
              <img src={benefit_4} alt="Benefit Four" />
            </div>
            <div className="col-lg-11 col-md-10 col-9">
              <h4 className="colorGray">Retail Expertise</h4>
              <p>
                We connect the best of brick and mortar with the best of online
                shopping to create <br className="only-desktop" />
                entirely new immersive shopping experiences.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-1 col-md-2 col-3">
              <img src={benefit_5} alt="Benefit Five" />
            </div>
            <div className="col-lg-11 col-md-10 col-9">
              <h4 className="colorGray">Proven Process</h4>
              <p>
                Our process automation and hands-on program management makes it
                easy for <br className="only-desktop" />
                our clients and delivers tangible results that work.
              </p>
            </div>
          </div>
        </section>
      </div>

      <div className="cta__container--3">
        <a className="cta" href="https://www.fresco-design.com/contact">
          Let's transform your eCommerce experience. <br />
          Get started &gt;
        </a>
      </div>
      <Footer />
    </>
  );
}
