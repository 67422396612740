import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

//PAGE STYLES 
import './styles.css';

//COMPONENTS
import Navbar from '../../components/Navbar'; 
import Footer from '../../components/Footer';
import Login from '../../components/Login';

//IFRAMES
import FrescoWidget from '../../components/FrescoWidget';

export default function CharmeleonLaunch(){ 

    const [user, setUser] = useState(sessionStorage.getItem('access'));

   return(<>
    
    {!user ? <>

    <Helmet>
        <title>Login | Fresco Design </title>
    </Helmet> 

    <Login setUser={setUser} />
    </> : <> 

    <Helmet>
        <title>Charmeleon Launch | Fresco Design</title>
        <meta name="description" content="Charmeleon launch" />
        {/* opengraph protocol */}
        <meta property="og:description" content="Charmeleon launch" /> 
        <meta property="og:title" content="Charmeleon Launch | Fresco Design" />
        <meta property="og:url" content="https://fresco-augmented-reality.com/Charmeleon_launch" />
        <link rel="canonical" href="https://fresco-augmented-reality.com/Charmeleon_launch" />
        <link rel="alternate" href="https://www.fresco-design.com.ar/Charmeleon_launch" hreflang="es-AR" />
        <link rel="alternate" href="https://www.fresco-design.com/Charmeleon_launch" />
    </Helmet> 
    
    <Navbar noShowOptions={true} />  

    <div className="container-fluid container_3decommerce_iframe"> 
        <section className="section_title_3dmodel">
            <div className="row">
                <div className="col">
                    <h3 style={{textAlign: 'center'}} className="mt-lg-2" >Neurable Charmeleon</h3>
                </div>
            </div>
        </section>   
    </div>    

    <div className="container-fluid container_3decommerce_iframe"> 
        <section className="desktop-title-iframe"> 
            <div className="row">
                <div className="col-12">
                    <h3>Speckled White Resting</h3>
                </div>
            </div>  
        </section>
    </div>

    <div className="container-fluid container_3decommerce_iframe"> 
        <FrescoWidget elementID="hp-white-restingmodel" frescoKey="05c2f79cdbbe42e588edeac6737f6997" />
    </div>

    <div className="container-fluid container_3decommerce_iframe"> 
        <section className="desktop-title-iframe"> 
            <div className="row">
                <div className="col-12">
                    <h3>Olive Resting</h3>
                </div>
            </div>  
        </section>
    </div>

    <div className="container-fluid container_3decommerce_iframe"> 
        <FrescoWidget elementID="hp-olive-restingmodel" frescoKey="0ea2d702e10746dda36bb71652981f06" />
    </div>

    <div className="container-fluid container_3decommerce_iframe"> 
        <section className="desktop-title-iframe"> 
            <div className="row">
                <div className="col-12">
                    <h3>Charcoal Resting</h3>
                </div>
            </div>  
        </section>
    </div>

    <div className="container-fluid container_3decommerce_iframe"> 
        <FrescoWidget elementID="hp-charcoal-restingmodel" frescoKey="53a2569746dd42a0a81080630a0962c1" />
    </div>

    <div className="container container_3decommerce"> 

        <section className="section_contact_3decommerce mt-5 mb-3">
            <div className="row">
                <div className="col-12">
                <p>                     
                    Fresco Immersive Commerce leverages AR to create online experiences for consumers to discover and explore your products.
                </p>
                </div> 
                <div className="col-12">
                    <p>
                        <a href="https://www.fresco-design.com/contact">
                            Let’s discuss how Fresco can grow your eCommerce business
                            <i className="fa fa-angle-right fa-lg" aria-hidden="true"></i>
                        </a>
                    </p>
                </div>
            </div>  
        </section>
        
    </div> 

    <Footer /> 
    
    </>}
         
   </>)

}

