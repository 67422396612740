import React, { Fragment, useEffect, useCallback, useState } from 'react';

//DATA
import { BOTTLE_PRODUCT } from '../../services/data';

//HELPER
import { SetBackgroundToClassName, SetTexture } from '../../helpers/index';

//IMAGES
import soldout from '../../images/icons/soldout.png';

export default function ColorOptions({ api, fullScreen, ios, android, setArFiles, colorSelected, setColorSelected, setProductNameState, modelMaterials, 
    isApplyTextures, setIsApplyTextures, loadingEffect }){

    const [showOptionsColor, setShowOptionsColor] = useState("invisible");
    
    const HandleClickOnColor = useCallback((option, e) => {

        let delayTime = 1000;
        
      setColorSelected(option.value);

        let numberOfChanges = option.changes.length;

        let count = 0;
        let countB = 0; 

        /* */
        setIsApplyTextures(true);
            option.changes.forEach((change) => {
                if(!change.uid){
                    let textureUrl = (ios || android) ? change.value.movil : change.value.desktop;
                    api.addTexture(textureUrl, (err, uid) => {                              //STEP 1
                        if (!err) {
                            change.uid = uid;                                  // <---- ERROR / PROBLEM
                            SetTexture(api, change.uid, modelMaterials, change.channel, change.material).then(data => {        //STEP 2 
                                
                                count += 1;
                                if(count === numberOfChanges){  
                                    
                                    let lastCount = 0;
    
                                    modelMaterials.forEach(materialUpdated => {
                                        api.setMaterial(materialUpdated, function(){          //STEP 3
                                            lastCount += 1;
                                            if(lastCount === modelMaterials.length){
                                                setTimeout(() => {   
                                                    setIsApplyTextures(false);               
                                                }, delayTime);
                                            }
    
                                        }); 
                                    });
                           
                                }
    
                            }); 
                        }
                    }); 
                } else {
                    delayTime = 0;
                    SetTexture(api, change.uid, modelMaterials, change.channel, change.material).then(data => {
                        api.setMaterial(data, function() {
                            countB += 1;
                            if(countB === numberOfChanges){  
                                setTimeout(() => {
                                    setIsApplyTextures(false);
                                }, delayTime);
                            }
                        });  
                    });
                }
            });

        setArFiles(option.ar_files);
        setProductNameState(`Bk-X ${option.value}`);

    },[api, android, ios, setArFiles, setColorSelected, setProductNameState, modelMaterials, setIsApplyTextures]);

    /* -- */

    useEffect(() => {
        SetBackgroundToClassName("color-item");

        if(fullScreen){
          setShowOptionsColor("");
        }else{
          setShowOptionsColor("invisible");
        }

    }, [fullScreen, setShowOptionsColor]);

    return(<div className={`bkcolors-section bkcolors-section-fullscreen ${showOptionsColor}`} >  

        {BOTTLE_PRODUCT.colors.color_primary.map((array) => {

        if(array.value === colorSelected){        
            document.getElementById(`fullscren-option-${array.id}`).checked = true;
        }

        return(<Fragment key={array.id}>
        <input disabled={isApplyTextures || loadingEffect} onClick={(e) => HandleClickOnColor(array, e)} 
        id={`fullscren-option-${array.id}`} name="colorfullscreen" type="radio" 
        value={array.value} className="color-radio" />
            <div className="swatch-element">
                <label htmlFor={`fullscren-option-${array.id}`} data-bgcolor={`#${array.hex}`} className="color-item">
                <img className="crossed-out" src={soldout} role="button" alt="Soldout" />                                          
                </label>
            </div>
        </Fragment>)

        })}

    </div>)

}